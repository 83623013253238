<ng-container *ngrxLet="{ activeLanguage: activeLanguage$ } as vm">
    <ng-container *ngIf="!(isExpanded$ | ngrxPush); else expandedMenuTemplate">
        <div class="default-menu">
            <button type="button" class="toggle-button" (click)="openMenu()">
                <dtm-ui-icon name="menu-unfold"></dtm-ui-icon>
            </button>

            <ng-container [ngTemplateOutlet]="menuItemsTemplate"></ng-container>

            <button type="button" (click)="logOut()">
                <dtm-ui-icon name="logout-circle"></dtm-ui-icon>
            </button>
        </div>
    </ng-container>

    <ng-template #expandedMenuTemplate>
        <div class="expanded-menu-backdrop" (click)="closeMenu()"></div>
        <div @menuToggle class="expanded-menu">
            <button type="button" class="toggle-button" (click)="closeMenu()">
                <dtm-ui-icon name="menu-fold"></dtm-ui-icon>
            </button>

            <div class="logo">
                <a routerLink="/" (click)="closeMenu()">
                    <img src="assets/images/logo.svg" alt="logo" />
                </a>
            </div>

            <div class="user-panel">
                <dtm-ui-icon name="user"></dtm-ui-icon>
                <span *ngrxLet="userFullName$ as userFullName">{{ userFullName }}</span>
            </div>

            <div class="language-select">
                <button
                    *ngIf="LANGUAGE.polish as polish"
                    type="button"
                    class="selectable-tile"
                    [class.selected]="vm.activeLanguage === polish.alpha2Code"
                    (click)="updateLanguage(polish.alpha2Code)"
                >
                    <dtm-ui-country-flag-display [country]="polish.flagName"></dtm-ui-country-flag-display>
                    <span>{{ polish.displayName }}</span>
                </button>
                <button
                    *ngIf="LANGUAGE.english as english"
                    type="button"
                    class="selectable-tile"
                    [class.selected]="vm.activeLanguage === english.alpha2Code"
                    (click)="updateLanguage(english.alpha2Code)"
                >
                    <dtm-ui-country-flag-display [country]="english.flagName"></dtm-ui-country-flag-display>
                    <span>{{ english.displayName }}</span>
                </button>
            </div>

            <div class="divider"></div>

            <ng-container [ngTemplateOutlet]="menuItemsTemplate" [ngTemplateOutletContext]="{ $implicit: true }"></ng-container>

            <button type="button" (click)="logOut()">
                <dtm-ui-icon name="logout-circle"></dtm-ui-icon>
                <span>{{ "sahClient.menuItems.logoutLabel" | transloco }}</span>
            </button>
        </div>
    </ng-template>

    <ng-template #menuItemsTemplate let-isExpanded>
        <nav class="menu-items">
            <button
                *ngFor="let item of MENU_ITEMS"
                type="button"
                routerLinkActive="menu-item-active"
                [routerLink]="item.link"
                (click)="closeMenu()"
            >
                <dtm-ui-icon [name]="item.iconName"></dtm-ui-icon>
                <span *ngIf="isExpanded">{{ item.label | transloco }}</span>
            </button>
        </nav>
    </ng-template>
</ng-container>
