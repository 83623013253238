import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Task, TaskStatus, TaskStatusChange, TaskTeamChange, Team } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { EmptyStateMode } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface TaskListComponentState {
    tasks: Task[];
    teams: Team[];
    isStatusChangeEnabled: boolean;
    isTaskEditEnabled: boolean;
    taskStatusPool: TaskStatus[];
}

@Component({
    selector: "sah-client-lib-task-list[tasks]",
    templateUrl: "task-list.component.html",
    styleUrls: ["task-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TaskListComponent implements OnInit {
    @Input() public set tasks(value: Task[] | undefined) {
        this.localStore.patchState({ tasks: value ?? [] });
    }
    @Input() public set teams(value: Team[] | undefined) {
        this.localStore.patchState({ teams: value ?? [] });
    }
    @Input() public set isTaskEditEnabled(value: BooleanInput) {
        this.localStore.patchState({ isTaskEditEnabled: coerceBooleanProperty(value) });
    }
    @Input() public set taskStatusPool(value: TaskStatus[] | undefined) {
        this.localStore.patchState({ taskStatusPool: value ?? [] });
    }

    @Output() protected readonly taskEdit = new EventEmitter<Task>();
    @Output() protected readonly taskRemove = new EventEmitter<Task>();
    @Output() protected readonly taskAreaSelect = new EventEmitter<string>();
    @Output() protected readonly statusChange = new EventEmitter<TaskStatusChange>();
    @Output() protected readonly teamAttach = new EventEmitter<TaskTeamChange>();
    @Output() protected readonly teamDetach = new EventEmitter<Task>();
    @Output() protected readonly pilotTaskAccept = new EventEmitter<Task>();
    @Output() protected readonly pilotTaskReject = new EventEmitter<Task>();

    protected readonly tasks$ = this.localStore.selectByKey("tasks");
    protected readonly teams$ = this.localStore.selectByKey("teams");
    protected readonly isStatusChangeEnabled$ = this.localStore.selectByKey("isStatusChangeEnabled");
    protected readonly isTaskEditEnabled$ = this.localStore.selectByKey("isTaskEditEnabled");
    protected readonly taskStatusPool$ = this.localStore.selectByKey("taskStatusPool");

    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly TaskStatus = TaskStatus;

    constructor(private readonly localStore: LocalComponentStore<TaskListComponentState>) {
        this.localStore.setState({
            tasks: [],
            teams: [],
            isStatusChangeEnabled: false,
            isTaskEditEnabled: false,
            taskStatusPool: [],
        });
    }

    public ngOnInit(): void {
        this.localStore.patchState({
            isStatusChangeEnabled: this.statusChange.observed,
        });
    }

    protected doesTaskStatusAllowRemoval(status: TaskStatus): boolean {
        return [TaskStatus.Incomplete, TaskStatus.Planned].includes(status);
    }

    protected doesTaskStatusAllowEdit(status: TaskStatus): boolean {
        return status !== TaskStatus.Completed;
    }

    protected getAvailableStatuses(task: Task, taskStatusPool: TaskStatus[] | undefined): TaskStatus[] {
        // NOTE: Change status to Active is available only for tasks with attached team
        const conditionalActiveStatus = task.attachedTeam ? [TaskStatus.Active] : [];
        let availableStatuses: TaskStatus[];

        switch (task.status) {
            case TaskStatus.Planned:
                availableStatuses = [...conditionalActiveStatus, TaskStatus.Paused, TaskStatus.Completed];
                break;
            case TaskStatus.Active:
                availableStatuses = [TaskStatus.Paused, TaskStatus.Completed];
                break;
            case TaskStatus.Paused:
                availableStatuses = [...conditionalActiveStatus, TaskStatus.Completed];
                break;
            default:
                availableStatuses = [];
        }

        return availableStatuses.filter((status) => taskStatusPool?.includes(status));
    }

    protected canModifyAssignedTeam(taskStatus: TaskStatus): boolean {
        return ![TaskStatus.Completed, TaskStatus.Rejected, TaskStatus.PendingAcceptance].includes(taskStatus);
    }
}
