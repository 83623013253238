import { convertMapAreaToPolygon } from "./utils/convert-map-area-to-polygon";
import { convertTaskStatusToCssClass } from "./utils/convert-task-status-to-css-class";
import { createMutableHandDrawPolyline } from "./utils/create-mutable-hand-draw-polyline";
import { createMutableMapArea } from "./utils/create-mutable-map-area";

export * from "./components/action-markers/action-markers-layer.directive";
export * from "./components/areas/areas.stories.data";
export * from "./incident-shared.module";
export * from "./incident-shared.tokens";
export * from "./models/incident-map.models";
export * from "./models/incident.models";
export * from "./models/task.models";
export * from "./models/team.models";
export * from "./services/action-marker.service";
export * from "./services/area.service";
export * from "./services/hand-drawing.service";
export * from "./services/incident-map-layers.service";
export * from "./services/incident-map.service";
export * from "./services/incident-shared-data-api.converters";
export * from "./services/task-marker.service";
export * from "./state/incident-shared-data.actions";
export * from "./state/incident-shared-data.state";

export const SahUtils = {
    convertTaskStatusToCssClass: convertTaskStatusToCssClass,
};

export const SahMapUtils = {
    convertMapAreaToPolygon: convertMapAreaToPolygon,
    createMutableMapArea: createMutableMapArea,
    createMutableHandDrawPolyline: createMutableHandDrawPolyline,
};
