import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { StringUtils, UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { Store } from "@ngxs/store";
import { EMPTY, Observable, catchError, throwError } from "rxjs";
import { map } from "rxjs/operators";
import { INCIDENT_SHARED_ENDPOINTS, IncidentSharedEndpoints } from "../incident-shared.tokens";
import { ActionMarkerData } from "../models/incident-map.models";
import { IncidentErrorType, IncidentEvent, IncidentEventMessage, OperationalSituation } from "../models/incident.models";
import {
    CreateOrUpdateActionMarkerResponseBody,
    GetOperationSituationResponseBody,
    convertActionMarkerDataToCreateOrUpdateActionMarkerRequestPayload,
    convertCreateOrUpdateActionMarkerResponseBodyToActionMarkerData,
    convertGetOperationalSituationResponseBodyToOperationalSituation,
    convertIMessageToIncidentEventMessage,
} from "./incident-shared-data-api.converters";

const WS_INCIDENT_EVENT_TYPES = Object.values(IncidentEvent);

@Injectable({ providedIn: "root" })
export class IncidentSharedDataApiService {
    constructor(
        @Inject(INCIDENT_SHARED_ENDPOINTS) private readonly endpoints: IncidentSharedEndpoints,
        private readonly http: HttpClient,
        private readonly store: Store,
        private readonly websocketService: WebsocketService
    ) {}

    public startIncidentWatch(incidentId: string): Observable<IncidentEventMessage | undefined> {
        return this.websocketService
            .watchTopic(StringUtils.replaceInTemplate(this.endpoints.wsIncidentTopic, { incidentId }), WS_INCIDENT_EVENT_TYPES)
            .pipe(map((message) => convertIMessageToIncidentEventMessage(message, this.store.selectSnapshot(AuthState.userId))));
    }

    public getOperationalSituation(incidentId: string): Observable<OperationalSituation> {
        return this.http
            .get<GetOperationSituationResponseBody>(StringUtils.replaceInTemplate(this.endpoints.getIncidentMapData, { incidentId }))
            .pipe(
                map((response) =>
                    convertGetOperationalSituationResponseBodyToOperationalSituation(response, this.store.selectSnapshot(AuthState.userId))
                ),
                catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown })))
            );
    }

    public createActionMarker(incidentId: string, data: Partial<ActionMarkerData>): Observable<Partial<ActionMarkerData>> {
        return this.http
            .post<CreateOrUpdateActionMarkerResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.createActionMarker, { incidentId }),
                convertActionMarkerDataToCreateOrUpdateActionMarkerRequestPayload(data)
            )
            .pipe(
                map((response) =>
                    convertCreateOrUpdateActionMarkerResponseBodyToActionMarkerData(response, this.store.selectSnapshot(AuthState.userId))
                ),
                catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown })))
            );
    }

    public updateActionMarker(incidentId: string, data: Partial<ActionMarkerData>): Observable<Partial<ActionMarkerData>> {
        if (!data.id) {
            return EMPTY;
        }

        return this.http
            .put<CreateOrUpdateActionMarkerResponseBody>(
                StringUtils.replaceInTemplate(this.endpoints.updateActionMarker, { incidentId, markerId: data.id }),
                convertActionMarkerDataToCreateOrUpdateActionMarkerRequestPayload(data)
            )
            .pipe(
                map((response) =>
                    convertCreateOrUpdateActionMarkerResponseBodyToActionMarkerData(response, this.store.selectSnapshot(AuthState.userId))
                ),
                catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown })))
            );
    }

    public removeActionMarker(incidentId: string, markerId: string): Observable<void> {
        return this.http
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removeActionMarker, { incidentId, markerId }))
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }

    public addActionMarkerPhoto(incidentId: string, markerId: string, file: Blob): Observable<UploadedFileBody> {
        const formData: FormData = new FormData();

        formData.append("file", file);

        return this.http
            .post<UploadedFileBody>(StringUtils.replaceInTemplate(this.endpoints.addActionMarkerPhoto, { incidentId, markerId }), formData)
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }

    public removeActionMarkerPhoto(incidentId: string, markerId: string): Observable<void> {
        return this.http
            .delete<void>(StringUtils.replaceInTemplate(this.endpoints.removeActionMarkerPhoto, { incidentId, markerId }))
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }

    public getActionMarkerPhoto(incidentId: string, markerId: string): Observable<Blob> {
        return this.http
            .get(StringUtils.replaceInTemplate(this.endpoints.getActionMarkerPhoto, { incidentId, markerId }), {
                responseType: "blob",
            })
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }

    public sendIncidentMessage(incidentId: string, message: string): Observable<void> {
        return this.http
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.sendIncidentMessage, { incidentId }), {
                content: message,
            })
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }
}
