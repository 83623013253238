import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { DOCUMENT } from "@angular/common";
import { Directive, EventEmitter, Inject, Input, OnDestroy, Output, ViewContainerRef } from "@angular/core";
import { CustomLeafletMapEvent, HandDrawingPolyline } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { HandDraw, LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "@dtm-frontend/shared/map/leaflet";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { LatLng, LeafletEvent, Map, Polyline, Popup, PopupOptions } from "leaflet";
import { HandDrawingMenuComponent } from "./hand-drawing-menu/hand-drawing-menu.component";

interface HandDrawDirectiveState {
    isSelectionModeEnabled: boolean;
    handDrawings: {
        drawing: HandDrawingPolyline;
        backupStyles: HandDrawingBackupStyles;
    }[];
}

interface HandDrawingBackupStyles {
    color?: string;
}

const DRAWING_MENU_POPUP_OPTIONS: PopupOptions = {
    closeButton: false,
    closeOnClick: false,
    closeOnEscapeKey: false,
    autoClose: false,
    className: "hand-drawing-menu",
    maxWidth: undefined,
};

const DRAWING_SELECTED_COLOR = "#b00020"; // NOTE: $color-error-400: #b00020

@UntilDestroy()
@Directive({
    selector: "sah-client-lib-hand-draw[isDrawingEnabled]",
    providers: [LocalComponentStore],
    exportAs: "handDraw",
})
export class HandDrawDirective implements OnDestroy {
    private readonly map: Promise<Map> = this.mapProvider.getMap();
    private handDraw: HandDraw | undefined;
    private readonly handDrawingMenuComponent = this.viewContainerRef.createComponent(HandDrawingMenuComponent);
    private readonly drawingMenuPopup = new Popup(DRAWING_MENU_POPUP_OPTIONS).setContent(
        this.handDrawingMenuComponent.location.nativeElement
    );

    @Input() public set isDrawingEnabled(value: BooleanInput) {
        if (value) {
            this.handDraw?.enable();
        } else {
            this.handDraw?.disable();
        }
    }

    @Input() public set isSelectionModeEnabled(value: BooleanInput) {
        const isSelectionModeEnabled = coerceBooleanProperty(value);

        this.localStore.patchState({ isSelectionModeEnabled });

        if (!isSelectionModeEnabled) {
            this.closeDrawingMenu();
            this.restoreAllDrawingsStylesFromBackup();
        }
    }

    @Output() public readonly drawingCreate: EventEmitter<Polyline> = new EventEmitter<Polyline>();
    @Output() public readonly drawingRemove = new EventEmitter<HandDrawingPolyline>();

    constructor(
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly localStore: LocalComponentStore<HandDrawDirectiveState>,
        private readonly viewContainerRef: ViewContainerRef
    ) {
        this.localStore.setState({
            isSelectionModeEnabled: false,
            handDrawings: [],
        });

        this.initializeHandDraw();
        this.watchDrawingMenuActions();
    }

    public async ngOnDestroy() {
        const map = await this.map;

        map.off(CustomLeafletMapEvent.CompleteHandDrawing, this.emitHandDrawingOnCreationCompletion, this);
        map.off(CustomLeafletMapEvent.LoadHandDrawing, this.addEventListenersToLoadedHandDrawing, this);
        map.off(CustomLeafletMapEvent.ClearHandDrawingSelection, this.clearSelection, this);
        this.handDraw?.disable();
    }

    public clearSelection(): void {
        this.closeDrawingMenu();
        this.restoreAllDrawingsStylesFromBackup();
    }

    private async closeDrawingMenu(): Promise<void> {
        (await this.map).closePopup(this.drawingMenuPopup);
    }

    private async initializeHandDraw(): Promise<void> {
        const map = await this.map;
        this.handDraw = new HandDraw(
            map,
            {
                strokeWidth: 2,
                svgZIndex: 1001,
            },
            this.document
        );

        map.on(CustomLeafletMapEvent.CompleteHandDrawing, this.emitHandDrawingOnCreationCompletion, this);
        map.on(CustomLeafletMapEvent.LoadHandDrawing, this.addEventListenersToLoadedHandDrawing, this);
        map.on(CustomLeafletMapEvent.ClearHandDrawingSelection, this.clearSelection, this);
    }

    private saveDrawingWithBackupStyles(newDrawing: HandDrawingPolyline): void {
        this.localStore.patchState((state) => ({
            handDrawings: [
                ...state.handDrawings,
                {
                    drawing: newDrawing,
                    backupStyles: {
                        color: newDrawing.options.color,
                    },
                },
            ],
        }));
    }

    private restoreAllDrawingsStylesFromBackup(): void {
        const allDrawings = this.localStore.selectSnapshotByKey("handDrawings");

        allDrawings.forEach((drawingWithBackupStyles) => {
            drawingWithBackupStyles.drawing.setStyle({
                color: drawingWithBackupStyles.backupStyles.color,
            });
        });
    }

    private onHandDrawingClick(handDrawing: HandDrawingPolyline): void {
        const isSelectionModeEnabled = this.localStore.selectSnapshotByKey("isSelectionModeEnabled");

        if (!isSelectionModeEnabled) {
            return;
        }

        this.restoreAllDrawingsStylesFromBackup();

        if (handDrawing) {
            this.closeDrawingMenu();

            handDrawing.setStyle({
                color: DRAWING_SELECTED_COLOR,
            });

            this.updateAndOpenDrawingMenu(handDrawing);
        }
    }

    private updateAndOpenDrawingMenu(handDrawing: HandDrawingPolyline): void {
        this.handDrawingMenuComponent.instance.handDrawing = handDrawing;
        this.handDrawingMenuComponent.changeDetectorRef.detectChanges();

        this.openDrawingMenu(handDrawing);
    }

    private async openDrawingMenu(handDrawing: HandDrawingPolyline): Promise<void> {
        const polylineVertices = handDrawing.getLatLngs() as LatLng[];
        const southernmostVertex = polylineVertices.reduce((result, vertex) => (result.lat > vertex.lat ? vertex : result));

        (await this.map).openPopup(this.drawingMenuPopup.setLatLng(southernmostVertex));
    }

    private watchDrawingMenuActions(): void {
        this.handDrawingMenuComponent.instance.drawingRemove.pipe(untilDestroyed(this)).subscribe((handDrawing) => {
            this.drawingRemove.emit(handDrawing);
        });
    }

    private emitHandDrawingOnCreationCompletion(event: LeafletEvent): void {
        const { propagatedFrom } = event as unknown as LeafletEvent & { propagatedFrom: Polyline };

        this.drawingCreate.emit(propagatedFrom);
    }

    private addEventListenersToLoadedHandDrawing(event: LeafletEvent): void {
        const handDrawing = (event as LeafletEvent & { drawing: HandDrawingPolyline }).drawing;

        this.saveDrawingWithBackupStyles(handDrawing);
        handDrawing.on("click", () => this.onHandDrawingClick(handDrawing));
    }
}
