import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IncidentSharedDataState, MapArea, SidePanelViewType, TaskStatus } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { AnimationUtils, FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { combineLatestWith } from "rxjs";
import { map } from "rxjs/operators";
import { AreaEditService } from "../../services/area-edit.service";
import { TaskService } from "../../services/task.service";
import { IncidentActions } from "../../state/incident.actions";
import { IncidentState } from "../../state/incident.state";

interface AreaSidePanelComponentState {
    taskStatusPool: TaskStatus[];
}

@Component({
    selector: "sah-client-lib-area-side-panel[taskStatusPool]",
    templateUrl: "area-side-panel.component.html",
    styleUrls: ["area-side-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInSideOffScreenRight()],
})
export class AreaSidePanelComponent {
    @Input() public set taskStatusPool(value: TaskStatus[] | undefined) {
        this.localStore.patchState({ taskStatusPool: value ?? [] });
    }

    protected readonly SidePanelViewType = SidePanelViewType;

    protected readonly areas$ = this.store.select(IncidentSharedDataState.areas);
    protected readonly tasks$ = this.store.select(IncidentSharedDataState.tasks);
    protected readonly teams$ = this.store.select(IncidentSharedDataState.teams);
    protected readonly notAssignedTasks$ = this.tasks$.pipe(
        map((tasks) => tasks.filter((task) => FunctionUtils.isNullOrUndefined(task.areaId)))
    );
    protected readonly viewType$ = this.store.select(IncidentState.sidePanelViewOpenState);
    protected readonly isExpanded$ = this.store
        .select(IncidentState.sidePanelViewOpenState)
        .pipe(
            map(
                (sidePanelViewOpenState) =>
                    sidePanelViewOpenState[SidePanelViewType.AreaList] ||
                    sidePanelViewOpenState[SidePanelViewType.AreaPreview] ||
                    sidePanelViewOpenState[SidePanelViewType.AreaAssignTask]
            )
        );
    protected readonly previewedArea$ = this.store
        .select(IncidentState.previewedAreaId)
        .pipe(map((previewedAreaId) => this.areaService.getAreaFromMapLayer(previewedAreaId)));
    protected readonly previewedAreaTasks$ = this.previewedArea$.pipe(
        RxjsUtils.filterFalsy(),
        combineLatestWith(this.tasks$),
        map(([previewedArea, tasks]) => tasks.filter((task) => task.areaId === previewedArea?.data?.id))
    );

    protected readonly taskStatusPool$ = this.localStore.selectByKey("taskStatusPool");

    constructor(
        protected readonly areaService: AreaEditService,
        private readonly localStore: LocalComponentStore<AreaSidePanelComponentState>,
        private readonly store: Store,
        protected readonly taskService: TaskService
    ) {
        this.localStore.setState({
            taskStatusPool: [],
        });
    }

    public previewArea(area: MapArea): void {
        this.store.dispatch(new IncidentActions.OpenAreaPreview(area?.data?.id));
    }

    protected changeViewType(viewType: SidePanelViewType): void {
        this.store.dispatch(new IncidentActions.OpenSidePanel(viewType));
    }

    protected closePanel(): void {
        this.store.dispatch(IncidentActions.CloseSidePanel);
    }
}
