<ng-container *ngrxLet="{ isOpen: isOpen$, teams: teams$ } as vm">
    <button type="button" class="button-secondary" (click)="openTeamCreator()">
        <dtm-ui-icon name="add"></dtm-ui-icon>
        {{ "sahClientLibIncident.teamPanel.addTeamButtonLabel" | transloco }}
    </button>
    <span class="info-text">{{
        "sahClientLibIncident.teamPanel.numberOfTeamsLabel" | transloco : { value: numberOfTeams$ | ngrxPush }
    }}</span>
    <button type="button" class="button-icon toggle-button" [disabled]="!vm.teams.length" (click)="togglePanel()">
        <dtm-ui-icon *ngIf="vm.isOpen; else arrowDownTemplate" name="arrow-up"></dtm-ui-icon>
        <ng-template #arrowDownTemplate> <dtm-ui-icon name="arrow-down"></dtm-ui-icon></ng-template>
    </button>
    <section *ngIf="vm.isOpen" class="team-list">
        <div *ngFor="let team of vm.teams" class="team" [class]="getTeamTaskStatusClass(team) | ngrxPush">
            <span>{{ team.name }}</span>
            <button type="button" class="remove-team-button" (click)="removeTeam(team); $event.stopPropagation()">
                <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
            </button>
        </div>
    </section>
</ng-container>
