import { TeamCreatorFormValues } from "../../shared/models/team.models";
import { IncidentFormValues } from "../models/incident-creator.models";

export namespace IncidentCreatorActions {
    export class ResetState {
        public static readonly type = "[IncidentCreator] Reset state";
    }

    export class CreateIncident {
        public static readonly type = "[IncidentCreator] Create incident";
        constructor(public incidentFormValues: IncidentFormValues) {}
    }

    export class UpdateIncident {
        public static readonly type = "[IncidentCreator] Update incident";
        constructor(public incidentId: string, public incidentFormValues: IncidentFormValues) {}
    }

    export class GetIncident {
        public static readonly type = "[IncidentCreator] Get incident";
        constructor(public incidentId: string) {}
    }

    export class CreateTeams {
        public static readonly type = "[IncidentCreator] Create teams";
        constructor(public incidentId: string, public teams: TeamCreatorFormValues[]) {}
    }

    export class GetAirOperationsCoordinators {
        public static readonly type = "[IncidentCreator] Get air operations coordinators";
    }

    export class GetFlightsCoordinators {
        public static readonly type = "[IncidentCreator] Get flights coordinators";
    }

    export class GetInstitutions {
        public static readonly type = "[IncidentCreator] Get institutions";
    }

    export class GetUnits {
        public static readonly type = "[IncidentCreator] Get units";
    }

    export class GetRestrictions {
        public static readonly type = "[IncidentCreator] Get restrictions";
    }

    export class GetRestrictionArea {
        public static readonly type = "[IncidentCreator] Get restriction area";
        constructor(public restrictionId: string) {}
    }

    export class GetOperators {
        public static readonly type = "[IncidentCreator] Get operators";
    }

    export class GetPilots {
        public static readonly type = "[IncidentCreator] Get pilots";
        constructor(public operatorId: string) {}
    }

    export class GetPilotsUavs {
        public static readonly type = "[IncidentCreator] Get pilot's uavs";
        constructor(public operatorId: string, public pilotId: string) {}
    }
}
