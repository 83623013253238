<form [formGroup]="actionMarkerForm">
    <dtm-ui-input-field>
        <label for="nameControl">{{ "sahSharedLibIncident.actionMarker.nameLabel" | transloco }}</label>
        <input matInput autocomplete="off" id="nameControl" formControlName="name" type="text" />
        <div class="field-error" *dtmUiFieldHasError="actionMarkerForm.controls.name; name: 'maxlength'; error as error">
            {{ "sahSharedLibIncident.actionMarker.fieldMaxlengthError" | transloco : { maxLength: error.requiredLength } }}
        </div>
    </dtm-ui-input-field>
    <dtm-ui-dms-coordinates-input
        dtmUiMarkValueAccessorControlsAsTouched
        formControlName="latitude"
        [geographicCoordinatesType]="GeographicCoordinatesType.Latitude"
        [inputLabel]="'sahSharedLibIncident.actionMarker.coordinatesLabel' | transloco"
    ></dtm-ui-dms-coordinates-input>
    <dtm-ui-dms-coordinates-input
        dtmUiMarkValueAccessorControlsAsTouched
        formControlName="longitude"
        [geographicCoordinatesType]="GeographicCoordinatesType.Longitude"
    ></dtm-ui-dms-coordinates-input>
    <sah-shared-lib-action-marker-photo-edit
        formControlName="photo"
        [maxSize]="FILE_MAX_SIZE"
        [markerName]="markerName$ | ngrxPush"
        (valueChange)="markPhotoAsUpdated()"
    >
        <div class="field-error" *dtmUiFieldHasError="actionMarkerForm.controls.photo; name: 'maxSize'; error as error">
            {{ "sahSharedLibIncident.actionMarker.maxFileSizeError" | transloco : { maxSize: error.requiredSize | formatFileSize } }}
        </div>
    </sah-shared-lib-action-marker-photo-edit>
    <div class="divider"></div>
    <div class="actions">
        <button type="button" class="button-icon" (click)="editCancel.emit()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
        <button type="button" class="button-icon" (click)="submitForm()">
            <dtm-ui-icon name="check"></dtm-ui-icon>
        </button>
    </div>
</form>
