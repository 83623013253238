import { Injectable } from "@angular/core";
import { HandDrawingPolyline } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Polyline } from "leaflet";
import { ToastrService } from "ngx-toastr";
import { switchMap } from "rxjs";
import { IncidentActions } from "../state/incident.actions";
import { IncidentState } from "../state/incident.state";

@UntilDestroy()
@Injectable()
export class HandDrawingEditService {
    constructor(
        private readonly dialogService: DialogService,
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {}

    public createHandDrawing(layer: Polyline): void {
        this.store
            .dispatch(new IncidentActions.CreateHandDrawing(layer))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.createHandDrawingError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahClientLibIncident.incident.createHandDrawingError"));

                    return;
                }
            });
    }

    public removeHandDrawing(drawing: HandDrawingPolyline): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("sahClientLibIncident.incident.confirmHandDrawingRemoveTitle"),
                    confirmationText: this.translocoService.translate("sahClientLibIncident.incident.confirmMessage"),
                    confirmButtonLabel: this.translocoService.translate("sahClientLibIncident.incident.confirmButtonLabel"),
                    declineButtonLabel: this.translocoService.translate("sahClientLibIncident.incident.declineButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new IncidentActions.RemoveHandDrawing(drawing))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.removeHandDrawingError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahClientLibIncident.incident.handDrawingRemoveError"));

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahClientLibIncident.incident.handDrawingRemoveSuccessMessage")
                );
            });
    }
}
