import { FormArray, FormControl, FormGroup } from "@angular/forms";
import { ChipValue } from "@dtm-frontend/shared/ui";

export interface UavCreatorFormValues {
    name: string | null;
    type: string | null;
    equipment: ChipValue[] | null;
}

export interface TeamCreatorFormValues {
    name: string | null;
    operator: Operator | null;
    pilot: Pilot | null;
    pilotName: string | null;
    uavs: Partial<UavCreatorFormValues>[];
}

export interface UavCreatorForm {
    name: FormControl<string | null>;
    type: FormControl<string | null>;
    equipment: FormControl<ChipValue[] | null>;
}

export interface TeamCreatorForm {
    name: FormControl<string | null>;
    operator: FormControl<Operator | null>;
    pilot: FormControl<Pilot | null>;
    pilotName: FormControl<string | null>;
    uavs: FormArray<FormGroup<Partial<UavCreatorForm>>>;
}

export enum UavType {
    FixedWing = "FixedWing",
    VTOL = "VTOL",
    Helicopter = "Helicopter",
    Blimp = "Blimp",
    Multirotor = "Multirotor",
    Other = "Other",
}

export enum BasicUavEquipment {
    NightVision = "NIGHT_VISION",
    ThermalVision = "THERMAL_VISION",
    Camera = "CAMERA",
    Loudspeaker = "LOUDSPEAKER",
    Spotlight = "SPOTLIGHT",
}

export interface Operator {
    id: string;
    name: string;
}

export interface Pilot {
    userId: string;
    pilotId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    uavs: Array<PilotsUav>;
}

export interface PilotsUav {
    name: string;
    equipment: ChipValue[];
}

export interface OperatorPilots {
    [key: string]: { [key: string]: Pilot };
}
