import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { SahPath } from "../shared/index";
import { ActiveIncidentComponent } from "./components/active-incident/active-incident.component";
import { PlannedIncidentComponent } from "./components/planned-incident/planned-incident.component";

const routes: Routes = [
    {
        path: SahPath.PlannedIncident,
        component: PlannedIncidentComponent,
        canActivate: [DtmRoleGuard],
    },
    {
        path: SahPath.ActiveIncident,
        component: ActiveIncidentComponent,
        canActivate: [DtmRoleGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class IncidentRoutingModule {}
