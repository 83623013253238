<dtm-ui-loader-container
    *ngrxLet="{
        selectedMapToolName: selectedMapToolName$,
        isIncidentValid: isIncidentValid$,
        hasUserLostAccess: hasUserLostAccess$,
        isIncidentMapDataProcessing: isIncidentMapDataProcessing$,
        isSelectionEnabled: isSelectionEnabled$,
        sidePanelViewOpenState: sidePanelViewOpenState$,
        mapFiltersState: mapFiltersState$,
        isAreaDrawEnabled: isAreaDrawEnabled$,
        isHandDrawEnabled: isHandDrawEnabled$,
        isActionMarkerEnabled: isActionMarkerEnabled$,
    } as vm"
    [isShown]="vm.isIncidentMapDataProcessing"
>
    <dtm-map-leaflet-map>
        <sah-shared-lib-tasks-map-layer (taskMarkerClick)="areaEditService.previewArea($event)"></sah-shared-lib-tasks-map-layer>

        <sah-client-lib-draw-areas
            [isModeEnabled]="vm.isAreaDrawEnabled"
            [selectedToolName]="vm.selectedMapToolName"
            (areaCreate)="areaEditService.createArea($event)"
        ></sah-client-lib-draw-areas>
        <sah-client-lib-edit-areas
            [isModeEnabled]="vm.isSelectionEnabled"
            [selectedArea]="selectedArea$ | ngrxPush"
            (areaSelect)="areaEditService.selectArea($event)"
            (areaRemove)="areaEditService.removeArea($event)"
            (areaEdit)="areaEditService.updateArea($event)"
            (areaTasksPreview)="areaEditService.previewArea($event)"
        ></sah-client-lib-edit-areas>
        <sah-client-lib-hand-draw
            [isDrawingEnabled]="vm.isHandDrawEnabled"
            [isSelectionModeEnabled]="vm.isSelectionEnabled"
            (drawingCreate)="handDrawingEditService.createHandDrawing($event)"
            (drawingRemove)="handDrawingEditService.removeHandDrawing($event)"
        ></sah-client-lib-hand-draw>
        <sah-client-lib-ruler [isRulerEnabled]="isRulerEnabled$ | ngrxPush"></sah-client-lib-ruler>
        <sah-shared-lib-action-markers-layer
            withContextMenu
            [actionMarkers]="actionMarkers$ | ngrxPush"
            [isActionMarkerProcessing]="isActionMarkerProcessing$ | ngrxPush"
            [selectedToolName]="vm.selectedMapToolName"
            [isCreateModeEnabled]="vm.isActionMarkerEnabled"
            [isSelectModeEnabled]="vm.isSelectionEnabled"
            (markerAdd)="actionMarkerService.addActionMarker($event)"
            (markerUpdate)="actionMarkerService.updateActionMarker($event)"
            (markerRemove)="actionMarkerService.removeActionMarker($event)"
            (markerPhotoLoad)="actionMarkerService.loadActionMarkerPhoto($event)"
            (markerSelect)="actionMarkerService.selectActionMarker($event)"
            (draggableToggle)="actionMarkerService.toggleSelectedMarkerDragging($event)"
        ></sah-shared-lib-action-markers-layer>
    </dtm-map-leaflet-map>

    <ng-container *ngIf="vm.isIncidentValid && !vm.hasUserLostAccess">
        <section class="top-left-controls">
            <sah-client-lib-team-panel></sah-client-lib-team-panel>
            <sah-client-lib-incident-toolbar
                [selectedMapToolName]="vm.selectedMapToolName"
                (toolSelect)="selectMapTool($event)"
            ></sah-client-lib-incident-toolbar>
        </section>
        <section class="top-right-controls">
            <button type="button" class="button-icon map-button" (click)="openSidePanel(SidePanelViewType.Chat)">
                <dtm-ui-icon name="chat"></dtm-ui-icon>
            </button>
            <sah-client-lib-map-filters-panel
                [incidentStatus]="IncidentStatus.Active"
                [mapFiltersState]="vm.mapFiltersState"
                [isOpen]="vm.sidePanelViewOpenState[SidePanelViewType.MapLayers]"
                [isAreaDrawEnabled]="vm.isAreaDrawEnabled"
                [isHandDrawEnabled]="vm.isHandDrawEnabled"
                [isActionMarkerEnabled]="vm.isActionMarkerEnabled"
                (panelOpen)="openSidePanel(SidePanelViewType.MapLayers)"
                (panelClose)="closeSidePanel()"
                (mapFiltersStateUpdate)="updateMapFiltersState($event)"
            ></sah-client-lib-map-filters-panel>
            <button type="button" class="button-secondary map-button" (click)="openSidePanel(SidePanelViewType.AreaList)">
                {{ "sahClientLibIncident.incident.areaSidePanelButtonLabel" | transloco }}
                <dtm-ui-icon name="shapes"></dtm-ui-icon>
            </button>
            <button type="button" class="button-secondary map-button" (click)="openSidePanel(SidePanelViewType.TaskList)">
                {{ "sahClientLibIncident.incident.taskSidePanelButtonLabel" | transloco }}
                <dtm-ui-icon name="todo"></dtm-ui-icon>
            </button>
        </section>

        <sah-client-lib-area-side-panel [taskStatusPool]="TASK_STATUS_POOL"></sah-client-lib-area-side-panel>
        <sah-client-lib-task-side-panel [taskStatusPool]="TASK_STATUS_POOL"></sah-client-lib-task-side-panel>
        <sah-client-lib-team-creator-side-panel></sah-client-lib-team-creator-side-panel>
        <sah-client-lib-chat-side-panel></sah-client-lib-chat-side-panel>
    </ng-container>

    <div *ngIf="!vm.isIncidentValid && !vm.isIncidentMapDataProcessing" class="error-container">
        <dtm-ui-info-message type="error">
            <p>{{ "sahClientLibIncident.incident.activeInvalidIncidentError" | transloco }}</p>
            <button type="button" class="button-tertiary" [routerLink]="SahAbsolutePath.Dashboard">
                {{ "sahClientLibIncident.incident.goBackToHomePageButtonLabel" | transloco }}
            </button>
        </dtm-ui-info-message>
    </div>
    <div *ngIf="vm.hasUserLostAccess && !vm.isIncidentMapDataProcessing" class="error-container">
        <dtm-ui-info-message type="error">
            <p>{{ "sahClientLibIncident.incident.userLostAccessError" | transloco }}</p>
            <button type="button" class="button-tertiary" [routerLink]="SahAbsolutePath.Dashboard">
                {{ "sahClientLibIncident.incident.goBackToHomePageButtonLabel" | transloco }}
            </button>
        </dtm-ui-info-message>
    </div>
</dtm-ui-loader-container>
