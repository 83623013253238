import { Injectable } from "@angular/core";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Map } from "leaflet";
import { Subject, withLatestFrom } from "rxjs";
import { CustomLeafletMapEvent, MapArea } from "../models/incident-map.models";
import { Task } from "../models/task.models";
import { IncidentSharedDataState } from "../state/incident-shared-data.state";

@UntilDestroy()
@Injectable()
export class TaskMarkerService {
    private map: Map | undefined;
    private taskMarkerUpdateSubject = new Subject<MapArea>();

    public taskMarkerUpdate$ = this.taskMarkerUpdateSubject.asObservable();

    constructor(private readonly store: Store) {}

    public initMapWithTaskMarkers(map: Map, areas: MapArea[], tasks: Task[]) {
        this.map = map;
        this.loadTaskMarkers(areas, tasks);

        this.watchAreaUpdate();
        this.watchAreaRemove();
    }

    public notifyAboutTaskMarkerUpdate(area: MapArea): void {
        this.taskMarkerUpdateSubject.next(area);
    }

    public reloadTaskMarkers(areas: MapArea[], tasks: Task[]): void {
        this.map?.fire(CustomLeafletMapEvent.RemoveAllTaskMarkers);
        this.loadTaskMarkers(areas, tasks);
    }

    private loadTaskMarkers(areas: MapArea[], tasks: Task[]): void {
        areas.forEach((area) => this.map?.fire(CustomLeafletMapEvent.CreateTaskMarker, { area, tasks: this.getAreaTasks(area, tasks) }));
    }

    private getAreaTasks(area: MapArea, tasks: Task[]): Task[] {
        return tasks.filter((task) => area.data?.taskIds?.includes(task.id));
    }

    private watchAreaUpdate(): void {
        this.store
            .select(IncidentSharedDataState.updatedArea)
            .pipe(RxjsUtils.filterFalsy(), withLatestFrom(this.store.select(IncidentSharedDataState.tasks)), untilDestroyed(this))
            .subscribe(([area, tasks]) =>
                this.map?.fire(CustomLeafletMapEvent.UpdateTaskMarker, { area, tasks: this.getAreaTasks(area, tasks) })
            );
    }

    private watchAreaRemove(): void {
        this.store
            .select(IncidentSharedDataState.removedAreaId)
            .pipe(untilDestroyed(this))
            .subscribe((areaId) => this.map?.fire(CustomLeafletMapEvent.RemoveTaskMarker, { areaId }));
    }
}
