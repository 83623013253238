<dtm-ui-loader-container *ngrxLet="{ viewType: viewType$, actionMarkerData: actionMarkerData$ } as vm" [isShown]="isProcessing$ | ngrxPush">
    <div class="container" [ngSwitch]="vm.viewType">
        <sah-shared-lib-action-marker-preview
            *ngSwitchCase="ViewType.Preview"
            [markerData]="vm.actionMarkerData"
            (iconChange)="toggleIconMenu()"
            (dragToggle)="dragToggle.emit($event)"
            (edit)="changeView(ViewType.Edit)"
            (remove)="remove.emit()"
        ></sah-shared-lib-action-marker-preview>
        <sah-shared-lib-action-marker-editor
            *ngSwitchCase="ViewType.Create"
            [markerData]="vm.actionMarkerData"
            (editCancel)="addCancel.emit()"
            (dataSubmit)="addMarker($event)"
        ></sah-shared-lib-action-marker-editor>
        <sah-shared-lib-action-marker-editor
            *ngSwitchCase="ViewType.Edit"
            [markerData]="vm.actionMarkerData"
            (editCancel)="changeView(ViewType.Preview)"
            (dataSubmit)="updateMarker($event)"
        ></sah-shared-lib-action-marker-editor>
    </div>

    <sah-shared-lib-action-markers-select-list
        *ngIf="isIconMenuOpen$ | ngrxPush"
        [selectedActionMarker]="vm.actionMarkerData?.tool?.name"
        (markerSelect)="updateMarker({ tool: $event }); toggleIconMenu()"
    ></sah-shared-lib-action-markers-select-list>
</dtm-ui-loader-container>
