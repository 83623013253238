import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { SahPath } from "../shared/defaults/paths";
import { IncidentCreatorComponent } from "./components/incident-creator/incident-creator.component";

const routes: Routes = [
    {
        path: SahPath.CreateIncident,
        component: IncidentCreatorComponent,
        canActivate: [DtmRoleGuard],
    },
    {
        path: SahPath.EditIncident,
        component: IncidentCreatorComponent,
        data: { isEditMode: true },
        canActivate: [DtmRoleGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class IncidentCreatorRoutingModule {}
