import { disableEditAndDrag, enableEditAndDrag, onDragEnd, onDragStart } from "./utils/edit-and-drag";
import { removeLastPolygonVertex } from "./utils/remove-last-polygon-vertex";

export const GeomanUtils = {
    removeLastPolygonVertex,
    disableEditAndDrag,
    enableEditAndDrag,
    onDragStart,
    onDragEnd,
};
