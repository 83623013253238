import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import { Store } from "@ngxs/store";

const LOGO_HEIGHT_PX = 140;
const LOGO_WIDTH_PX = 450;

@Component({
    templateUrl: "login-page.component.html",
    styleUrls: ["login-page.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginPageComponent {
    protected readonly LOGO_HEIGHT_PX = LOGO_HEIGHT_PX;
    protected readonly LOGO_WIDTH_PX = LOGO_WIDTH_PX;

    protected readonly isProcessing$ = this.store.select(AuthState.isProcessing);
    protected readonly hasLoginError$ = this.store.select(AuthState.hasLoginError);
    protected readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);

    constructor(private readonly store: Store) {}

    protected login({ username, password }: { username: string; password: string }): void {
        this.store.dispatch(new AuthActions.LogIn(username, password));
    }
}
