import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { IconName } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TaskStatus } from "../../../models/task.models";

interface TaskStatusBadgeComponentState {
    status: TaskStatus | undefined;
}

@Component({
    selector: "sah-shared-lib-task-status-badge[status]",
    templateUrl: "task-status-badge.component.html",
    styleUrls: ["task-status-badge.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TaskStatusBadgeComponent {
    @Input() public set status(value: TaskStatus | undefined) {
        this.localStore.patchState({ status: value });
    }

    protected readonly status$ = this.localStore.selectByKey("status");

    constructor(private readonly localStore: LocalComponentStore<TaskStatusBadgeComponentState>) {
        this.localStore.setState({
            status: undefined,
        });
    }

    protected getStatusClass(taskStatus: TaskStatus | undefined): string {
        switch (taskStatus) {
            case TaskStatus.Active:
                return "active";
            case TaskStatus.Completed:
                return "completed";
            case TaskStatus.Incomplete:
                return "incomplete";
            case TaskStatus.Paused:
                return "paused";
            case TaskStatus.PendingAcceptance:
                return "pending-acceptance";
            case TaskStatus.Planned:
                return "planned";
            case TaskStatus.Rejected:
                return "rejected";
            default:
                return "";
        }
    }

    protected getStatusIconName(taskStatus: TaskStatus | undefined): IconName {
        switch (taskStatus) {
            case TaskStatus.Active:
                return "play-circle";
            case TaskStatus.Completed:
                return "checkbox-circle";
            case TaskStatus.Incomplete:
                return "loader";
            case TaskStatus.Paused:
                return "pause-circle";
            case TaskStatus.PendingAcceptance:
                return "postpone";
            case TaskStatus.Planned:
                return "markup";
            case TaskStatus.Rejected:
                return "forbid";
            default:
                return "";
        }
    }
}
