<div class="container">
    <div class="logo">
        <img ngSrc="/assets/images/logo.svg" alt="logo" [height]="LOGO_HEIGHT_PX" [width]="LOGO_WIDTH_PX" />
    </div>

    <dtm-auth-login
        [isProcessing]="isProcessing$ | ngrxPush"
        [hasLoginError]="hasLoginError$ | ngrxPush"
        (login)="login($event)"
    ></dtm-auth-login>
</div>
