import { HttpStatusCode } from "@angular/common/http";
import { IncidentCategory, IncidentList } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { PageResponseBody } from "@dtm-frontend/shared/ui";
import { DashboardErrorType, DashboardIncidentActivationError } from "../models/dasboard.models";

export enum GetIncidentsScope {
    Coordinator = "COORDINATOR",
}

export type GetIncidentsResponseBody = PageResponseBody<{
    id: string;
    name: string;
    categories: IncidentCategory[];
    leadInstitution: {
        name: string;
        unit: string;
    };
    numberOfTasks: {
        active: number;
        all: number;
        unassigned: number;
    };
}>;

interface ActivateIncidentErrorResponseBody {
    status: HttpStatusCode;
    error: {
        generalMessage: string;
        args: Record<string, unknown>;
    };
}

export function convertGetIncidentsResponseBodyToIncidentList(response: GetIncidentsResponseBody): IncidentList {
    return {
        content: response.content.map((incident) => ({
            id: incident.id,
            name: incident.name,
            categories: incident.categories,
            leadInstitution: `${incident.leadInstitution.name} ${incident.leadInstitution.unit}`,
        })),
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}

export function convertActivateIncidentErrorResponseBodyToDashboardIncidentActivationError(
    errorResponseBody: ActivateIncidentErrorResponseBody
): DashboardIncidentActivationError {
    switch (errorResponseBody.status) {
        case HttpStatusCode.Conflict:
            return {
                type: DashboardErrorType.Conflict,
                args: errorResponseBody.error.args,
                messageKey: errorResponseBody.error.generalMessage,
            };
        default:
            return { type: DashboardErrorType.Unknown, args: {}, messageKey: "" };
    }
}
