import { HttpStatusCode } from "@angular/common/http";
import {
    AreaEntity,
    IncidentError,
    IncidentErrorType,
    MapArea,
    TaskCreatorFormValues,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { ChipOption, ChipType } from "@dtm-frontend/shared/ui";
import { Circle, GeoJSON } from "leaflet";
import { Pilot, PilotsUav } from "../../shared/models/team.models";

interface ErrorResponseBody {
    status: HttpStatusCode;
    error: {
        generalMessage: string;
        args: Record<string, unknown>;
    };
}

interface CreateOrUpdateTaskRequestPayload {
    name: string | null;
    altitudeRange: {
        floor: number | null;
        ceiling: number | null;
    };
    timeRange: {
        start: Date | null;
        stop: Date | null;
    };
    notes: string | null;
}

export interface GetTeamCreatorHintsResponseBody {
    equipment: string[];
}

export type GetOperatorsResponseBody = Array<{ id: string; name: string }>;
export type GetPilotsResponseBody = Array<{ userId: string; pilotId: string; firstName: string; lastName: string; fullName: string }>;
export type GetPilotUavsResponseBody = Array<{
    name: string;
    uavSetups: Array<{
        equipment: {
            items: Array<{
                id: string;
                name: string;
                type: string;
            }>;
        };
    }>;
}>;

export function convertMapAreaToAreaEntity(area: MapArea): Partial<AreaEntity> {
    const areaData: Partial<AreaEntity> = {
        id: area.data?.id,
        version: area.data?.version,
        taskIds: area.data?.taskIds,
        name: area.data?.name,
    };

    if (area instanceof Circle) {
        return {
            center: area.toGeoJSON().geometry,
            radius: area.getRadius(),
            ...areaData,
        };
    }

    return { geometry: area.toGeoJSON().geometry as GeoJSON.Polygon, ...areaData };
}

export function convertTaskCreatorFormValuesToCreateOrUpdateTaskRequestPayload({
    name,
    floorAltitude,
    ceilingAltitude,
    startTime,
    endTime,
    details,
}: TaskCreatorFormValues): CreateOrUpdateTaskRequestPayload {
    return {
        name,
        altitudeRange: {
            floor: floorAltitude,
            ceiling: ceilingAltitude,
        },
        timeRange: {
            start: startTime,
            stop: endTime,
        },
        notes: details,
    };
}

export function convertGetTeamCreatorHintsResponseBodyToChipOptionArray(responseBody: GetTeamCreatorHintsResponseBody): ChipOption[] {
    return responseBody.equipment.map((accessory) => ({ value: accessory, label: accessory, isDisabled: false }));
}

export function convertErrorResponseBodyToIncidentError(errorResponseBody: ErrorResponseBody): IncidentError {
    switch (errorResponseBody.status) {
        case HttpStatusCode.Conflict:
            return {
                type: IncidentErrorType.Conflict,
                args: errorResponseBody.error.args,
                messageKey: errorResponseBody.error.generalMessage,
            };
        default:
            return { type: IncidentErrorType.Unknown, args: {}, messageKey: "" };
    }
}

export function convertGetPilotsResponseBodyToPilotsArray(response: GetPilotsResponseBody): Pilot[] {
    return response.map((pilot) => ({ ...pilot, uavs: [] }));
}

export function convertGetPilotsUavsResponseBodyToPilotUavsArray(response: GetPilotUavsResponseBody): PilotsUav[] {
    return response.map((pilotsUav) => ({
        name: pilotsUav.name,
        equipment: pilotsUav.uavSetups
            .flatMap((uavSetup) => uavSetup.equipment.items)
            .map((item) => ({ type: ChipType.Custom, value: item.name, label: item.name, isRemovable: true })),
    }));
}
