import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Task } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { EmptyStateMode } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AreaTaskSelectComponentState {
    tasks: Task[];
}

@Component({
    selector: "sah-client-lib-area-task-select[tasks]",
    templateUrl: "area-task-select.component.html",
    styleUrls: ["area-task-select.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AreaTaskSelectComponent {
    @Input() public set tasks(value: Task[] | undefined) {
        this.localStore.patchState({ tasks: value ?? [] });
    }

    @Output() protected readonly taskSelect = new EventEmitter<Task>();

    protected readonly tasks$ = this.localStore.selectByKey("tasks");

    protected readonly EmptyStateMode = EmptyStateMode;

    constructor(private readonly localStore: LocalComponentStore<AreaTaskSelectComponentState>) {
        this.localStore.setState({
            tasks: [],
        });
    }
}
