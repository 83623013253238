import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { TaskStatus } from "../../../models/task.models";

interface TaskStatusBadgeMenuComponentState {
    availableStatuses: TaskStatus[];
    status: TaskStatus | undefined;
    isPreview: boolean;
}

@UntilDestroy()
@Component({
    selector: "sah-shared-lib-task-status-badge-menu[status][availableStatuses]",
    templateUrl: "./task-status-badge-menu.component.html",
    styleUrls: ["./task-status-badge-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TaskStatusBadgeMenuComponent {
    @Input() public set status(value: TaskStatus | undefined) {
        this.localStore.patchState({ status: value });
    }

    @Input() public set availableStatuses(value: TaskStatus[] | undefined) {
        this.localStore.patchState({ availableStatuses: value ?? [] });
    }

    @Input() public set isPreview(value: BooleanInput) {
        this.localStore.patchState({ isPreview: coerceBooleanProperty(value) });
    }

    @Output() protected statusChange = new EventEmitter<TaskStatus>();

    protected readonly status$ = this.localStore.selectByKey("status");
    protected readonly statuses$ = this.localStore.selectByKey("availableStatuses");
    protected readonly isPreview$ = this.localStore.selectByKey("isPreview");

    constructor(
        private readonly localStore: LocalComponentStore<TaskStatusBadgeMenuComponentState>,
        private readonly dialogService: DialogService,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            availableStatuses: [],
            status: undefined,
            isPreview: false,
        });
    }

    protected openStatusChangeDialog(newStatus: TaskStatus): void {
        const newStatusTranslation = this.translocoService.translate("sahSharedLibIncident.tasks.taskStatusValue", {
            status: newStatus,
        });

        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("sahSharedLibIncident.tasks.taskStatusBadgeMenu.statusChangeDialog.title"),
                    confirmationText: this.translocoService.translate(
                        "sahSharedLibIncident.tasks.taskStatusBadgeMenu.statusChangeDialog.confirmationText",
                        {
                            status: newStatusTranslation,
                        }
                    ),
                },
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => this.statusChange.emit(newStatus));
    }
}
