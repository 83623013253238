<ng-container *ngrxLet="{ isEditMode: isEditMode$, isProcessing: isProcessing$ } as vm">
    <div dtmUiInvalidFormScrollable class="panel-content scroll-shadows">
        <h2>
            {{
                vm.isEditMode
                    ? ("sahClientLibIncidentCreator.incidentCreator.dataPanel.editHeader" | transloco)
                    : ("sahClientLibIncidentCreator.incidentCreator.dataPanel.header" | transloco)
            }}
        </h2>

        <form [formGroup]="incidentForm">
            <section>
                <h3>{{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.basicInformationHeader" | transloco }}</h3>

                <div class="fields-container">
                    <dtm-ui-input-field>
                        <label>{{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.incidentNameLabel" | transloco }}</label>
                        <input matInput type="text" autocomplete="off" required [formControl]="incidentForm.controls.name" />
                        <div class="field-error" *dtmUiFieldHasError="incidentForm.controls.name; name: ['required']">
                            {{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.requiredError" | transloco }}
                        </div>
                        <div class="field-error" *dtmUiFieldHasError="incidentForm.controls.name; name: 'maxlength'; error as error">
                            {{
                                "sahClientLibIncidentCreator.incidentCreator.dataPanel.maxLengthError"
                                    | transloco : { maxLength: error.requiredLength }
                            }}
                        </div>
                    </dtm-ui-input-field>

                    <dtm-ui-select-field [formControl]="incidentForm.controls.categories" [multiple]="true">
                        <label>{{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.incidentCategoryLabel" | transloco }}</label>
                        <dtm-ui-select-option *ngFor="let category of INCIDENT_CATEGORIES" [value]="category">
                            {{
                                "sahClientLibIncidentCreator.incidentCreator.dataPanel.incidentCategoryValueLabel"
                                    | transloco : { value: category }
                            }}
                        </dtm-ui-select-option>
                        <div class="field-error" *dtmUiFieldHasError="incidentForm.controls.categories; name: ['required']">
                            {{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.requiredError" | transloco }}
                        </div>
                    </dtm-ui-select-field>

                    <div class="field-with-popover-wrapper">
                        <dtm-ui-checkbox-field [formControl]="incidentForm.controls.isPlanned">
                            {{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.isPlannedLabel" | transloco }}
                        </dtm-ui-checkbox-field>
                        <dtm-ui-popover
                            *ngIf="incidentForm.controls.isPlanned.enabled"
                            [popoverText]="'sahClientLibIncidentCreator.incidentCreator.dataPanel.isPlannedPopoverMessage' | transloco"
                        ></dtm-ui-popover>
                    </div>
                </div>
            </section>

            <section>
                <h3>{{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.incidentOrganizationHeader" | transloco }}</h3>

                <div class="fields-container">
                    <dtm-ui-radio-group [formControl]="incidentForm.controls.isCustomArea">
                        <div class="field-with-popover-wrapper">
                            <dtm-ui-radio-field [value]="true">
                                {{
                                    "sahClientLibIncidentCreator.incidentCreator.dataPanel.customAreaWithoutRestrictionsLabel" | transloco
                                }}</dtm-ui-radio-field
                            >
                            <dtm-ui-popover
                                [popoverText]="
                                    'sahClientLibIncidentCreator.incidentCreator.dataPanel.customAreaWithoutRestrictionsPopoverMessage'
                                        | transloco
                                "
                            ></dtm-ui-popover>
                        </div>
                        <div class="field-with-popover-wrapper">
                            <dtm-ui-radio-field [value]="false">
                                {{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.existingRestrictedZoneLabel" | transloco }}
                            </dtm-ui-radio-field>
                            <dtm-ui-popover
                                [popoverText]="
                                    'sahClientLibIncidentCreator.incidentCreator.dataPanel.existingRestrictedZonePopoverMessage' | transloco
                                "
                            ></dtm-ui-popover>
                        </div>

                        <dtm-ui-form-errors class="preserve-error-space">
                            <div class="field-error" *dtmUiFieldHasError="incidentForm.controls.isCustomArea; name: ['required']">
                                {{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.requiredError" | transloco }}
                            </div>
                            <div class="field-error" *dtmUiFieldHasError="incidentForm.controls.customArea; name: ['required']">
                                {{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.customAreaRequiredError" | transloco }}
                            </div>
                        </dtm-ui-form-errors>
                    </dtm-ui-radio-group>

                    <ng-container *ngIf="incidentForm.controls.restriction.enabled">
                        <dtm-ui-info-message *ngIf="hasGetRestrictionsError$ | ngrxPush; else restrictionsSelectTemplate" type="error">
                            {{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.getRestrictionsError" | transloco }}
                        </dtm-ui-info-message>

                        <ng-template #restrictionsSelectTemplate>
                            <ng-container *ngrxLet="restrictions$ as restrictions">
                                <dtm-ui-select-field
                                    *ngIf="restrictions?.length; else noRestrictionsTemplate"
                                    @slideIn
                                    [formControl]="incidentForm.controls.restriction"
                                    [compareWith]="compareSelectOptionsWithIds"
                                    [isClearable]="false"
                                >
                                    <label>{{
                                        "sahClientLibIncidentCreator.incidentCreator.dataPanel.incidentAreaLabel" | transloco
                                    }}</label>
                                    <dtm-ui-select-option *ngFor="let restriction of restrictions" [value]="restriction">
                                        {{ restriction.name }}
                                    </dtm-ui-select-option>
                                    <div class="field-error" *dtmUiFieldHasError="incidentForm.controls.restriction; name: ['required']">
                                        {{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.requiredError" | transloco }}
                                    </div>
                                </dtm-ui-select-field>
                            </ng-container>

                            <ng-template #noRestrictionsTemplate>
                                <dtm-ui-info-message @slideIn>{{
                                    "sahClientLibIncidentCreator.incidentCreator.dataPanel.noRestrictionsMessage" | transloco
                                }}</dtm-ui-info-message>
                            </ng-template>
                        </ng-template>
                    </ng-container>
                </div>
            </section>

            <section>
                <h3>{{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.incidentManagementHeader" | transloco }}</h3>

                <div class="fields-container">
                    <dtm-ui-select-field
                        [formControl]="incidentForm.controls.leadInstitution"
                        [isClearable]="false"
                        [compareWith]="compareSelectOptionsWithIds"
                    >
                        <label>{{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.leadInstitutionLabel" | transloco }}</label>
                        <dtm-ui-select-option *ngFor="let institution of institutions$ | ngrxPush" [value]="institution">
                            {{ institution.name }}
                        </dtm-ui-select-option>
                        <div class="field-error" *dtmUiFieldHasError="incidentForm.controls.leadInstitution; name: ['required']">
                            {{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.requiredError" | transloco }}
                        </div>
                    </dtm-ui-select-field>

                    <dtm-ui-select-field [formControl]="incidentForm.controls.unit" [isClearable]="false" [compareWith]="compareUnits">
                        <label>{{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.unitLabel" | transloco }}</label>
                        <dtm-ui-select-option *ngFor="let unit of units$ | ngrxPush" [value]="unit">
                            {{ unit.name }}
                        </dtm-ui-select-option>
                        <div class="field-error" *dtmUiFieldHasError="incidentForm.controls.unit; name: ['required']">
                            {{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.requiredError" | transloco }}
                        </div>
                    </dtm-ui-select-field>

                    <dtm-ui-label-value
                        [label]="'sahClientLibIncidentCreator.incidentCreator.dataPanel.airOperationsCoordinatorLabel' | transloco"
                        [value]="incidentForm.controls.airOperationsCoordinator.value | invoke : getCoordinatorFullName"
                    ></dtm-ui-label-value>

                    <dtm-ui-select-field
                        [formControl]="incidentForm.controls.deputyAirOperationsCoordinators"
                        [multiple]="true"
                        [compareWith]="compareSelectOptionsWithIds"
                    >
                        <label>{{
                            "sahClientLibIncidentCreator.incidentCreator.dataPanel.deputyAirOperationsCoordinatorsLabel" | transloco
                        }}</label>
                        <dtm-ui-select-option
                            *ngFor="let coordinator of deputyAirOperationsCoordinatorsPool$ | ngrxPush"
                            [value]="coordinator"
                        >
                            {{ coordinator | invoke : getCoordinatorFullName }}
                        </dtm-ui-select-option>
                    </dtm-ui-select-field>

                    <div class="field-with-popover-wrapper">
                        <dtm-ui-checkbox-field [formControl]="incidentForm.controls.isRequiredTaskReconciliation">
                            {{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.requiredTaskReconciliationLabel" | transloco }}
                        </dtm-ui-checkbox-field>
                        <dtm-ui-popover
                            [popoverText]="
                                'sahClientLibIncidentCreator.incidentCreator.dataPanel.requiredTaskReconciliationPopoverMessage' | transloco
                            "
                        ></dtm-ui-popover>
                    </div>

                    <dtm-ui-select-field
                        *ngIf="incidentForm.controls.isRequiredTaskReconciliation.value"
                        @slideIn
                        [formControl]="incidentForm.controls.flightsCoordinators"
                        [multiple]="true"
                        [compareWith]="compareSelectOptionsWithIds"
                    >
                        <label
                            >{{ "sahClientLibIncidentCreator.incidentCreator.dataPanel.flightsCoordinatorsLabel" | transloco }}
                            <dtm-ui-popover
                                [popoverText]="
                                    'sahClientLibIncidentCreator.incidentCreator.dataPanel.flightsCoordinatorsPopoverMessage' | transloco
                                "
                            ></dtm-ui-popover>
                        </label>
                        <dtm-ui-select-option *ngFor="let coordinator of flightsCoordinatorsPool$ | ngrxPush" [value]="coordinator">
                            {{ coordinator | invoke : getCoordinatorFullName }}
                        </dtm-ui-select-option>
                    </dtm-ui-select-field>
                </div>
            </section>
        </form>
    </div>

    <div class="panel-actions">
        <button type="button" class="button-secondary" [disabled]="vm.isProcessing" (click)="formCancel.emit()">
            {{ "sahClientLibIncidentCreator.incidentCreator.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" [disabled]="vm.isProcessing" (click)="submit()">
            <mat-spinner *ngIf="vm.isProcessing" diameter="20" color="accent"></mat-spinner>
            {{
                vm.isEditMode
                    ? ("sahClientLibIncidentCreator.incidentCreator.updateIncidentButtonLabel" | transloco)
                    : ("sahClientLibIncidentCreator.incidentCreator.createIncidentButtonLabel" | transloco)
            }}
        </button>
    </div>
</ng-container>
