export enum TeamStatus {
    Undefined = "UNDEFINED",
    Ready = "READY",
    NotReady = "NOT_READY",
    InProgress = "IN_PROGRESS",
    Problem = "PROBLEM",
}

export interface Team {
    id: string;
    teamId: string;
    name: string;
    version: number;
    pilot?: TeamPilot;
}

export interface TeamPilot {
    userId?: string;
    pilotId?: string;
    fullName: string;
}
