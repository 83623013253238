import { Team, TeamStatus } from "./team.models";

export enum TaskStatus {
    Active = "ACTIVE",
    Completed = "COMPLETED",
    Incomplete = "INCOMPLETE",
    Paused = "PAUSED",
    PendingAcceptance = "PENDING_ACCEPTANCE",
    Planned = "PLANNED",
    Rejected = "REJECTED",
}

export interface Task {
    id: string;
    name: string;
    status: TaskStatus;
    areaId?: string;
    altitudeRange: {
        floor: number;
        ceiling: number;
    };
    timeRange?: {
        start?: Date;
        stop?: Date;
    };
    attachedTeam?: {
        id: string;
        teamId: string;
        name: string;
        status: TeamStatus;
        version: number;
    };
    isAirborne: boolean;
    version: number;
    details: string;
    rejectionReason: string | undefined;
    isMine?: boolean;
}

export interface TaskCreatorFormValues {
    name: string | null;
    startDate: Date | null;
    startTime: Date | null;
    endDate: Date | null;
    endTime: Date | null;
    floorAltitude: number | null;
    ceilingAltitude: number | null;
    areDetailsRequired: boolean | null;
    details: string | null;
}

export interface TaskCreatorPilotFormValues {
    data: TaskCreatorFormValues;
    area: {
        centerPointLatitude: number;
        centerPointLongitude: number;
        radius: number;
    };
}

export interface TaskStatusChange {
    status: TaskStatus;
    task: Task;
}

export interface TaskTeamChange {
    task: Task;
    team: Team;
}

export enum TaskErrorType {
    Unknown = "Unknown",
}

export interface TaskError {
    type: TaskErrorType;
}
