import { OverlayModule } from "@angular/cdk/overlay";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterLink } from "@angular/router";
import { IncidentSharedDataState, IncidentSharedModule } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { LeafletMapModule } from "@dtm-frontend/shared/map/leaflet";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { DmsCoordinatesModule } from "@dtm-frontend/shared/ui/dms-coordinates";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { WebsocketService } from "@dtm-frontend/shared/websocket";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ToastContainerModule } from "ngx-toastr";
import { SharedModule } from "../shared/shared.module";
import { ActiveIncidentComponent } from "./components/active-incident/active-incident.component";
import { AreaAssignedTasksListComponent } from "./components/area-side-panel/area-assigned-tasks-list/area-assigned-tasks-list.component";
import { AreaListComponent } from "./components/area-side-panel/area-list/area-list.component";
import { AreaNameChangeDialogComponent } from "./components/area-side-panel/area-name-change-dialog/area-name-change-dialog.component";
import { AreaSidePanelComponent } from "./components/area-side-panel/area-side-panel.component";
import { AreaTaskSelectComponent } from "./components/area-side-panel/area-task-select/area-task-select.component";
import { AreaMenuComponent } from "./components/areas/area-menu/area-menu.component";
import { DrawAreasDirective } from "./components/areas/draw-areas.directive";
import { EditAreasDirective } from "./components/areas/edit-areas.directive";
import { HandDrawDirective } from "./components/areas/hand-draw.directive";
import { HandDrawingMenuComponent } from "./components/areas/hand-drawing-menu/hand-drawing-menu.component";
import { RulerDirective } from "./components/areas/ruler.directive";
import { ChatSidePanelComponent } from "./components/chat-side-panel/chat-side-panel.component";
import { IncidentSidePanelComponent } from "./components/incident-side-panel/incident-side-panel.component";
import { IncidentToolbarComponent } from "./components/incident-toolbar/incident-toolbar.component";
import { MapFiltersPanelComponent } from "./components/map-filters/map-filters-panel.component";
import { PlannedIncidentComponent } from "./components/planned-incident/planned-incident.component";
import { AcceptPilotTaskDialogComponent } from "./components/task-side-panel/accept-pilot-task-dialog/accept-pilot-task-dialog.component";
import { RejectPilotTaskDialogComponent } from "./components/task-side-panel/reject-pilot-task-dialog/reject-pilot-task-dialog.component";
import { TaskCreatorComponent } from "./components/task-side-panel/task-creator/task-creator.component";
import { TaskListComponent } from "./components/task-side-panel/task-list/task-list.component";
import { TaskSidePanelComponent } from "./components/task-side-panel/task-side-panel.component";
import { TeamCreatorSidePanelComponent } from "./components/team-creator-side-panel/team-creator-side-panel.component";
import { TeamPanelComponent } from "./components/team-panel/team-panel.component";
import { IncidentApiService } from "./services/incident-api.service";
import { IncidentState } from "./state/incident.state";

@NgModule({
    imports: [
        CommonModule,
        DmsCoordinatesModule,
        LeafletMapModule,
        LetModule,
        IncidentSharedModule,
        MatDatepickerModule,
        MatDialogModule,
        MatInputModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        MatTooltipModule,
        NgxsModule.forFeature([IncidentState, IncidentSharedDataState]),
        PushModule,
        ReactiveFormsModule,
        RouterLink,
        SharedI18nModule,
        SharedModule,
        SharedUiModule,
        ToastContainerModule,
        OverlayModule,
    ],
    declarations: [
        AcceptPilotTaskDialogComponent,
        ActiveIncidentComponent,
        AreaAssignedTasksListComponent,
        AreaListComponent,
        AreaMenuComponent,
        AreaNameChangeDialogComponent,
        AreaSidePanelComponent,
        AreaTaskSelectComponent,
        ChatSidePanelComponent,
        DrawAreasDirective,
        EditAreasDirective,
        HandDrawDirective,
        HandDrawingMenuComponent,
        IncidentSidePanelComponent,
        IncidentToolbarComponent,
        MapFiltersPanelComponent,
        PlannedIncidentComponent,
        RejectPilotTaskDialogComponent,
        RulerDirective,
        TaskCreatorComponent,
        TaskListComponent,
        TaskSidePanelComponent,
        TeamCreatorSidePanelComponent,
        TeamPanelComponent,
    ],
    providers: [
        IncidentApiService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "sahClientLibIncident",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [
        AreaAssignedTasksListComponent,
        AreaListComponent,
        AreaTaskSelectComponent,
        DrawAreasDirective,
        EditAreasDirective,
        IncidentToolbarComponent,
        TaskCreatorComponent,
        TaskListComponent,
    ],
})
export class IncidentModule {
    public static forTest(): ModuleWithProviders<IncidentModule> {
        return {
            ngModule: IncidentModule,
            providers: [
                {
                    provide: IncidentApiService,
                    useValue: null,
                },
                {
                    provide: WebsocketService,
                    useValue: null,
                },
            ],
        };
    }
}
