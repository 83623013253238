<ng-container *ngrxLet="assignedTasks$ as assignedTasks">
    <div class="header">
        <span class="title">{{
            "sahClientLibIncident.areaSidePanel.tasksLabel" | transloco : { numberOfTasks: assignedTasks.length }
        }}</span>
        <button type="button" class="button-secondary" (click)="newTaskAssign.emit()">
            <dtm-ui-icon name="add"></dtm-ui-icon>
            {{ "sahClientLibIncident.areaSidePanel.assignTask" | transloco }}
        </button>
    </div>
    <sah-client-lib-task-list
        [tasks]="assignedTasks"
        [teams]="teams$ | ngrxPush"
        [taskStatusPool]="taskStatusPool$ | ngrxPush"
        (taskRemove)="assignedTaskRemove.emit($event)"
        (taskAreaSelect)="taskAreaSelect.emit($event)"
        (teamAttach)="teamAttach.emit($event)"
        (teamDetach)="teamDetach.emit($event)"
        (statusChange)="statusChange.emit($event)"
        (pilotTaskAccept)="pilotTaskAccept.emit($event)"
        (pilotTaskReject)="pilotTaskReject.emit($event)"
    ></sah-client-lib-task-list>
</ng-container>
