import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardRoutingModule } from "@dtm-frontend/search-and-help-client-lib/dashboard";
import { IncidentRoutingModule } from "@dtm-frontend/search-and-help-client-lib/incident";
import { IncidentCreatorRoutingModule } from "@dtm-frontend/search-and-help-client-lib/incident-creator";
import { SahAbsolutePath, SahPath, SahRole } from "@dtm-frontend/search-and-help-client-lib/shared";
import { LoginPageComponent, loginPageGuard } from "@dtm-frontend/search-and-help-shared-lib/shared";
import { DTM_AUTH_GUARD_REQUIRED_ROLES, DtmAuthGuard, NOT_AUTHORIZED_ROUTE } from "@dtm-frontend/shared/auth";
import { NotAuthorizedComponent } from "@dtm-frontend/shared/ui";

const routes: Routes = [
    {
        path: NOT_AUTHORIZED_ROUTE,
        component: NotAuthorizedComponent,
        canActivate: [DtmAuthGuard],
    },
    {
        path: SahPath.Login,
        component: LoginPageComponent,
        canActivate: [loginPageGuard(SahAbsolutePath.Dashboard)],
    },
    {
        path: "**",
        redirectTo: SahPath.Dashboard,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), DashboardRoutingModule, IncidentCreatorRoutingModule, IncidentRoutingModule],
    providers: [
        {
            provide: DTM_AUTH_GUARD_REQUIRED_ROLES,
            useValue: [SahRole.KOL],
        },
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
