import { Injectable } from "@angular/core";
import {
    CustomLeafletMapEvent,
    IncidentMapLayer,
    IncidentMapLayersService,
    IncidentSharedDataState,
    MapArea,
    MapFilter,
    Task,
    TaskMarker,
    TaskMarkerService,
    TaskStatus,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { ArrayUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Map } from "leaflet";
import { pairwise, startWith, withLatestFrom } from "rxjs";
import { IncidentActions } from "../state/incident.actions";
import { IncidentState } from "../state/incident.state";

type FilteredTaskStatus = TaskStatus.Completed | TaskStatus.Planned;

@UntilDestroy()
@Injectable()
export class IncidentMapFiltersService {
    private readonly mapFiltersState$ = this.store.select(IncidentState.mapFiltersState);

    private map: Map | undefined;
    private hiddenTaskMarkers: TaskMarker[] = [];
    private readonly hiddenAreasContainingOnlyTasksWithStatus: { [key in FilteredTaskStatus]: MapArea[] } = {
        [TaskStatus.Completed]: [],
        [TaskStatus.Planned]: [],
    };

    constructor(
        private readonly mapLayers: IncidentMapLayersService,
        private readonly store: Store,
        private readonly taskMarkerService: TaskMarkerService
    ) {}

    public initMap(mapProvider: Map): void {
        this.map = mapProvider;

        this.watchMapFiltersUpdates();
        this.watchAreaTaskMarkersUpdates();
    }

    public isAreaHidden(areaId: string): boolean {
        return this.store.selectSnapshot(IncidentState.mapFiltersState)[MapFilter.Areas] || this.isAreaFilteredOutByTaskStatus(areaId);
    }

    public reloadFilters(): void {
        this.hiddenTaskMarkers = [];
        this.hiddenAreasContainingOnlyTasksWithStatus.COMPLETED = [];
        this.hiddenAreasContainingOnlyTasksWithStatus.PLANNED = [];

        const mapFilterState = this.store.selectSnapshot(IncidentState.mapFiltersState);

        this.toggleAllActionMarkersFilter(mapFilterState[MapFilter.ActionMarkers]);
        this.toggleAllAreasFilter(mapFilterState[MapFilter.Areas]);
        this.toggleAreasWithTasksByStatusFilter(mapFilterState[MapFilter.AreasWithCompletedTasksOnly], TaskStatus.Completed);
        this.toggleAreasWithTasksByStatusFilter(mapFilterState[MapFilter.AreasWithPlannedTasksOnly], TaskStatus.Planned);
        this.toggleAllHandDrawingsAndNotesFilter(mapFilterState[MapFilter.HandDrawingsAndNotes]);
    }

    private toggleAllActionMarkersFilter(isFilterActive: boolean): void {
        const actionMarkersLayer = this.mapLayers.getMapLayer(IncidentMapLayer.ActionMarkers);

        if (isFilterActive) {
            this.map?.fire(CustomLeafletMapEvent.ClearActionMarkerSelection);
            this.map?.removeLayer(actionMarkersLayer);
        } else {
            this.map?.addLayer(actionMarkersLayer);
        }
    }

    private toggleAllAreasFilter(isFilterActive: boolean): void {
        this.store.dispatch(new IncidentActions.SelectArea(undefined));
        const areasLayer = this.mapLayers.getMapLayer(IncidentMapLayer.Areas);

        const affectedAreas = (areasLayer.getLayers() as MapArea[]).filter(
            (mapArea) => !this.isAreaFilteredOutByTaskStatus(mapArea.data?.id)
        );

        this.toggleAreasWithTaskMarkers(affectedAreas, isFilterActive);
    }

    private toggleAllHandDrawingsAndNotesFilter(isFilterActive: boolean): void {
        const handDrawingsLayer = this.mapLayers.getMapLayer(IncidentMapLayer.HandDrawings);

        if (isFilterActive) {
            this.map?.removeLayer(handDrawingsLayer);
            this.map?.fire(CustomLeafletMapEvent.ClearHandDrawingSelection);
        } else {
            this.map?.addLayer(handDrawingsLayer);
        }
    }

    private toggleAreasWithTasksByStatusFilter(isFilterActive: boolean, status: FilteredTaskStatus): void {
        this.store.dispatch(new IncidentActions.SelectArea(undefined));

        const areasLayer = this.mapLayers.getMapLayer(IncidentMapLayer.Areas);
        const areas = areasLayer.getLayers() as MapArea[];

        if (isFilterActive) {
            const affectedAreas = areas.filter((area) => this.areAreaTasksWithOnlyGivenStatus(area, status));
            this.toggleAreasWithTaskMarkers(affectedAreas, isFilterActive);
            this.updateHiddenAreas(status, affectedAreas);

            return;
        }

        if (this.store.selectSnapshot(IncidentState.mapFiltersState).Areas) {
            this.updateHiddenAreas(status, []);

            return;
        }

        const existingHiddenAreasToUpdate = this.hiddenAreasContainingOnlyTasksWithStatus[status].filter((hiddenArea) =>
            areas.some((area) => area.data?.id === hiddenArea.data?.id)
        );
        this.toggleAreasWithTaskMarkers(existingHiddenAreasToUpdate, isFilterActive);
        this.updateHiddenAreas(status, []);
    }

    private updateHiddenAreas(status: FilteredTaskStatus, areas: MapArea[]): void {
        this.hiddenAreasContainingOnlyTasksWithStatus[status] = areas;
    }

    private toggleAreasWithTaskMarkers(areas: MapArea[], shouldBeHidden: boolean): void {
        areas.forEach((area) => {
            this.toggleMapArea(area, shouldBeHidden);
            this.toggleAreaTaskMarkers(area, shouldBeHidden);
        });
    }

    private toggleMapArea(mapArea: MapArea, isHidden: boolean): void {
        const element = mapArea.getElement();
        if (!element) {
            return;
        }

        element.setAttribute("style", isHidden ? "display: none" : "");
    }

    private toggleAreaTaskMarkers(area: MapArea, shouldBeHidden: boolean): void {
        const taskMarkersLayer = this.mapLayers.getMapLayer(IncidentMapLayer.TaskMarkers);
        const taskMarkers = taskMarkersLayer.getLayers() as TaskMarker[];

        if (shouldBeHidden) {
            taskMarkers
                .filter((taskMarker) => taskMarker.data?.areaId === area.data?.id)
                .forEach((taskMarker) => {
                    taskMarkersLayer.removeLayer(taskMarker);
                    this.hiddenTaskMarkers.push(taskMarker);
                });

            return;
        }

        const [affectedTaskMarkers, updatedHiddenTaskMarkers] = ArrayUtils.partition(
            this.hiddenTaskMarkers,
            (taskMarker) => taskMarker.data?.areaId === area.data?.id
        );

        this.hiddenTaskMarkers = updatedHiddenTaskMarkers;
        affectedTaskMarkers
            .filter((taskMarker) => area.data?.taskIds?.includes(taskMarker.data?.taskId ?? ""))
            .forEach((taskMarker) => taskMarkersLayer.addLayer(taskMarker));
    }

    private updateAreaVisibility(area: MapArea, status: FilteredTaskStatus) {
        this.store.dispatch(new IncidentActions.SelectArea(undefined));
        const shouldBeHidden = this.areAreaTasksWithOnlyGivenStatus(area, status);
        const matchingHiddenArea = this.hiddenAreasContainingOnlyTasksWithStatus[status].find(
            (hiddenArea) => hiddenArea.data?.id === area.data?.id
        );

        if (matchingHiddenArea && !shouldBeHidden) {
            this.toggleAreasWithTaskMarkers([area], shouldBeHidden);
            this.hiddenAreasContainingOnlyTasksWithStatus[status] = this.hiddenAreasContainingOnlyTasksWithStatus[status].filter(
                (hiddenArea) => hiddenArea.data?.id !== area.data?.id
            );
        } else if (!matchingHiddenArea && shouldBeHidden) {
            this.toggleAreasWithTaskMarkers([area], shouldBeHidden);
            this.hiddenAreasContainingOnlyTasksWithStatus[status].push(area);
        }
    }

    private isAreaFilteredOutByTaskStatus(areaId: string | undefined): boolean {
        return Object.values(this.hiddenAreasContainingOnlyTasksWithStatus).some((areas) => areas.some((area) => area.data?.id === areaId));
    }

    private areAreaTasksWithOnlyGivenStatus(area: MapArea, status: TaskStatus): boolean {
        const areaTasks = this.getAreaTasks(area);

        return !!areaTasks.length && areaTasks.every((task) => task.status === status);
    }

    private getAreaTasks(area: MapArea): Task[] {
        return this.store.selectSnapshot(IncidentSharedDataState.tasks).filter((task) => task.areaId === area.data?.id);
    }

    private getMapArea(areaId: string | undefined): MapArea | undefined {
        return (this.mapLayers.getMapLayer(IncidentMapLayer.Areas).getLayers() as MapArea[]).find((mapArea) => mapArea.data?.id === areaId);
    }

    private watchMapFiltersUpdates(): void {
        this.mapFiltersState$
            .pipe(startWith(this.store.selectSnapshot(IncidentState.mapFiltersState)), pairwise(), untilDestroyed(this))
            .subscribe(([previous, current]) => {
                if (previous[MapFilter.ActionMarkers] !== current[MapFilter.ActionMarkers]) {
                    this.toggleAllActionMarkersFilter(current[MapFilter.ActionMarkers]);
                }

                if (previous[MapFilter.Areas] !== current[MapFilter.Areas]) {
                    this.toggleAllAreasFilter(current[MapFilter.Areas]);
                }

                if (previous[MapFilter.AreasWithCompletedTasksOnly] !== current[MapFilter.AreasWithCompletedTasksOnly]) {
                    this.toggleAreasWithTasksByStatusFilter(current[MapFilter.AreasWithCompletedTasksOnly], TaskStatus.Completed);
                }

                if (previous[MapFilter.AreasWithPlannedTasksOnly] !== current[MapFilter.AreasWithPlannedTasksOnly]) {
                    this.toggleAreasWithTasksByStatusFilter(current[MapFilter.AreasWithPlannedTasksOnly], TaskStatus.Planned);
                }

                if (previous[MapFilter.HandDrawingsAndNotes] !== current[MapFilter.HandDrawingsAndNotes]) {
                    this.toggleAllHandDrawingsAndNotesFilter(current[MapFilter.HandDrawingsAndNotes]);
                }
            });
    }

    private watchAreaTaskMarkersUpdates(): void {
        this.taskMarkerService.taskMarkerUpdate$
            .pipe(withLatestFrom(this.store.select(IncidentState.mapFiltersState)), untilDestroyed(this))
            .subscribe(([area, mapFiltersState]) => {
                const mapArea = this.getMapArea(area?.data?.id);
                if (!mapArea || mapFiltersState.Areas) {
                    return;
                }

                if (mapFiltersState.AreasWithCompletedTasksOnly) {
                    this.updateAreaVisibility(mapArea, TaskStatus.Completed);
                }

                if (mapFiltersState.AreasWithPlannedTasksOnly) {
                    this.updateAreaVisibility(mapArea, TaskStatus.Planned);
                }
            });
    }
}
