import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Task } from "../../../models/task.models";

interface TaskPreviewComponentState {
    task: Task | undefined;
}

@Component({
    selector: "sah-shared-lib-task-preview[task]",
    templateUrl: "task-preview.component.html",
    styleUrls: ["task-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TaskPreviewComponent {
    @Input() public set task(value: Task | undefined) {
        this.localStore.patchState({ task: value });
    }

    @Output() protected readonly previewClose = new EventEmitter();

    protected readonly task$ = this.localStore.selectByKey("task");

    constructor(private readonly localStore: LocalComponentStore<TaskPreviewComponentState>) {
        this.localStore.setState({ task: undefined });
    }
}
