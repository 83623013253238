import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ActionMarkerData } from "../../../models/incident-map.models";

export enum ViewType {
    Create = "Create",
    Edit = "Edit",
    Preview = "Preview",
}

interface ActionMarkerContextMenuComponentState {
    viewType: ViewType | undefined;
    actionMarkerData: Partial<ActionMarkerData> | undefined;
    isProcessing: boolean;
    isIconMenuOpen: boolean;
}

@Component({
    templateUrl: "action-marker-context-menu.component.html",
    styleUrls: ["action-marker-context-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ActionMarkerContextMenuComponent {
    @Input() public set viewType(value: ViewType | undefined) {
        this.localStore.patchState({ viewType: value });
    }

    @Input() public set actionMarkerData(value: Partial<ActionMarkerData> | undefined) {
        this.localStore.patchState({
            actionMarkerData: value,
            isIconMenuOpen: false,
        });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Output() public readonly add = new EventEmitter<Partial<ActionMarkerData>>();
    @Output() public readonly addCancel = new EventEmitter<void>();
    @Output() public readonly update = new EventEmitter<Partial<ActionMarkerData>>();
    @Output() public readonly remove = new EventEmitter<void>();
    @Output() public readonly dragToggle = new EventEmitter<boolean>();
    @Output() public readonly viewChange = new EventEmitter<ViewType>();

    protected readonly ViewType = ViewType;

    protected readonly viewType$ = this.localStore.selectByKey("viewType");
    protected readonly actionMarkerData$ = this.localStore.selectByKey("actionMarkerData");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isIconMenuOpen$ = this.localStore.selectByKey("isIconMenuOpen");

    constructor(private readonly localStore: LocalComponentStore<ActionMarkerContextMenuComponentState>) {
        this.localStore.setState({
            viewType: ViewType.Preview,
            actionMarkerData: undefined,
            isProcessing: false,
            isIconMenuOpen: false,
        });
    }

    public get selectedMarkerId() {
        return this.localStore.selectSnapshotByKey("actionMarkerData")?.id;
    }

    protected changeView(viewType: ViewType): void {
        this.viewChange.emit(viewType);
    }

    protected addMarker(data: Partial<ActionMarkerData>): void {
        const currentData = this.localStore.selectSnapshotByKey("actionMarkerData");

        this.add.emit({ ...currentData, ...data });
    }

    protected updateMarker(data: Partial<ActionMarkerData>): void {
        const currentData = this.localStore.selectSnapshotByKey("actionMarkerData");

        this.update.emit({ ...currentData, ...data });
    }

    protected toggleIconMenu(): void {
        this.localStore.patchState((state) => ({ isIconMenuOpen: !state.isIconMenuOpen }));
    }
}
