import { ChangeDetectionStrategy, Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { first, mergeMap, of, toArray } from "rxjs";
import { map } from "rxjs/operators";
import { BasicUavEquipment, Operator, Pilot, TeamCreatorFormValues } from "../../../../shared/models/team.models";
import { IncidentCreatorActions } from "../../../state/incident-creator.actions";
import { IncidentCreatorState } from "../../../state/incident-creator.state";

@UntilDestroy()
@Component({
    selector: "sah-client-lib-incident-creator-teams",
    templateUrl: "incident-creator-teams.component.html",
    styleUrls: ["../incident-creator-side-panel.scss", "incident-creator-teams.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class IncidentCreatorTeamsComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable: InvalidFormScrollableDirective | undefined;

    @Output() protected readonly stepSkip = new EventEmitter<void>();
    @Output() protected readonly formSubmit = new EventEmitter<TeamCreatorFormValues[]>();

    protected readonly defaultAccessoriesOptions$ = of(...Object.values(BasicUavEquipment)).pipe(
        mergeMap((accessory) =>
            this.translationHelper
                .waitForTranslation("sahClientLibIncidentCreator.incidentCreator.teamsPanel.uavAccessoriesValueLabel", {
                    value: accessory,
                })
                .pipe(
                    first(),
                    map((label) => ({ value: accessory, label, isDisabled: false }))
                )
        ),
        toArray()
    );

    protected readonly teamsControl = new FormControl<Partial<TeamCreatorFormValues>[]>([{}]);

    protected readonly operators$ = this.store.select(IncidentCreatorState.operators);
    protected readonly operatorPilots$ = this.store.select(IncidentCreatorState.operatorPilots);
    protected readonly isProcessing$ = this.store.select(IncidentCreatorState.isProcessing);

    constructor(private readonly store: Store, private readonly translationHelper: TranslationHelperService) {}

    protected submit(): void {
        this.teamsControl.updateValueAndValidity();
        if (this.teamsControl.invalid) {
            this.teamsControl.markAllAsTouched();
            this.invalidFormScrollable?.scrollToFirstInvalidField();

            return;
        }

        this.formSubmit.emit(this.teamsControl.getRawValue() as TeamCreatorFormValues[]);
    }

    protected onOperatorChange(operator: Operator): void {
        this.store.dispatch(new IncidentCreatorActions.GetPilots(operator.id));
    }

    protected onPilotChange(change: { operator: Operator; pilot: Pilot }): void {
        this.store.dispatch(new IncidentCreatorActions.GetPilotsUavs(change.operator.id, change.pilot.pilotId));
    }
}
