import { HttpStatusCode } from "@angular/common/http";
import { AreaGeometryEntity, IncidentCategory, IncidentStatus, MapArea } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { ChipType } from "@dtm-frontend/shared/ui";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { Circle, GeoJSON, Polygon } from "leaflet";
import { Pilot, PilotsUav } from "../../shared/models/team.models";
import {
    Coordinator,
    CoordinatorRole,
    IncidentCreatorError,
    IncidentCreatorErrorType,
    IncidentFormValues,
    Restriction,
    Unit,
} from "../models/incident-creator.models";

interface ErrorResponseBody {
    status: HttpStatusCode;
    error: {
        generalMessage: string;
        args: Record<string, unknown>;
    };
}

type IncidentAreaGeometryEntity = Partial<{ id: string; version: number }> & AreaGeometryEntity;

interface IncidentAreaEntity {
    area: IncidentAreaGeometryEntity;
    restriction?: {
        id: string;
        name: string;
    };
}

interface CreateOrUpdateIncidentRequestPayload {
    name: string;
    categories: IncidentCategory[];
    incidentArea: Partial<IncidentAreaEntity>;
    leadInstitution: {
        id: string;
        name: string;
        unit: string;
    };
    deputyAirOperationsCoordinatorsIds: string[];
    flightsDirectorsIds: string[];
    pilotTaskApprovalRequired: boolean;
    planned: boolean;
}

export interface CreateOrUpdateIncidentResponseBody {
    id: string;
    name: string;
    version: number;
}

export interface GetIncidentResponseBody {
    incident: {
        id: string;
        name: string;
        categories: IncidentCategory[];
        leadInstitution: { id: string; name: string; unit: string };
        status: IncidentStatus;
        areaId: string;
        createdAt: Date;
        airOperationsCoordinatorId: string;
        deputyAirOperationsCoordinatorsIds: string[];
        flightsDirectorsIds: string[];
        pilotTaskApprovalRequired: boolean;
        version: number;
    };
    incidentArea: IncidentAreaEntity;
    airOperationsCoordinator: Coordinator;
    deputyAirOperationsCoordinators: Coordinator[];
    flightsDirectors: Coordinator[];
}

export type GetRestrictionsResponseBody = Array<{ id: string; title: string; startAt: string; endAt: string; designator: string }>;

export interface GetRestrictionAreaResponseBody {
    geometry: GeoJSON.Polygon;
    center: GeoJSON.Point;
    radius: number;
}

export type GetOperatorsResponseBody = Array<{ id: string; name: string }>;
export type GetPilotsResponseBody = Array<{ userId: string; pilotId: string; firstName: string; lastName: string; fullName: string }>;
export type GetPilotUavsResponseBody = Array<{
    name: string;
    uavSetups: Array<{
        equipment: {
            items: Array<{
                id: string;
                name: string;
                type: string;
            }>;
        };
    }>;
}>;
export type GetCoordinatorsResponseBody = Array<{
    id: string;
    firstName: string;
    lastName: string;
    airOperationsCoordinator: boolean;
    flightsDirector: boolean;
}>;

function convertMapAreaToIncidentAreaGeometryEntity(incidentArea: MapArea): IncidentAreaGeometryEntity {
    const areaData = {
        id: incidentArea.data?.id,
        version: incidentArea.data?.version,
    };

    if (incidentArea instanceof Circle) {
        return {
            center: incidentArea.toGeoJSON().geometry as GeoJSON.Point,
            radius: incidentArea.getRadius(),
            ...areaData,
        };
    }

    return { geometry: incidentArea.toGeoJSON().geometry as GeoJSON.Polygon, ...areaData };
}

export function convertIncidentAreaGeometryEntityToMapArea(incidentAreaGeometryEntity: IncidentAreaGeometryEntity): MapArea {
    let mapArea: MapArea;

    if ("radius" in incidentAreaGeometryEntity) {
        const centerCoordinates = incidentAreaGeometryEntity.center?.coordinates;
        mapArea = new Circle({ lng: centerCoordinates[0], lat: centerCoordinates[1] }, incidentAreaGeometryEntity.radius);
    } else {
        mapArea = new Polygon(
            incidentAreaGeometryEntity.geometry.coordinates.map((position) => position.map(([lng, lat]) => ({ lng, lat })))
        );
    }

    mapArea.data = {
        id: incidentAreaGeometryEntity.id,
        version: incidentAreaGeometryEntity.version,
    };

    return mapArea;
}

export function convertUnitsMapToUnitList(unitsMap: Record<string, string>): Unit[] {
    return Object.keys(unitsMap).map((key) => ({ code: key, name: unitsMap[key] }));
}

export function convertIncidentFormValuesToCreateOrUpdateIncidentRequestPayload(
    formValues: IncidentFormValues
): CreateOrUpdateIncidentRequestPayload {
    const leadInstitution = {
        id: formValues.leadInstitution.id,
        name: formValues.leadInstitution.name,
        unit: formValues.unit.name,
    };

    let incidentArea = {};
    if (formValues.customArea) {
        incidentArea = { area: convertMapAreaToIncidentAreaGeometryEntity(formValues.customArea) };
    }

    if (!formValues.customArea && formValues.restriction?.area) {
        incidentArea = {
            restriction: { id: formValues.restriction.id, name: formValues.restriction.name },
            area: convertMapAreaToIncidentAreaGeometryEntity(formValues.restriction.area),
        };
    }

    return {
        name: formValues.name,
        categories: formValues.categories,
        incidentArea,
        leadInstitution,
        deputyAirOperationsCoordinatorsIds: formValues.deputyAirOperationsCoordinators?.map((coordinator) => coordinator.id),
        flightsDirectorsIds: formValues.flightsCoordinators?.map((coordinator) => coordinator.id),
        pilotTaskApprovalRequired: formValues.isRequiredTaskReconciliation,
        planned: formValues.isPlanned,
    };
}

export function convertGetIncidentResponseBodyToIncidentFormValues({
    incident,
    incidentArea,
    airOperationsCoordinator,
    deputyAirOperationsCoordinators,
    flightsDirectors,
}: GetIncidentResponseBody): IncidentFormValues {
    const restriction = incidentArea.restriction;
    const isCustomArea = !restriction;
    const incidentMapArea = convertIncidentAreaGeometryEntityToMapArea(incidentArea.area);

    return {
        name: incident.name,
        categories: incident.categories,
        isPlanned: incident.status === IncidentStatus.Planned,
        isCustomArea,
        customArea: isCustomArea ? incidentMapArea : undefined,
        restriction: !isCustomArea
            ? {
                  area: incidentMapArea,
                  id: restriction.id,
                  name: restriction.name,
              }
            : undefined,
        leadInstitution: {
            id: incident.leadInstitution.id,
            name: incident.leadInstitution.name,
        },
        unit: { name: incident.leadInstitution.unit, code: "" },
        airOperationsCoordinator: airOperationsCoordinator,
        deputyAirOperationsCoordinators: deputyAirOperationsCoordinators,
        flightsCoordinators: flightsDirectors,
        isRequiredTaskReconciliation: incident.pilotTaskApprovalRequired,
    };
}

export function convertGetRestrictionResponseBodyToRestrictionList(response: GetRestrictionsResponseBody): Restriction[] {
    return response.map(({ id, title }) => ({
        id,
        name: title,
    }));
}

export function convertGetRestrictionAreaResponseBodyToMapArea({ radius, geometry, center }: GetRestrictionAreaResponseBody): MapArea {
    if (radius) {
        return new Circle({ lng: center.coordinates[0], lat: center.coordinates[1] }, radius);
    }

    return new Polygon(geometry.coordinates.map((position) => position.map(([lng, lat]) => ({ lng, lat }))));
}

export function convertErrorResponseBodyToIncidentCreatorError(errorResponseBody: ErrorResponseBody): IncidentCreatorError {
    switch (errorResponseBody.status) {
        case HttpStatusCode.Conflict:
            return {
                type: IncidentCreatorErrorType.Conflict,
                args: errorResponseBody.error.args,
                messageKey: errorResponseBody.error.generalMessage,
            };
        default:
            return { type: IncidentCreatorErrorType.Unknown, args: {}, messageKey: "" };
    }
}

export function convertGetPilotsResponseBodyToPilotsArray(response: GetPilotsResponseBody): Pilot[] {
    return response.map((pilot) => ({ ...pilot, uavs: [] }));
}

export function convertGetPilotsUavsResponseBodyToPilotUavsArray(response: GetPilotUavsResponseBody): PilotsUav[] {
    return response.map((pilotsUav) => ({
        name: pilotsUav.name,
        equipment: pilotsUav.uavSetups
            .flatMap((uavSetup) => uavSetup.equipment.items)
            .map((item) => ({ type: ChipType.Custom, value: item.name, label: item.name, isRemovable: true })),
    }));
}

export function convertGetCoordinatorsResponseBodyToCoordinatorsArray(response: GetCoordinatorsResponseBody): Coordinator[] {
    return response.map(({ id, firstName, lastName, airOperationsCoordinator, flightsDirector }) => ({
        id,
        firstName,
        lastName,
        roles: [
            airOperationsCoordinator && CoordinatorRole.AirOperationsCoordinator,
            flightsDirector && CoordinatorRole.FlightsDirector,
        ].filter(FunctionUtils.isTruthy),
    }));
}
