import { Injectable } from "@angular/core";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { EMPTY, catchError, finalize, tap } from "rxjs";
import { Operator, OperatorPilots, Pilot } from "../../shared/models/team.models";
import { Coordinator, IncidentCreatorError, IncidentFormValues, Institution, Restriction, Unit } from "../models/incident-creator.models";
import { IncidentCreatorApiService } from "../services/incident-creator-api.service";
import { IncidentCreatorActions } from "./incident-creator.actions";

interface IncidentCreatorStateModel {
    isProcessing: boolean;
    createIncidentError: IncidentCreatorError | undefined;
    createdIncidentId: string | undefined;
    editedIncidentData: IncidentFormValues | undefined;
    editedIncidentDataError: IncidentCreatorError | undefined;
    editIncidentError: IncidentCreatorError | undefined;
    airOperationsCoordinators: Coordinator[] | undefined;
    airOperationsCoordinatorsError: IncidentCreatorError | undefined;
    flightsCoordinators: Coordinator[] | undefined;
    flightsCoordinatorsError: IncidentCreatorError | undefined;
    institutions: Institution[] | undefined;
    institutionsError: IncidentCreatorError | undefined;
    units: Unit[] | undefined;
    unitsError: IncidentCreatorError | undefined;
    restrictions: Restriction[] | undefined;
    getRestrictionsError: IncidentCreatorError | undefined;
    getRestrictionAreaError: IncidentCreatorError | undefined;
    createTeamsError: IncidentCreatorError | undefined;
    operators: Operator[] | undefined;
    operatorPilots: OperatorPilots | undefined;
}

const DEFAULT_STATE: IncidentCreatorStateModel = {
    isProcessing: false,
    createIncidentError: undefined,
    createdIncidentId: undefined,
    editedIncidentData: undefined,
    editedIncidentDataError: undefined,
    editIncidentError: undefined,
    airOperationsCoordinators: undefined,
    airOperationsCoordinatorsError: undefined,
    flightsCoordinators: undefined,
    flightsCoordinatorsError: undefined,
    institutions: undefined,
    institutionsError: undefined,
    units: undefined,
    unitsError: undefined,
    restrictions: undefined,
    getRestrictionsError: undefined,
    getRestrictionAreaError: undefined,
    createTeamsError: undefined,
    operators: undefined,
    operatorPilots: undefined,
};

@State<IncidentCreatorStateModel>({
    name: "incidentCreator",
    defaults: DEFAULT_STATE,
})
@Injectable()
export class IncidentCreatorState {
    @Selector()
    public static isProcessing(state: IncidentCreatorStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static airOperationsCoordinators(state: IncidentCreatorStateModel): Coordinator[] | undefined {
        return state.airOperationsCoordinators;
    }

    @Selector()
    public static createIncidentError(state: IncidentCreatorStateModel): IncidentCreatorError | undefined {
        return state.createIncidentError;
    }

    @Selector()
    public static createdIncidentId(state: IncidentCreatorStateModel): string | undefined {
        return state.createdIncidentId;
    }

    @Selector()
    public static editedIncidentData(state: IncidentCreatorStateModel): IncidentFormValues | undefined {
        return state.editedIncidentData;
    }

    @Selector()
    public static editedIncidentDataError(state: IncidentCreatorStateModel): IncidentCreatorError | undefined {
        return state.editedIncidentDataError;
    }

    @Selector()
    public static editIncidentError(state: IncidentCreatorStateModel): IncidentCreatorError | undefined {
        return state.editIncidentError;
    }

    @Selector()
    public static flightsCoordinators(state: IncidentCreatorStateModel): Coordinator[] | undefined {
        return state.flightsCoordinators;
    }

    @Selector()
    public static institutions(state: IncidentCreatorStateModel): Institution[] | undefined {
        return state.institutions;
    }

    @Selector()
    public static restrictions(state: IncidentCreatorStateModel): Restriction[] | undefined {
        return state.restrictions;
    }

    @Selector()
    public static units(state: IncidentCreatorStateModel): Unit[] | undefined {
        return state.units;
    }

    @Selector()
    public static createTeamsError(state: IncidentCreatorStateModel): IncidentCreatorError | undefined {
        return state.createTeamsError;
    }

    @Selector()
    public static getRestrictionsError(state: IncidentCreatorStateModel): IncidentCreatorError | undefined {
        return state.getRestrictionsError;
    }

    @Selector()
    public static getRestrictionAreaError(state: IncidentCreatorStateModel): IncidentCreatorError | undefined {
        return state.getRestrictionAreaError;
    }

    @Selector()
    public static operators(state: IncidentCreatorStateModel): Operator[] | undefined {
        return state.operators;
    }

    @Selector()
    public static operatorPilots(state: IncidentCreatorStateModel): OperatorPilots | undefined {
        return state.operatorPilots;
    }

    constructor(private readonly incidentCreatorApiService: IncidentCreatorApiService) {}

    @Action(IncidentCreatorActions.ResetState)
    public resetState(context: StateContext<IncidentCreatorStateModel>) {
        context.patchState(DEFAULT_STATE);
    }

    @Action(IncidentCreatorActions.CreateIncident)
    public createIncident(context: StateContext<IncidentCreatorStateModel>, action: IncidentCreatorActions.CreateIncident) {
        context.patchState({ createIncidentError: undefined, isProcessing: true });

        return this.incidentCreatorApiService.createIncident(action.incidentFormValues).pipe(
            tap((incident) => context.patchState({ createdIncidentId: incident.id })),
            catchError((error) => {
                context.patchState({ createIncidentError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentCreatorActions.UpdateIncident)
    public updateIncident(context: StateContext<IncidentCreatorStateModel>, action: IncidentCreatorActions.UpdateIncident) {
        context.patchState({ editIncidentError: undefined, isProcessing: true });

        return this.incidentCreatorApiService.updateIncident(action.incidentId, action.incidentFormValues).pipe(
            tap(() => context.patchState({ editedIncidentData: undefined })),
            catchError((error) => {
                context.patchState({ editIncidentError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentCreatorActions.GetIncident)
    public getIncident(context: StateContext<IncidentCreatorStateModel>, action: IncidentCreatorActions.GetIncident) {
        context.patchState({ isProcessing: true, editedIncidentData: undefined, editedIncidentDataError: undefined });

        return this.incidentCreatorApiService.getIncident(action.incidentId).pipe(
            tap((incident) => context.patchState({ editedIncidentData: incident })),
            catchError((error) => {
                context.patchState({ editedIncidentDataError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentCreatorActions.CreateTeams)
    public createTeams(context: StateContext<IncidentCreatorStateModel>, { incidentId, teams }: IncidentCreatorActions.CreateTeams) {
        context.patchState({ isProcessing: true, createTeamsError: undefined });

        return this.incidentCreatorApiService.createTeams(incidentId, teams).pipe(
            catchError((error) => {
                context.patchState({ createTeamsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentCreatorActions.GetAirOperationsCoordinators)
    public getAirOperationsCoordinators(context: StateContext<IncidentCreatorStateModel>) {
        context.patchState({ airOperationsCoordinatorsError: undefined, isProcessing: true });

        return this.incidentCreatorApiService.getAirOperationsCoordinators().pipe(
            tap((airOperationsCoordinators) => context.patchState({ airOperationsCoordinators })),
            catchError((error) => {
                context.patchState({ airOperationsCoordinatorsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentCreatorActions.GetFlightsCoordinators)
    public getFlightsCoordinators(context: StateContext<IncidentCreatorStateModel>) {
        context.patchState({ flightsCoordinatorsError: undefined, isProcessing: true });

        return this.incidentCreatorApiService.getFlightsCoordinators().pipe(
            tap((flightsCoordinators) => context.patchState({ flightsCoordinators })),
            catchError((error) => {
                context.patchState({ flightsCoordinatorsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentCreatorActions.GetInstitutions)
    public getInstitutions(context: StateContext<IncidentCreatorStateModel>) {
        context.patchState({ institutionsError: undefined, isProcessing: true });

        return this.incidentCreatorApiService.getInstitutions().pipe(
            tap((institutions) => context.patchState({ institutions })),
            catchError((error) => {
                context.patchState({ institutionsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentCreatorActions.GetUnits)
    public getUnits(context: StateContext<IncidentCreatorStateModel>) {
        context.patchState({ unitsError: undefined, isProcessing: true });

        return this.incidentCreatorApiService.getUnits().pipe(
            tap((units) => context.patchState({ units })),
            catchError((error) => {
                context.patchState({ unitsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentCreatorActions.GetRestrictions)
    public getRestrictedAreas(context: StateContext<IncidentCreatorStateModel>) {
        context.patchState({ isProcessing: true, getRestrictionsError: undefined });

        return this.incidentCreatorApiService.getRestrictions().pipe(
            tap((restrictions) => context.patchState({ restrictions })),
            catchError((error) => {
                context.patchState({ getRestrictionsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentCreatorActions.GetRestrictionArea)
    public getRestrictionArea(
        context: StateContext<IncidentCreatorStateModel>,
        { restrictionId }: IncidentCreatorActions.GetRestrictionArea
    ) {
        context.patchState({ isProcessing: true, getRestrictionAreaError: undefined });
        const { restrictions } = context.getState();

        return this.incidentCreatorApiService.getRestrictionArea(restrictionId).pipe(
            tap((restrictionArea) =>
                context.patchState({
                    restrictions: restrictions?.map((restriction) =>
                        restriction.id === restrictionId ? { ...restriction, area: restrictionArea } : restriction
                    ),
                })
            ),
            catchError((error) => {
                context.patchState({ getRestrictionAreaError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentCreatorActions.GetOperators)
    public getOperators(context: StateContext<IncidentCreatorStateModel>) {
        context.patchState({ isProcessing: true });

        return this.incidentCreatorApiService.getOperators().pipe(
            tap((operators) => context.patchState({ operators })),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentCreatorActions.GetPilots)
    public getPilots(context: StateContext<IncidentCreatorStateModel>, { operatorId }: IncidentCreatorActions.GetPilots) {
        const { operatorPilots } = context.getState();
        if (operatorPilots && operatorPilots[operatorId]) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.incidentCreatorApiService.getPilots(operatorId).pipe(
            tap((pilots) => {
                const selectedOperatorPilots = pilots.reduce((result: { [key: string]: Pilot }, pilot) => {
                    result[pilot.pilotId] = pilot;

                    return result;
                }, {});

                context.patchState({
                    operatorPilots: { ...(operatorPilots ?? {}), [operatorId]: selectedOperatorPilots },
                });
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentCreatorActions.GetPilotsUavs)
    public getPilotsUavs(context: StateContext<IncidentCreatorStateModel>, { operatorId, pilotId }: IncidentCreatorActions.GetPilotsUavs) {
        const { operatorPilots } = context.getState();
        const selectedOperatorPilots = (operatorPilots ?? {})[operatorId];
        const selectedPilot = selectedOperatorPilots[pilotId];

        if (selectedPilot?.uavs?.length) {
            return;
        }

        context.patchState({ isProcessing: true });

        return this.incidentCreatorApiService.getPilotsUavs(pilotId).pipe(
            tap((uavs) =>
                context.patchState({
                    operatorPilots: {
                        ...(operatorPilots ?? {}),
                        [operatorId]: { ...(selectedOperatorPilots ?? {}), [pilotId]: { ...(selectedPilot ?? {}), uavs } },
                    },
                })
            ),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
