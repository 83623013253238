import { DEFAULT_MAP_AREA_HINT_LINE_STYLE, MapArea } from "@dtm-frontend/search-and-help-shared-lib/incident";
import "@geoman-io/leaflet-geoman-free";

/* eslint-disable */
export function disableEditAndDrag(layer: MapArea): void {
    layer.pm.disableLayerDrag();
    layer.pm.disable();
}

export function enableEditAndDrag(layer: MapArea): void {
    layer.pm.enableLayerDrag();
    // @ts-ignore
    layer.pm.enable({ hintlineStyle: DEFAULT_MAP_AREA_HINT_LINE_STYLE });
    // @ts-ignore
    layer.pm._centerMarker?.remove();
}

export function onDragStart(layer: MapArea): void {
    // @ts-ignore
    layer.pm._dragging = false;
    layer.pm.disable();
    // @ts-ignore
    layer.pm._dragging = true;
}

export function onDragEnd(layer: MapArea): void {
    layer.pm.enableLayerDrag();
    // @ts-ignore
    layer.pm.enable({ hintlineStyle: DEFAULT_MAP_AREA_HINT_LINE_STYLE });
    // @ts-ignore
    layer.pm._centerMarker?.remove();
}
