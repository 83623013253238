import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Directive, EventEmitter, Inject, Input, OnDestroy, Output, ViewContainerRef } from "@angular/core";
import { CustomLeafletMapEvent, MapArea, MapToolName, SahMapUtils } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "@dtm-frontend/shared/map/leaflet";
import { KeyboardEventKey, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Circle, LatLng, LeafletEvent, LeafletKeyboardEvent, Map, Polygon, Popup, PopupOptions } from "leaflet";
import { GeomanUtils } from "../../../geoman/index";
import { AreaMenuComponent } from "./area-menu/area-menu.component";

interface EditAreasDirectiveState {
    selectedToolName: MapToolName | undefined;
    isModeEnabled: boolean;
    isContextMenuEnabled: boolean;
    selectedArea: MapArea | undefined;
    areaPastState: MapArea | undefined;
}

const AREA_MENU_POPUP_OPTIONS: PopupOptions = {
    closeButton: false,
    closeOnClick: false,
    closeOnEscapeKey: false,
    autoClose: false,
    className: "area-menu",
    maxWidth: undefined,
};

@UntilDestroy()
@Directive({
    selector: "sah-client-lib-edit-areas[isModeEnabled][selectedArea]",
    providers: [LocalComponentStore],
})
export class EditAreasDirective implements OnDestroy {
    @Input() public set isModeEnabled(value: BooleanInput) {
        this.toggleEdit(coerceBooleanProperty(value));
    }
    @Input() public set selectedArea(value: MapArea | undefined) {
        this.selectArea(value);
    }
    @Input() public set isContextMenuEnabled(value: BooleanInput) {
        this.localStore.patchState({ isContextMenuEnabled: coerceBooleanProperty(value) });
    }

    @Output() protected readonly areaSelect = new EventEmitter<MapArea>();
    @Output() protected readonly areaRemove = new EventEmitter<MapArea>();
    @Output() protected readonly areaEdit = new EventEmitter<MapArea>();

    // NOTE: Area menu actions
    @Output() protected readonly areaTasksPreview = new EventEmitter<MapArea>();

    private readonly map: Promise<Map> = this.mapProvider.getMap();
    private readonly areaMenuComponent = this.viewContainerRef.createComponent(AreaMenuComponent);
    private readonly areaMenuPopup = new Popup(AREA_MENU_POPUP_OPTIONS).setContent(this.areaMenuComponent.location.nativeElement);

    constructor(
        private readonly localStore: LocalComponentStore<EditAreasDirectiveState>,
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        private readonly viewContainerRef: ViewContainerRef
    ) {
        this.localStore.setState({
            isContextMenuEnabled: true,
            isModeEnabled: false,
            selectedArea: undefined,
            selectedToolName: undefined,
            areaPastState: undefined,
        });

        this.initializeMap();
        this.watchAreaMenuActions();
    }

    public ngOnDestroy() {
        this.areaMenuComponent.destroy();
        this.map.then((map) => {
            map.off("keyup", this.handleKeyUpEvent, this);
            map.off(CustomLeafletMapEvent.LoadArea, this.addEventListenersToLoadedArea, this);
            map.off(CustomLeafletMapEvent.RevertSelectedAreaPosition, this.revertSelectedAreaPosition, this);
        });
    }

    private initializeMap(): void {
        this.map.then((map) => {
            map.on("keyup", this.handleKeyUpEvent, this);
            map.on(CustomLeafletMapEvent.LoadArea, this.addEventListenersToLoadedArea, this);
            map.on(CustomLeafletMapEvent.RevertSelectedAreaPosition, this.revertSelectedAreaPosition, this);
        });
    }

    private toggleEdit(isModeEnabled: boolean): void {
        this.localStore.patchState({ isModeEnabled });
        const selectedArea = this.localStore.selectSnapshotByKey("selectedArea");

        if (isModeEnabled) {
            this.selectArea(selectedArea);

            return;
        }

        this.deselectArea(selectedArea);
    }

    private onEditAreaClick(area: MapArea): void {
        const isModeEnabled = this.localStore.selectSnapshotByKey("isModeEnabled");
        if (!isModeEnabled) {
            return;
        }

        this.areaSelect.emit(area);
    }

    private selectArea(area: MapArea | undefined): void {
        const selectedArea = this.localStore.selectSnapshotByKey("selectedArea");
        if (selectedArea !== undefined && selectedArea !== area) {
            GeomanUtils.disableEditAndDrag(selectedArea);
            this.closeAreaMenu();
        }

        this.localStore.patchState({ selectedArea: area });

        const isModeEnabled = this.localStore.selectSnapshotByKey("isModeEnabled");
        if (!isModeEnabled) {
            return;
        }

        if (area) {
            this.updateAndOpenAreaMenu(area);
            GeomanUtils.enableEditAndDrag(area);
        }
    }

    private deselectArea(selectedArea: MapArea | undefined): void {
        if (selectedArea) {
            GeomanUtils.disableEditAndDrag(selectedArea);
            this.closeAreaMenu();
        }

        this.areaSelect.emit(undefined);
    }

    private onEditAreaDragStart(area: MapArea): void {
        const { isModeEnabled, selectedArea } = this.localStore.selectSnapshot((state) => state);
        if (!isModeEnabled || selectedArea !== area) {
            return;
        }

        this.saveEditedAreaState(area);

        GeomanUtils.onDragStart(area);
        this.closeAreaMenu();
    }

    private onEditAreaDragEnd(area: MapArea): void {
        const { isModeEnabled, selectedArea } = this.localStore.selectSnapshot((state) => state);
        if (!isModeEnabled || selectedArea !== area) {
            return;
        }

        this.areaEdit.emit(area);
        GeomanUtils.onDragEnd(area);
        this.openAreaMenu(area);
    }

    private onVertexMarkerDragStart(area: MapArea): void {
        this.saveEditedAreaState(area);
        this.closeAreaMenu();
    }

    private onVertexMarkerDragEnd(area: MapArea): void {
        this.areaEdit.emit(area);
        this.openAreaMenu(area);
    }

    private removeAreaOnDeletePressed(event: LeafletKeyboardEvent): void {
        const selectedArea = this.localStore.selectSnapshotByKey("selectedArea");
        if (event.originalEvent.key !== KeyboardEventKey.Delete || selectedArea === undefined) {
            return;
        }

        this.areaRemove.emit(selectedArea);
        this.closeAreaMenu();
    }

    private deselectAreaOnEscapePressed(event: LeafletKeyboardEvent): void {
        const selectedArea = this.localStore.selectSnapshotByKey("selectedArea");
        if (event.originalEvent.key !== KeyboardEventKey.Escape || selectedArea === undefined) {
            return;
        }

        this.deselectArea(selectedArea);
    }

    private updateAndOpenAreaMenu(area: MapArea): void {
        this.areaMenuComponent.instance.area = area;
        this.areaMenuComponent.changeDetectorRef.detectChanges();

        this.openAreaMenu(area);
    }

    private async openAreaMenu(area: MapArea): Promise<void> {
        if (!this.localStore.selectSnapshotByKey("isContextMenuEnabled")) {
            return;
        }

        if (area instanceof Circle) {
            const southernCenter = { lat: area.getBounds().getSouth(), lng: area.getBounds().getCenter().lng };
            (await this.map).openPopup(this.areaMenuPopup.setLatLng(southernCenter));

            return;
        }

        const polygonVertices = area.getLatLngs()[0] as LatLng[];
        const southernmostVertex = polygonVertices.reduce((result, vertex) => (result.lat > vertex.lat ? vertex : result));

        (await this.map).openPopup(this.areaMenuPopup.setLatLng(southernmostVertex));
    }

    private async closeAreaMenu(): Promise<void> {
        (await this.map).closePopup(this.areaMenuPopup);
    }

    private saveEditedAreaState(area: MapArea): void {
        this.localStore.patchState({
            areaPastState: SahMapUtils.createMutableMapArea(area),
        });
    }

    private loadEditedAreaPosition(area: MapArea): void {
        const areaPastState = this.localStore.selectSnapshotByKey("areaPastState");

        if (area instanceof Polygon && areaPastState instanceof Polygon) {
            area.setLatLngs(areaPastState.getLatLngs());
        } else if (area instanceof Circle && areaPastState instanceof Circle) {
            area.setLatLng(areaPastState.getLatLng());
            area.setRadius(areaPastState.getRadius());
        }

        GeomanUtils.enableEditAndDrag(area);
        this.openAreaMenu(area);
    }

    private watchAreaMenuActions(): void {
        this.areaMenuComponent.instance.tasksPreview.pipe(untilDestroyed(this)).subscribe((area) => this.areaTasksPreview.emit(area));
    }

    private handleKeyUpEvent(event: LeafletKeyboardEvent): void {
        const isModeEnabled = this.localStore.selectSnapshotByKey("isModeEnabled");
        if (!isModeEnabled) {
            return;
        }

        this.removeAreaOnDeletePressed(event);
        this.deselectAreaOnEscapePressed(event);
    }

    private addEventListenersToLoadedArea(event: LeafletEvent): void {
        const area = (event as unknown as LeafletEvent & { area: MapArea }).area;

        area.on("click", () => this.onEditAreaClick(area));
        area.on("pm:dragstart", () => this.onEditAreaDragStart(area));
        area.on("pm:dragend", () => this.onEditAreaDragEnd(area));
        area.on("pm:markerdragstart", () => this.onVertexMarkerDragStart(area));
        area.on("pm:markerdragend", () => this.onVertexMarkerDragEnd(area));
    }

    private revertSelectedAreaPosition(): void {
        const selectedArea = this.localStore.selectSnapshotByKey("selectedArea");

        if (!selectedArea) {
            return;
        }

        this.loadEditedAreaPosition(selectedArea);
    }
}
