import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, forwardRef } from "@angular/core";
import {
    ControlValueAccessor,
    FormArray,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from "@angular/forms";
import { ButtonTheme, ChipOption, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Operator, OperatorPilots, Pilot, TeamCreatorFormValues, UavCreatorFormValues } from "../../models/team.models";

interface TeamsCreatorComponentState {
    isProcessing: boolean;
    accessoryOptions: ChipOption[];
    operators: Operator[];
    operatorPilots: OperatorPilots | undefined;
    isInfoMessageVisible: boolean;
}

@UntilDestroy()
@Component({
    selector: "sah-client-lib-teams-creator",
    templateUrl: "teams-creator.component.html",
    styleUrls: ["teams-creator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TeamsCreatorComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TeamsCreatorComponent),
            multi: true,
        },
    ],
})
export class TeamsCreatorComponent implements ControlValueAccessor, Validator {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set accessoryOptions(value: ChipOption[] | undefined) {
        this.localStore.patchState({ accessoryOptions: value ?? [] });
    }

    @Input() public set operators(value: Operator[] | undefined) {
        this.localStore.patchState({ operators: value ?? [] });
    }

    @Input() public set operatorPilots(value: OperatorPilots | undefined) {
        this.localStore.patchState({ operatorPilots: value });
    }

    @Output() protected readonly operatorChange = new EventEmitter<Operator>();
    @Output() protected readonly pilotChange = new EventEmitter<{ operator: Operator; pilot: Pilot }>();

    protected readonly teamsCreatorForm = new FormArray<FormControl<Partial<TeamCreatorFormValues>>>([]);

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly accessoryOptions$ = this.localStore.selectByKey("accessoryOptions");
    protected readonly operators$ = this.localStore.selectByKey("operators");
    protected readonly operatorPilots$ = this.localStore.selectByKey("operatorPilots");
    protected readonly isInfoMessageVisible$ = this.localStore.selectByKey("isInfoMessageVisible");

    private onTouched = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    constructor(
        private readonly cdr: ChangeDetectorRef,
        private readonly dialogService: DialogService,
        private readonly localStore: LocalComponentStore<TeamsCreatorComponentState>,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({
            isProcessing: false,
            accessoryOptions: [],
            operators: [],
            operatorPilots: undefined,
            isInfoMessageVisible: true,
        });
    }

    public registerOnChange(fn: (value: Partial<TeamCreatorFormValues>[]) => void): void {
        this.teamsCreatorForm.valueChanges.pipe(untilDestroyed(this)).subscribe(fn);
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: Partial<TeamCreatorFormValues>[]): void {
        this.teamsCreatorForm.clear();
        value.forEach((teamValue) => this.addTeam(teamValue));
        this.cdr.detectChanges();
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.teamsCreatorForm.disable();
        } else {
            this.teamsCreatorForm.enable();
        }
    }

    public validate(): ValidationErrors | null {
        return this.teamsCreatorForm.invalid ? { invalidTeamsCreator: true } : null;
    }

    protected addTeam(teamValue?: Partial<TeamCreatorFormValues>): void {
        this.teamsCreatorForm.push(new FormControl<Partial<TeamCreatorFormValues>>(teamValue ?? {}, { nonNullable: true }));
    }

    protected removeTeam(teamControlValues: Partial<TeamCreatorFormValues>, teamIndex: number): void {
        const { name, operator, pilot, pilotName, uavs } = teamControlValues ?? {};
        const areUavsEmpty = this.isNullableArrayPropertyEmpty(uavs) || uavs?.every((uav) => this.areUavFormValuesEmpty(uav));
        const canRemoveWithoutConfirmation = !name && !operator && !pilot && !pilotName && areUavsEmpty;

        if (canRemoveWithoutConfirmation) {
            this.teamsCreatorForm.removeAt(teamIndex);

            return;
        }

        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("sahClientLibShared.teamsCreator.removeTeamDialog.title"),
                    confirmationText: this.translocoService.translate("sahClientLibShared.teamsCreator.removeTeamDialog.message"),
                    confirmButtonLabel: this.translocoService.translate(
                        "sahClientLibShared.teamsCreator.removeTeamDialog.confirmButtonLabel"
                    ),
                    declineButtonLabel: this.translocoService.translate(
                        "sahClientLibShared.teamsCreator.removeTeamDialog.cancelButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe(() => {
                this.teamsCreatorForm.removeAt(teamIndex);
                this.cdr.detectChanges();
            });
    }

    protected hideInfoMessage(): void {
        this.localStore.patchState({ isInfoMessageVisible: false });
    }

    private areUavFormValuesEmpty({ name, type, equipment }: Partial<UavCreatorFormValues>): boolean {
        return !name && !type && this.isNullableArrayPropertyEmpty(equipment);
    }

    private isNullableArrayPropertyEmpty(property: Array<unknown> | null | undefined): boolean {
        return FunctionUtils.isNullOrUndefined(property) || property.length === 0;
    }
}
