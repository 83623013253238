<ng-container *ngrxLet="selectedMapToolName$ as selectedMapToolName">
    <div class="container">
        <ng-container
            *ngIf="MAP_TOOLS[MapToolName.Cursor] as tool"
            [ngTemplateOutlet]="toolButtonTemplate"
            [ngTemplateOutletContext]="{ $implicit: tool }"
        ></ng-container>
        <ng-container
            *ngIf="MAP_TOOLS[MapToolName.Hand] as tool"
            [ngTemplateOutlet]="toolButtonTemplate"
            [ngTemplateOutletContext]="{ $implicit: tool }"
        ></ng-container>
        <ng-container
            *ngIf="MAP_TOOLS[MapToolName.DrawPencil] as tool"
            [ngTemplateOutlet]="toolButtonTemplate"
            [ngTemplateOutletContext]="{ $implicit: tool }"
        ></ng-container>
        <ng-container
            *ngIf="getMenuState(MenuType.AreaDrawing) | ngrxPush as menuState"
            [ngTemplateOutlet]="menuButtonTemplate"
            [ngTemplateOutletContext]="{ $implicit: menuState }"
        >
        </ng-container>
        <!--        <ng-container-->
        <!--            *ngIf="MAP_TOOLS[MapToolName.DrawArrowStraight] as tool"-->
        <!--            [ngTemplateOutlet]="toolButtonTemplate"-->
        <!--            [ngTemplateOutletContext]="{ $implicit: tool }"-->
        <!--        ></ng-container>-->
        <!--        <ng-container-->
        <!--            *ngIf="MAP_TOOLS[MapToolName.Text] as tool"-->
        <!--            [ngTemplateOutlet]="toolButtonTemplate"-->
        <!--            [ngTemplateOutletContext]="{ $implicit: tool }"-->
        <!--        ></ng-container>-->
        <ng-container
            *ngIf="getMenuState(MenuType.ActionMarker) | ngrxPush as menuState"
            [ngTemplateOutlet]="actionMarkersTemplate"
            [ngTemplateOutletContext]="{ $implicit: menuState }"
        ></ng-container>
        <!--        <ng-container-->
        <!--            *ngIf="MAP_TOOLS[MapToolName.StickyNote] as tool"-->
        <!--            [ngTemplateOutlet]="toolButtonTemplate"-->
        <!--            [ngTemplateOutletContext]="{ $implicit: tool }"-->
        <!--        ></ng-container>-->
        <ng-container
            *ngIf="MAP_TOOLS[MapToolName.Ruler] as tool"
            [ngTemplateOutlet]="toolButtonTemplate"
            [ngTemplateOutletContext]="{ $implicit: tool }"
        ></ng-container>
    </div>

    <!--    <div class="container">-->
    <!--        <button type="button" class="button-icon" (click)="actionUndo.emit()">-->
    <!--            <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>-->
    <!--        </button>-->
    <!--        <button type="button" class="button-icon">-->
    <!--            <dtm-ui-icon name="arrow-go-forward" (click)="actionRedo.emit()"></dtm-ui-icon>-->
    <!--        </button>-->
    <!--    </div>-->

    <ng-template #menuButtonTemplate let-menu>
        <button
            cdkOverlayOrigin
            #toolsMenuButton="cdkOverlayOrigin"
            type="button"
            class="button-icon"
            [class.selected-tool-button]="selectedMapToolName | invoke : isAnyToolSelected : menu.tools"
            [class.menu-opened]="menu.isOpened"
            (click)="selectFirstMenuToolIfNoneIsSelected(menu); toggleMenu(menu.type)"
        >
            <dtm-ui-icon [name]="menu.currentTool.icon"></dtm-ui-icon>
            <ng-template
                cdkConnectedOverlay
                [cdkConnectedOverlayHasBackdrop]="true"
                [cdkConnectedOverlayBackdropClass]="'incident-toolbar-backdrop'"
                [cdkConnectedOverlayOrigin]="toolsMenuButton"
                [cdkConnectedOverlayOpen]="menu.isOpened"
                [cdkConnectedOverlayOffsetX]="OVERLAY_OFFSET_X"
                [cdkConnectedOverlayOffsetY]="OVERLAY_OFFSET_Y"
                (overlayOutsideClick)="toggleMenu(menu.type); toolSelect.emit(selectedMapToolName)"
            >
                <div class="container">
                    <button type="button" class="button-icon" (click)="toggleMenu(menu.type)">
                        <dtm-ui-icon name="close"></dtm-ui-icon>
                    </button>

                    <div class="divider"></div>

                    <button
                        *ngFor="let tool of menu.tools"
                        type="button"
                        class="button-icon"
                        [class.selected-tool-button]="selectedMapToolName === tool.name"
                        (click)="selectTool(tool.name); toggleMenu(menu.type, tool)"
                    >
                        <dtm-ui-icon [name]="tool.icon"></dtm-ui-icon>
                    </button>
                </div>
            </ng-template>
        </button>
    </ng-template>

    <ng-template #toolButtonTemplate let-tool>
        <button
            type="button"
            class="button-icon"
            [class.selected-tool-button]="selectedMapToolName === tool.name"
            (click)="selectTool(tool.name)"
        >
            <dtm-ui-icon [name]="tool.icon"></dtm-ui-icon>
        </button>
    </ng-template>

    <ng-template #actionMarkersTemplate let-menu>
        <button
            cdkOverlayOrigin
            #toolsMenuButton="cdkOverlayOrigin"
            type="button"
            class="button-icon"
            [class.selected-tool-button]="selectedMapToolName | invoke : isAnyToolSelected : menu.tools"
            [class.menu-opened]="menu.isOpened"
            (click)="selectFirstMenuToolIfNoneIsSelected(menu); toggleMenu(menu.type)"
        >
            <dtm-ui-icon [name]="MAP_TOOLS[MapToolName.ActionMarker].icon"></dtm-ui-icon>
            <ng-template
                cdkConnectedOverlay
                [cdkConnectedOverlayHasBackdrop]="true"
                [cdkConnectedOverlayBackdropClass]="'incident-toolbar-backdrop'"
                [cdkConnectedOverlayOrigin]="toolsMenuButton"
                [cdkConnectedOverlayOpen]="menu.isOpened"
                [cdkConnectedOverlayOffsetX]="OVERLAY_OFFSET_X"
                [cdkConnectedOverlayOffsetY]="OVERLAY_OFFSET_Y"
                (overlayOutsideClick)="toggleMenu(menu.type); toolSelect.emit(selectedMapToolName)"
            >
                <sah-shared-lib-action-markers-select-list
                    [selectedActionMarker]="selectedMapToolName"
                    (markerSelect)="selectTool($event.name); toggleMenu(menu.type, $event)"
                ></sah-shared-lib-action-markers-select-list>
            </ng-template>
        </button>
    </ng-template>
</ng-container>
