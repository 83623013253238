import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { BYTES_IN_MEGABYTE, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ActionMarkerData } from "../../../models/incident-map.models";

interface ActionMarkerEditorComponentState {
    markerName: string | undefined;
}

const NAME_MAX_LENGTH = 100;
// eslint-disable-next-line no-magic-numbers
const FILE_MAX_SIZE = 25 * BYTES_IN_MEGABYTE;

@UntilDestroy()
@Component({
    selector: "sah-shared-lib-action-marker-editor",
    templateUrl: "action-marker-editor.component.html",
    styleUrls: ["action-marker-editor.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ActionMarkerEditorComponent {
    @Input() public set markerData(value: Partial<ActionMarkerData> | undefined) {
        if (value?.location) {
            const { latitude, longitude } = value.location;
            this.actionMarkerForm.reset({
                name: value.name,
                photo: value.photo,
                hasPhotoBeenUpdated: false,
                latitude,
                longitude,
            });
            this.localStore.patchState({ markerName: value.name });
        }
    }

    @Output() protected readonly dataSubmit = new EventEmitter<Partial<ActionMarkerData>>();
    @Output() protected readonly editCancel = new EventEmitter<void>();

    protected readonly FILE_MAX_SIZE = FILE_MAX_SIZE;
    protected readonly GeographicCoordinatesType = GeographicCoordinatesType;

    protected readonly markerName$ = this.localStore.selectByKey("markerName");

    protected readonly actionMarkerForm = new FormGroup({
        name: new FormControl<string | null>(null, [Validators.maxLength(NAME_MAX_LENGTH)]),
        latitude: new FormControl<number | null>(null, Validators.required),
        longitude: new FormControl<number | null>(null, Validators.required),
        photo: new FormControl<Blob | null>(null),
        hasPhotoBeenUpdated: new FormControl<boolean>(false),
    });

    constructor(private readonly localStore: LocalComponentStore<ActionMarkerEditorComponentState>) {
        this.localStore.setState({
            markerName: undefined,
        });
    }

    protected submitForm(): void {
        if (this.actionMarkerForm.invalid) {
            this.actionMarkerForm.markAllAsTouched();

            return;
        }

        this.dataSubmit.emit(this.getFormValuesAsActionMarkerData());
    }

    protected markPhotoAsUpdated(): void {
        const hasPhotoBeenUpdatedControl = this.actionMarkerForm.controls.hasPhotoBeenUpdated;
        if (hasPhotoBeenUpdatedControl.value) {
            return;
        }

        hasPhotoBeenUpdatedControl.setValue(true);
    }

    private getFormValuesAsActionMarkerData(): Partial<ActionMarkerData> {
        const { name, latitude, longitude, photo, hasPhotoBeenUpdated } = this.actionMarkerForm.value;

        return {
            name: name ?? undefined,
            photo: photo ?? undefined,
            hasPhotoBeenUpdated: hasPhotoBeenUpdated ?? undefined,
            ...(latitude && longitude && { location: { latitude, longitude } }),
        };
    }
}
