import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { DOCUMENT } from "@angular/common";
import { Directive, EventEmitter, Inject, Input, OnDestroy, Output } from "@angular/core";
import {
    AREA_DRAWING_TOOLS,
    DEFAULT_MAP_AREA_HINT_LINE_STYLE,
    DEFAULT_MAP_AREA_PATH_OPTIONS,
    MapArea,
    MapToolName,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "@dtm-frontend/shared/map/leaflet";
import { KeyboardEventKey, LocalComponentStore } from "@dtm-frontend/shared/utils";
import "@geoman-io/leaflet-geoman-free";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Layer, LeafletKeyboardEvent, Map } from "leaflet";
import { combineLatestWith } from "rxjs";
import { GeomanUtils } from "../../../geoman/index";

interface DrawAreasDirectiveState {
    isModeEnabled: boolean;
    isDrawingHelpHidden: boolean;
    isDrawingEnabled: boolean;
    isMouseOverControls: boolean;
    selectedToolName: MapToolName | undefined;
}

const ENABLE_DRAW_OPTIONS = {
    continueDrawing: true,
    templineStyle: DEFAULT_MAP_AREA_PATH_OPTIONS,
    hintlineStyle: DEFAULT_MAP_AREA_HINT_LINE_STYLE,
    pathOptions: DEFAULT_MAP_AREA_PATH_OPTIONS,
};

@UntilDestroy()
@Directive({
    selector: "sah-client-lib-draw-areas[isModeEnabled][selectedToolName]",
    providers: [LocalComponentStore],
})
export class DrawAreasDirective implements OnDestroy {
    @Input() public set isModeEnabled(value: BooleanInput) {
        this.localStore.patchState({ isModeEnabled: coerceBooleanProperty(value) });
    }
    @Input() public set isDrawingHelpHidden(value: BooleanInput) {
        this.localStore.patchState({ isDrawingHelpHidden: coerceBooleanProperty(value) });
    }
    @Input() public set selectedToolName(value: MapToolName | undefined) {
        this.localStore.patchState({ selectedToolName: value });
    }

    @Output() protected readonly areaCreate: EventEmitter<MapArea> = new EventEmitter<MapArea>();

    private readonly map: Promise<Map> = this.mapProvider.getMap();

    constructor(
        @Inject(DOCUMENT) private readonly document: Document,
        private readonly localStore: LocalComponentStore<DrawAreasDirectiveState>,
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider
    ) {
        this.localStore.setState({
            isModeEnabled: false,
            isDrawingHelpHidden: false,
            isMouseOverControls: false,
            isDrawingEnabled: false,
            selectedToolName: undefined,
        });

        this.initializeMap();
        this.mapProvider.getControlSections().then((controls) =>
            controls.forEach((control) => {
                control.nativeElement.addEventListener("mouseenter", () => this.localStore.patchState({ isMouseOverControls: true }));
                control.nativeElement.addEventListener("mouseleave", () => this.localStore.patchState({ isMouseOverControls: false }));
            })
        );

        this.updateDrawingMarkerAndTooltipVisibility();
        this.updateSelectedTool();
    }

    public ngOnDestroy() {
        this.map.then((map) => {
            map.off("keyup", this.handleKeyUpEvent, this);
            map.off("pm:create", this.emitCreatedArea, this);
        });
    }

    private async initializeMap(): Promise<void> {
        const map = await this.map;

        map.on("keyup", this.handleKeyUpEvent, this);
        map.on("pm:create", this.emitCreatedArea, this);
    }

    private async enableTool(toolName?: MapToolName): Promise<void> {
        const map = await this.map;

        switch (toolName) {
            case MapToolName.ShapeRectangle:
                map.pm.enableDraw("Rectangle", ENABLE_DRAW_OPTIONS);
                break;
            case MapToolName.ShapeCircle:
                map.pm.enableDraw("Circle", ENABLE_DRAW_OPTIONS);
                break;
            case MapToolName.ShapePolygon:
                map.pm.enableDraw("Polygon", ENABLE_DRAW_OPTIONS);
                break;
            default:
                break;
        }

        this.localStore.patchState({ isDrawingEnabled: AREA_DRAWING_TOOLS.some((tool) => tool.name === toolName) });
    }

    private updateDrawingMarkerAndTooltipVisibility(): void {
        this.localStore
            .selectByKey("isDrawingEnabled")
            .pipe(
                combineLatestWith(this.localStore.selectByKey("isMouseOverControls"), this.localStore.selectByKey("isDrawingHelpHidden")),
                untilDestroyed(this)
            )
            .subscribe(([isDrawingEnabled, isMouseCursorOverControls, isDrawingHelpHidden]) => {
                if (!isDrawingEnabled) {
                    return;
                }

                const shouldDisplayTooltips = !isMouseCursorOverControls && !isDrawingHelpHidden;
                const marker = this.document.querySelector(".marker-icon.cursor-marker") as HTMLElement;
                const tooltipId = marker?.attributes.getNamedItem("aria-describedby")?.value;
                const tooltip = this.document.querySelector(`#${tooltipId}`) as HTMLElement;

                if (!marker || !tooltip) {
                    return;
                }

                marker.style.display = shouldDisplayTooltips ? "block" : "none";
                tooltip.style.display = shouldDisplayTooltips ? "block" : "none";
                this.hideDrawingMarkerAndTooltipUntilMouseMove();
            });
    }

    private updateSelectedTool(): void {
        this.localStore
            .selectByKey("isModeEnabled")
            .pipe(combineLatestWith(this.localStore.selectByKey("selectedToolName")), untilDestroyed(this))
            .subscribe(([isModeEnabled, selectedToolName]) => {
                if (!isModeEnabled) {
                    this.map.then((mapInstance) => mapInstance.pm.disableDraw());

                    return;
                }

                this.enableTool(selectedToolName);
            });
    }

    private hideDrawingMarkerAndTooltipUntilMouseMove(): void {
        const marker = this.document.querySelector(".marker-icon.cursor-marker") as HTMLElement;
        const tooltipId = marker?.attributes.getNamedItem("aria-describedby")?.value;
        const tooltip = this.document.querySelector(`#${tooltipId}`) as HTMLElement;

        if (!marker || !tooltip) {
            return;
        }

        /* NOTE: Prevents marker and tooltip from being incorrectly positioned when overlay panel closes or shape drawing ends.
           Transform style is controlled by the geoman library and will change after mouse move.
         */
        marker.style.transform = "translate(-1000px, 0)";
        tooltip.style.transform = "translate(-1000px, 0)";
    }

    private async cancelDrawingOnEscPressed(event: LeafletKeyboardEvent): Promise<void> {
        if (event.originalEvent.key !== KeyboardEventKey.Escape) {
            return;
        }

        const map = await this.map;

        map.pm.disableDraw();
        await this.enableTool(this.localStore.selectSnapshotByKey("selectedToolName"));
        this.hideDrawingMarkerAndTooltipUntilMouseMove();
    }

    private async removeLastPolygonVertexOnBackspacePressed(event: LeafletKeyboardEvent): Promise<void> {
        const map = await this.map;
        if (map.pm.Draw.getActiveShape() !== "Polygon" || event.originalEvent.key !== KeyboardEventKey.Backspace) {
            return;
        }

        this.removeLastPolygonVertex();
    }

    private async removeLastPolygonVertex(): Promise<void> {
        const map = await this.map;

        GeomanUtils.removeLastPolygonVertex(map);

        if (!map.pm.globalDrawModeEnabled()) {
            map.pm.enableDraw("Polygon", ENABLE_DRAW_OPTIONS);
            this.hideDrawingMarkerAndTooltipUntilMouseMove();
        }
    }

    private handleKeyUpEvent(event: LeafletKeyboardEvent): void {
        const isModeEnabled = this.localStore.selectSnapshotByKey("isModeEnabled");
        if (!isModeEnabled) {
            return;
        }

        this.cancelDrawingOnEscPressed(event);
        this.removeLastPolygonVertexOnBackspacePressed(event);
    }

    private emitCreatedArea({ layer }: { layer: Layer }): void {
        const drawnArea = layer as MapArea;
        this.areaCreate.emit(drawnArea);
    }
}
