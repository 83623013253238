<form
    *ngrxLet="{ datePickerPlaceholder: datePickerPlaceholder$, isDisabled: isDisabled$ } as vm"
    autocomplete="off"
    [formGroup]="taskCreatorForm"
>
    <dtm-ui-input-field>
        <label for="nameControl">{{ "sahSharedLibIncident.tasks.taskCreatorForm.nameLabel" | transloco }}</label>
        <input matInput id="nameControl" formControlName="name" type="text" />
        <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.name; name: ['required', 'pattern']">
            {{ "sahSharedLibIncident.tasks.taskCreatorForm.fieldRequiredError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.name; name: 'maxlength'; error as error">
            {{ "sahSharedLibIncident.tasks.taskCreatorForm.fieldMaxlengthError" | transloco : { maxLength: error.requiredLength } }}
        </div>
    </dtm-ui-input-field>

    <div class="paired-fields">
        <dtm-ui-date-field>
            <label for="startDateControl">{{ "sahSharedLibIncident.tasks.taskCreatorForm.startDateLabel" | transloco }} </label>
            <input
                matInput
                id="startDateControl"
                formControlName="startDate"
                [matDatepicker]="startDatePicker"
                [min]="MIN_DATE"
                [placeholder]="vm.datePickerPlaceholder"
            />
            <mat-datepicker #startDatePicker></mat-datepicker>
            <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.startDate; name: 'matDatepickerMin'; error as error">
                {{
                    "sahSharedLibIncident.tasks.taskCreatorForm.minDateError"
                        | transloco : { minDate: error.min | localizeDate : { dateStyle: "short" } }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="taskCreatorForm; name: ['requiredStartDate']">
                {{ "sahSharedLibIncident.tasks.taskCreatorForm.fieldRequiredError" | transloco }}
            </div>
        </dtm-ui-date-field>
        <dtm-ui-time-field formControlName="startTime" [referenceDate]="taskCreatorForm.controls.startDate.value" [isClearable]="true">
            <label>{{ "sahSharedLibIncident.tasks.taskCreatorForm.startTimeLabel" | transloco }} </label>
            <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.startTime; name: 'min'; error as error">
                {{
                    "sahSharedLibIncident.tasks.taskCreatorForm.minTimeError"
                        | transloco : { minTime: error.min | localizeDate : { timeStyle: "short" } }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="taskCreatorForm; name: ['requiredStartTime']">
                {{ "sahSharedLibIncident.tasks.taskCreatorForm.fieldRequiredError" | transloco }}
            </div>
        </dtm-ui-time-field>
    </div>

    <div class="paired-fields" *ngrxLet="minEndDate$ as minEndDate">
        <dtm-ui-date-field>
            <label for="endDateControl">{{ "sahSharedLibIncident.tasks.taskCreatorForm.endDateLabel" | transloco }} </label>
            <input
                matInput
                id="endDateControl"
                formControlName="endDate"
                [matDatepicker]="endDatePicker"
                [min]="minEndDate"
                [placeholder]="vm.datePickerPlaceholder"
            />
            <mat-datepicker #endDatePicker></mat-datepicker>
            <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.endDate; name: 'matDatepickerMin'; error as error">
                {{
                    "sahSharedLibIncident.tasks.taskCreatorForm.minDateError"
                        | transloco : { minDate: error.min | localizeDate : { dateStyle: "short" } }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="taskCreatorForm; name: ['requiredEndDate']">
                {{ "sahSharedLibIncident.tasks.taskCreatorForm.fieldRequiredError" | transloco }}
            </div>
        </dtm-ui-date-field>
        <dtm-ui-time-field
            formControlName="endTime"
            [minTime]="taskCreatorForm.controls.endDate.value && minEndDate"
            [referenceDate]="taskCreatorForm.controls.endDate.value"
            [isClearable]="true"
        >
            <label>{{ "sahSharedLibIncident.tasks.taskCreatorForm.endTimeLabel" | transloco }} </label>
            <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.endTime; name: 'min'; error as error">
                {{
                    "sahSharedLibIncident.tasks.taskCreatorForm.minTimeError"
                        | transloco : { minTime: error.min | localizeDate : { timeStyle: "short" } }
                }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="taskCreatorForm; name: ['requiredEndTime']">
                {{ "sahSharedLibIncident.tasks.taskCreatorForm.fieldRequiredError" | transloco }}
            </div>
        </dtm-ui-time-field>
    </div>

    <button
        type="button"
        class="button-secondary clear-button"
        [disabled]="vm.isDisabled || (isDateClearButtonDisabled$ | ngrxPush)"
        (click)="clearDates()"
    >
        {{ "sahSharedLibIncident.tasks.taskCreatorForm.clearDatesButtonLabel" | transloco }}
    </button>

    <dtm-ui-expanded-number-input
        [formControl]="taskCreatorForm.controls.floorAltitude"
        [valueSuffix]="'sahSharedLibIncident.tasks.taskCreatorForm.altitudeSuffix' | transloco"
    >
        <label>{{ "sahSharedLibIncident.tasks.taskCreatorForm.floorAltitudeLabel" | transloco }}</label>
        <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.floorAltitude; name: 'required'">
            {{ "sahSharedLibIncident.tasks.taskCreatorForm.fieldRequiredError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.floorAltitude; name: 'min'; error as error">
            {{
                "sahSharedLibIncident.tasks.taskCreatorForm.minValueError"
                    | transloco
                        : {
                              min: error.min,
                              unit: "sahSharedLibIncident.tasks.taskCreatorForm.altitudeSuffix" | transloco
                          }
            }}
        </div>
    </dtm-ui-expanded-number-input>

    <dtm-ui-expanded-number-input
        [formControl]="taskCreatorForm.controls.ceilingAltitude"
        [valueSuffix]="'sahSharedLibIncident.tasks.taskCreatorForm.altitudeSuffix' | transloco"
    >
        <label>{{ "sahSharedLibIncident.tasks.taskCreatorForm.ceilingAltitudeLabel" | transloco }}</label>
        <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.ceilingAltitude; name: 'required'">
            {{ "sahSharedLibIncident.tasks.taskCreatorForm.fieldRequiredError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.ceilingAltitude; name: 'min'; error as error">
            {{
                "sahSharedLibIncident.tasks.taskCreatorForm.minValueError"
                    | transloco
                        : {
                              min: error.min,
                              unit: "sahSharedLibIncident.tasks.taskCreatorForm.altitudeSuffix" | transloco
                          }
            }}
        </div>
    </dtm-ui-expanded-number-input>

    <dtm-ui-checkbox-field formControlName="areDetailsRequired">
        <span>{{ "sahSharedLibIncident.tasks.taskCreatorForm.areDetailsRequiredLabel" | transloco }}</span>
    </dtm-ui-checkbox-field>

    <dtm-ui-textarea-field *ngIf="taskCreatorForm.controls.details.enabled">
        <label for="detailsControl">{{ "sahSharedLibIncident.tasks.taskCreatorForm.detailsLabel" | transloco }}</label>
        <textarea matInput id="detailsControl" formControlName="details" [rows]="3"></textarea>
        <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.details; name: ['required', 'pattern']">
            {{ "sahSharedLibIncident.tasks.taskCreatorForm.fieldRequiredError" | transloco }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.details; name: 'maxlength'; error as error">
            {{ "sahSharedLibIncident.tasks.taskCreatorForm.fieldMaxlengthError" | transloco : { maxLength: error.requiredLength } }}
        </div>
    </dtm-ui-textarea-field>
</form>
