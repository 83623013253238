import { Pipe, PipeTransform } from "@angular/core";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { firstValueFrom } from "rxjs";
import { IncidentCategory } from "../models/incident.models";

@Pipe({
    name: "incidentCategoryList",
})
export class IncidentCategoryListPipe implements PipeTransform {
    constructor(private readonly translationHelperService: TranslationHelperService) {}

    public async transform(categories: IncidentCategory[]): Promise<string | undefined> {
        if (FunctionUtils.isNullOrUndefined(categories)) {
            return undefined;
        }

        return (
            await Promise.all(
                categories.map((category) =>
                    firstValueFrom(
                        this.translationHelperService.waitForTranslation("sahSharedLibIncident.incidentList.categoryValueLabel", {
                            value: category,
                        })
                    )
                )
            )
        ).join(", ");
    }
}
