<sah-shared-lib-task-status-badge *ngIf="task$ | ngrxPush as task" [status]="task.status" (dblclick)="$event.stopPropagation()">
    <div class="task-description">
        <ng-container *ngIf="task.attachedTeam; else onlyTaskTemplate">{{
            "sahSharedLibIncident.tasks.taskMarkerIcon.descriptionMessage"
                | transloco
                    : {
                          teamName: task.attachedTeam.name | ellipsifyText : TEXT_MAX_LENGTH,
                          taskName: task.name | ellipsifyText : TEXT_MAX_LENGTH
                      }
        }}</ng-container>
        <ng-template #onlyTaskTemplate>
            {{ task.name | ellipsifyText : TEXT_MAX_LENGTH }}
        </ng-template>
    </div>
</sah-shared-lib-task-status-badge>
