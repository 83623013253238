<ng-container *ngrxLet="selectedActionMarker$ as selectedActionMarker">
    <button
        *ngFor="let marker of ACTION_MARKER_TOOLS"
        type="button"
        class="button-icon"
        [class.selected]="selectedActionMarker | invoke : isMarkerSelected : marker"
        (click)="markerSelect.emit(marker)"
    >
        <ng-container *ngIf="marker.icon">
            <dtm-ui-icon [name]="marker.icon"></dtm-ui-icon>
        </ng-container>
        <ng-container *ngIf="marker.letterIcon">
            <div class="letter-icon">{{ marker.letterIcon }}</div>
        </ng-container>
    </button>
</ng-container>
