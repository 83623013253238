<ng-container *ngIf="area$ | ngrxPush as area">
    <div class="area-info">
        <span *ngIf="area.data" class="area-name">
            {{ area.data.name }}
        </span>
        <div class="area-actions">
            <ng-content select="[actions]"></ng-content>
        </div>
    </div>
    <div class="area-coordinates">
        <ng-container *ngIf="area | invoke : isInstanceofCircle; else polygonDmsCoordinatesTemplate">
            {{
                "sahSharedLibIncident.areas.areaCard.circleCoordinatesInfo"
                    | transloco : { dmsCoordinates: area | invoke : getDmsCoordinates, radius: area | invoke : getRadius }
            }}
        </ng-container>
        <ng-template #polygonDmsCoordinatesTemplate>
            {{
                "sahSharedLibIncident.areas.areaCard.polygonCoordinatesInfo"
                    | transloco : { dmsCoordinates: area | invoke : getDmsCoordinates }
            }}
        </ng-template>
    </div>
</ng-container>
