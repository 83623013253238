import { InjectionToken } from "@angular/core";

export interface IncidentSharedEndpoints {
    getIncidentMapData: string;
    createActionMarker: string;
    updateActionMarker: string;
    addActionMarkerPhoto: string;
    removeActionMarkerPhoto: string;
    getActionMarkerPhoto: string;
    removeActionMarker: string;
    sendIncidentMessage: string;
    wsIncidentTopic: string;
}

export const INCIDENT_SHARED_ENDPOINTS = new InjectionToken<IncidentSharedEndpoints>("Incident shared endpoints");
