<dtm-ui-loader-container
    *ngrxLet="{ customArea: customArea$, isProcessing: isProcessing$, activeStep: activeStep$ } as vm"
    [isShown]="vm.isProcessing"
>
    <ng-container [ngSwitch]="vm.activeStep">
        <sah-client-lib-incident-creator-data
            *ngSwitchCase="IncidentCreatorStep.Data"
            [isEditMode]="isEditMode$ | ngrxPush"
            [data]="editedIncidentData$ | ngrxPush"
            [airOperationsCoordinators]="airOperationsCoordinators$ | ngrxPush"
            [flightsCoordinators]="flightsCoordinators$ | ngrxPush"
            [institutions]="institutions$ | ngrxPush"
            [units]="units$ | ngrxPush"
            [restrictions]="restrictions$ | ngrxPush"
            [userId]="userId$ | ngrxPush"
            [isProcessing]="vm.isProcessing"
            [customArea]="vm.customArea"
            [hasGetRestrictionsError]="hasGetRestrictionsError$ | ngrxPush"
            (isCustomAreaChange)="updateIncidentAreaType($event)"
            (restrictedAreaChange)="updateRestrictedArea($event)"
            (formSubmit)="createOrUpdateIncident($event)"
            (formCancel)="cancel()"
        ></sah-client-lib-incident-creator-data>

        <sah-client-lib-incident-creator-teams
            *ngSwitchCase="IncidentCreatorStep.Teams"
            (formSubmit)="createTeams($event)"
            (stepSkip)="skipStep()"
        >
        </sah-client-lib-incident-creator-teams>
    </ng-container>

    <sah-client-lib-incident-creator-map
        [isDrawingHelpHidden]="isDrawingHelpHidden$ | ngrxPush"
        [selectedMapTool]="selectedMapTool$ | ngrxPush"
        [customArea]="vm.customArea"
        [areToolsEnabled]="(isCustomIncidentAreaSelected$ | ngrxPush) && vm.activeStep === IncidentCreatorStep.Data"
        (areaCreate)="createCustomArea($event)"
        (drawingToolSelect)="selectDrawingTool($event)"
        (incidentAreaRemove)="removeCustomArea()"
    ></sah-client-lib-incident-creator-map>
</dtm-ui-loader-container>
