<ng-container *ngrxLet="{ area: area$, task: task$ } as vm">
    <section *ngIf="vm.task">
        <div class="header">{{ vm.task.name }}</div>
        <dtm-ui-label-value [label]="'sahSharedLibIncident.tasks.timeRangeLabel' | transloco">
            <ng-container *ngIf="vm.task.timeRange?.start && vm.task.timeRange?.stop">{{
                "sahSharedLibIncident.tasks.timeRangeStartStopValueLabel"
                    | transloco
                        : {
                              start: (vm.task.timeRange?.start | localizeDate : { dateStyle: "short", timeStyle: "short" }),
                              stop: (vm.task.timeRange?.stop | localizeDate : { dateStyle: "short", timeStyle: "short" })
                          }
            }}</ng-container>
            <ng-container *ngIf="vm.task.timeRange?.start && !vm.task.timeRange?.stop">{{
                "sahSharedLibIncident.tasks.timeRangeStartValueLabel"
                    | transloco : { start: (vm.task.timeRange?.start | localizeDate : { dateStyle: "short", timeStyle: "short" }) }
            }}</ng-container>
            <ng-container *ngIf="!vm.task.timeRange?.start && vm.task.timeRange?.stop">{{
                "sahSharedLibIncident.tasks.timeRangeStopValueLabel"
                    | transloco : { stop: (vm.task.timeRange?.stop | localizeDate : { dateStyle: "short", timeStyle: "short" }) }
            }}</ng-container>
            <ng-container *ngIf="!vm.task.timeRange?.start && !vm.task.timeRange?.stop">{{
                "sahSharedLibIncident.tasks.timeRangeNoValueLabel" | transloco
            }}</ng-container>
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'sahSharedLibIncident.tasks.altitudeRangeLabel' | transloco">
            {{
                "sahSharedLibIncident.tasks.altitudeRangeValueLabel"
                    | transloco
                        : {
                              floor: vm.task.altitudeRange.floor,
                              ceiling: vm.task.altitudeRange.ceiling,
                              suffix: ("sahSharedLibIncident.tasks.altitudeSuffix" | transloco)
                          }
            }}
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'sahSharedLibIncident.tasks.areaLabel' | transloco" [value]="vm.area?.data?.name"></dtm-ui-label-value>
    </section>
</ng-container>
