<ng-container *ngIf="markerData$ | ngrxPush as markerData">
    <div class="details">
        <div *ngIf="markerData.name as name" class="name">{{ name }}</div>
        <div *ngIf="markerData.location as location" class="location">{{ location | invoke : getLocationDMS }}</div>
        <sah-shared-lib-action-marker-photo-preview
            *ngIf="markerData.photoId"
            [markerName]="markerData.name"
            [photo]="markerData.photo"
        ></sah-shared-lib-action-marker-photo-preview>
    </div>

    <ng-container *ngIf="!(isReadonly$ | ngrxPush)">
        <div class="divider"></div>

        <div class="action-bar">
            <button type="button" class="button-icon" (click)="iconChange.emit()">
                <dtm-ui-icon *ngIf="markerData.tool?.icon as icon" [name]="icon"></dtm-ui-icon>
                <div *ngIf="markerData.tool?.letterIcon as letterIcon">{{ letterIcon }}</div>
            </button>

            <button type="button" class="button-icon" [class.selected]="isDraggable$ | ngrxPush" (click)="toggleDraggable()">
                <dtm-ui-icon name="drag-move"></dtm-ui-icon>
            </button>

            <button type="button" class="button-icon" (click)="edit.emit()">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
            </button>

            <button type="button" class="button-icon" (click)="remove.emit()">
                <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
            </button>
        </div>
    </ng-container>
</ng-container>
