<ng-container *ngrxLet="{ task: task$, isPreview: isPreview$ } as vm">
    <div *ngIf="vm.task" class="task-card" [class]="vm.task.status | invoke : getStatusClass">
        <div class="header">
            <span>{{ vm.task.name }}</span>
            <div *ngIf="!vm.isPreview">
                <ng-content select="[actions]"></ng-content>
            </div>
        </div>

        <div class="status-bar">
            <sah-shared-lib-task-status-badge-menu
                [status]="vm.task.status"
                [availableStatuses]="availableStatuses$ | ngrxPush"
                [isPreview]="vm.isPreview || !(isStatusChangeEnabled$ | ngrxPush)"
                (statusChange)="statusChange.emit({ status: $event, task: vm.task })"
            ></sah-shared-lib-task-status-badge-menu>
            <sah-shared-lib-task-team-badge-menu
                [attachedTeam]="vm.task.attachedTeam"
                [availableTeams]="availableTeams$ | ngrxPush"
                [isPreview]="vm.isPreview || !(isTeamChangeEnabled$ | ngrxPush)"
                (teamDetach)="teamDetach.emit(vm.task)"
                (teamAttach)="teamAttach.emit({ task: vm.task, team: $event })"
            ></sah-shared-lib-task-team-badge-menu>
        </div>

        <div class="content">
            <dtm-ui-label-value [label]="'sahSharedLibIncident.tasks.timeRangeLabel' | transloco">
                <span *ngIf="vm.task.timeRange?.start && vm.task.timeRange?.stop">{{
                    "sahSharedLibIncident.tasks.timeRangeStartStopValueLabel"
                        | transloco
                            : {
                                  start: (vm.task.timeRange?.start | localizeDate : { dateStyle: "short", timeStyle: "short" }),
                                  stop: (vm.task.timeRange?.stop | localizeDate : { dateStyle: "short", timeStyle: "short" })
                              }
                }}</span>
                <span *ngIf="vm.task.timeRange?.start && !vm.task.timeRange?.stop">{{
                    "sahSharedLibIncident.tasks.timeRangeStartValueLabel"
                        | transloco : { start: (vm.task.timeRange?.start | localizeDate : { dateStyle: "short", timeStyle: "short" }) }
                }}</span>
                <span *ngIf="!vm.task.timeRange?.start && vm.task.timeRange?.stop">{{
                    "sahSharedLibIncident.tasks.timeRangeStopValueLabel"
                        | transloco : { stop: (vm.task.timeRange?.stop | localizeDate : { dateStyle: "short", timeStyle: "short" }) }
                }}</span>
                <span *ngIf="!vm.task.timeRange?.start && !vm.task.timeRange?.stop">{{
                    "sahSharedLibIncident.tasks.timeRangeNoValueLabel" | transloco
                }}</span>
            </dtm-ui-label-value>
            <dtm-ui-label-value [label]="'sahSharedLibIncident.tasks.altitudeRangeLabel' | transloco">
                {{
                    "sahSharedLibIncident.tasks.altitudeRangeValueLabel"
                        | transloco
                            : {
                                  floor: vm.task.altitudeRange.floor,
                                  ceiling: vm.task.altitudeRange.ceiling,
                                  suffix: ("sahSharedLibIncident.tasks.altitudeSuffix" | transloco)
                              }
                }}
            </dtm-ui-label-value>
        </div>
    </div>
</ng-container>
