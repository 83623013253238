<section
    *ngrxLet="{
        areToolsEnabled: areToolsEnabled$,
        customArea: customArea$,
        selectedMapTool: selectedMapTool$
    } as vm"
    class="map-panel"
>
    <div *ngIf="vm.areToolsEnabled" @slideIn class="map-toolbar">
        <h2>{{ "sahClientLibIncidentCreator.incidentCreator.mapPanel.header" | transloco }}</h2>

        <div class="map-tools-container">
            <button type="button" [matMenuTriggerFor]="drawMenu" [disabled]="vm.customArea" [class.active]="!vm.customArea">
                <div>
                    <dtm-ui-icon name="pen-nib"></dtm-ui-icon>
                    <dtm-ui-icon class="sub-icon" [name]="vm.selectedMapTool?.icon"></dtm-ui-icon>
                    <dtm-ui-icon name="arrow-down"></dtm-ui-icon>
                </div>
                {{ "sahClientLibIncidentCreator.incidentCreator.mapPanel.mapToolbarDrawLabel" | transloco }}
            </button>
            <button type="button" [disabled]="!vm.customArea" (click)="removeIncidentArea()">
                <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                {{ "sahClientLibIncidentCreator.incidentCreator.mapPanel.mapToolbarRemoveAreaLabel" | transloco }}
            </button>
        </div>

        <mat-menu #drawMenu="matMenu" xPosition="after" yPosition="below">
            <div class="map-toolbar-menu">
                <button
                    type="button"
                    [class.active]="vm.selectedMapTool?.name === CIRCLE_MAP_TOOL.name"
                    (click)="drawingToolSelect.emit(CIRCLE_MAP_TOOL)"
                >
                    <dtm-ui-icon [name]="CIRCLE_MAP_TOOL.icon"></dtm-ui-icon>
                    {{ "sahClientLibIncidentCreator.incidentCreator.mapPanel.mapToolbarCircleLabel" | transloco }}
                </button>
                <button
                    type="button"
                    [class.active]="vm.selectedMapTool?.name === POLYGON_MAP_TOOL.name"
                    (click)="drawingToolSelect.emit(POLYGON_MAP_TOOL)"
                >
                    <dtm-ui-icon [name]="POLYGON_MAP_TOOL.icon"></dtm-ui-icon>
                    {{ "sahClientLibIncidentCreator.incidentCreator.mapPanel.mapToolbarPolygonLabel" | transloco }}
                </button>
            </div>
        </mat-menu>
    </div>

    <div class="map-container">
        <div toastContainer class="map-tools-toast-container" aria-live="polite"></div>
        <dtm-map-leaflet-map>
            <sah-client-lib-draw-areas
                [selectedToolName]="vm.selectedMapTool?.name"
                [isModeEnabled]="vm.areToolsEnabled && !vm.customArea"
                [isDrawingHelpHidden]="isDrawingHelpHidden$ | ngrxPush"
                (areaCreate)="areaCreate.emit($event)"
            ></sah-client-lib-draw-areas>
            <sah-client-lib-edit-areas
                [isModeEnabled]="vm.areToolsEnabled && !!vm.customArea"
                [selectedArea]="vm.customArea"
                [isContextMenuEnabled]="false"
            ></sah-client-lib-edit-areas>
        </dtm-map-leaflet-map>
    </div>
</section>
