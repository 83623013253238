<ng-container *ngrxLet="{ isExpanded: isExpanded$, viewType: viewType$, previewedArea: previewedArea$ } as vm">
    <sah-client-lib-incident-side-panel @slideInSideOffScreenRight *ngIf="vm.isExpanded">
        <div header class="header">
            <ng-container *ngIf="vm.viewType[SidePanelViewType.AreaList]">
                <button type="button" class="button-icon" (click)="closePanel()">
                    <dtm-ui-icon name="close"></dtm-ui-icon>
                </button>
                <span class="title">{{ "sahClientLibIncident.areaSidePanel.areaListHeader" | transloco }}</span>
            </ng-container>
            <ng-container *ngIf="vm.viewType[SidePanelViewType.AreaPreview]">
                <button type="button" class="button-icon" (click)="changeViewType(SidePanelViewType.AreaList)">
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                </button>
                <ng-container *ngTemplateOutlet="editAreaHeaderTemplate"></ng-container>
            </ng-container>
            <ng-container *ngIf="vm.viewType[SidePanelViewType.AreaAssignTask]">
                <button type="button" class="button-icon" (click)="changeViewType(SidePanelViewType.AreaPreview)">
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                </button>
                <ng-container *ngTemplateOutlet="editAreaHeaderTemplate"></ng-container>
            </ng-container>
        </div>

        <ng-container *ngIf="vm.viewType[SidePanelViewType.AreaList]">
            <sah-client-lib-area-list
                [areas]="areas$ | ngrxPush"
                (areaSelect)="areaService.selectArea($event, true)"
                (areaPreview)="previewArea($event)"
                (areaRemove)="areaService.removeArea($event)"
            ></sah-client-lib-area-list>
        </ng-container>
        <ng-container *ngIf="vm.viewType[SidePanelViewType.AreaPreview]">
            <sah-client-lib-area-assigned-tasks-list
                [assignedTasks]="previewedAreaTasks$ | ngrxPush"
                [teams]="teams$ | ngrxPush"
                [taskStatusPool]="taskStatusPool$ | ngrxPush"
                (assignedTaskRemove)="areaService.detachAreaFromTask(vm.previewedArea, $event)"
                (newTaskAssign)="changeViewType(SidePanelViewType.AreaAssignTask)"
                (taskAreaSelect)="areaService.selectAreaById($event, true)"
                (teamAttach)="taskService.attachTeamToTask($event)"
                (teamDetach)="taskService.detachTeamFromTask($event)"
                (statusChange)="taskService.changeTaskStatus($event)"
                (pilotTaskAccept)="taskService.acceptPilotTask($event)"
                (pilotTaskReject)="taskService.rejectPilotTask($event)"
            ></sah-client-lib-area-assigned-tasks-list>
        </ng-container>
        <ng-container *ngIf="vm.viewType[SidePanelViewType.AreaAssignTask]">
            <sah-client-lib-area-task-select
                [tasks]="notAssignedTasks$ | ngrxPush"
                (taskSelect)="areaService.assignedNewTaskToArea($event, vm.previewedArea)"
            ></sah-client-lib-area-task-select>
        </ng-container>

        <ng-template #editAreaHeaderTemplate>
            <span class="title">
                {{ vm.previewedArea?.data?.name }}
            </span>
            <button type="button" class="button-icon" (click)="areaService.selectArea(vm.previewedArea, true)">
                <dtm-ui-icon name="road-map"></dtm-ui-icon>
            </button>
            <button
                type="button"
                class="button-icon"
                [matMenuTriggerFor]="areaActionsMenu"
                [matMenuTriggerData]="{ $implicit: vm.previewedArea }"
            >
                <dtm-ui-icon name="more"></dtm-ui-icon>
            </button>
        </ng-template>
    </sah-client-lib-incident-side-panel>

    <mat-menu #areaActionsMenu="matMenu">
        <ng-template matMenuContent let-area>
            <button mat-menu-item type="button" (click)="areaService.updateAreaName(area)">
                <dtm-ui-icon name="edit"></dtm-ui-icon>
                <span>{{ "sahClientLibIncident.areaSidePanel.changeAreaNameLabel" | transloco }}</span>
            </button>
        </ng-template>
    </mat-menu>
</ng-container>
