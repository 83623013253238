import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { Page } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { map } from "rxjs";
import { IncidentListAction, IncidentListActionType, IncidentListItem } from "../../models/incident.models";

export interface IncidentListComponentState {
    isProcessing: boolean;
    hasGetListErrorOccurred: boolean;
    incidents: IncidentListItem[];
    incidentsPage: Page | undefined;
    actions: IncidentListAction[];
}

@Component({
    selector: "sah-shared-lib-incident-list[isProcessing][hasGetListErrorOccurred][incidents][incidentsPage]",
    templateUrl: "./incident-list.component.html",
    styleUrls: ["./incident-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class IncidentListComponent {
    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set hasGetListErrorOccurred(value: BooleanInput) {
        this.localStore.patchState({ hasGetListErrorOccurred: coerceBooleanProperty(value) });
    }

    @Input() public set incidents(value: IncidentListItem[] | undefined) {
        this.localStore.patchState({ incidents: value ?? [] });
    }

    @Input() public set incidentsPage(value: Page | undefined) {
        this.localStore.patchState({ incidentsPage: value });
    }

    @Input() public set actions(value: IncidentListAction[] | undefined) {
        this.localStore.patchState({ actions: value ?? [] });
    }

    @Output() protected readonly pageChange = new EventEmitter<PageEvent>();
    @Output() protected readonly pageReload = new EventEmitter();

    protected readonly IncidentListActionType = IncidentListActionType;
    protected readonly DEFAULT_COLUMNS: Readonly<Array<keyof IncidentListItem>> = ["categories", "name", "leadInstitution"];

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasGetListErrorOccurred$ = this.localStore.selectByKey("hasGetListErrorOccurred");
    protected readonly incidents$ = this.localStore.selectByKey("incidents");
    protected readonly incidentsPage$ = this.localStore.selectByKey("incidentsPage");
    protected readonly actions$ = this.localStore.selectByKey("actions");

    protected readonly displayedTableColumns$ = this.actions$.pipe(
        map((actions) => (actions.length ? [...this.DEFAULT_COLUMNS, "actions"] : this.DEFAULT_COLUMNS))
    );

    constructor(private readonly localStore: LocalComponentStore<IncidentListComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            hasGetListErrorOccurred: false,
            incidents: [],
            incidentsPage: undefined,
            actions: [],
        });
    }
}
