import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Task, TaskCreatorFormValues } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { DateUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";

interface TaskCreatorComponentState {
    isProcessing: boolean;
}

const DEFAULT_START_TIME_ADD_MINUTES = 5;
const DEFAULT_END_TIME_ADD_MINUTES = 35;

@UntilDestroy()
@Component({
    selector: "sah-client-lib-task-creator",
    templateUrl: "task-creator.component.html",
    styleUrls: ["task-creator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TaskCreatorComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    @Input() public set task(value: Task | undefined) {
        if (!value) {
            return;
        }

        this.taskCreatorForm.patchValue({
            name: value.name,
            startDate: value.timeRange?.start ?? null,
            startTime: value.timeRange?.start ?? null,
            endDate: value.timeRange?.stop ?? null,
            endTime: value.timeRange?.stop ?? null,
            floorAltitude: value.altitudeRange.floor,
            ceilingAltitude: value.altitudeRange.ceiling,
            areDetailsRequired: !!value.details,
            details: value.details,
        });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set isDefaultDateSet(value: BooleanInput) {
        if (coerceBooleanProperty(value)) {
            this.taskCreatorForm.patchValue({
                startDate: this.MIN_DATE,
                startTime: DateUtils.addMinutes(this.MIN_DATE, DEFAULT_START_TIME_ADD_MINUTES),
                endDate: this.MIN_DATE,
                endTime: DateUtils.addMinutes(this.MIN_DATE, DEFAULT_END_TIME_ADD_MINUTES),
            });
        }
    }

    @Output() protected readonly taskSubmit = new EventEmitter<TaskCreatorFormValues>();
    @Output() protected readonly cancel = new EventEmitter<void>();

    protected readonly MIN_DATE = new Date();
    protected readonly taskCreatorForm = new FormControl<Partial<TaskCreatorFormValues> | null>(null);

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");

    constructor(private readonly localStore: LocalComponentStore<TaskCreatorComponentState>) {
        this.localStore.setState({
            isProcessing: false,
        });
    }

    protected submit(): void {
        if (this.taskCreatorForm.invalid) {
            this.taskCreatorForm.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.taskSubmit.emit(this.taskCreatorForm.value as TaskCreatorFormValues);
    }
}
