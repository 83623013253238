<ng-container *ngIf="selectedTool$ | ngrxPush as selectedTool">
    <svg width="36" height="44" viewBox="0 0 36 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            id="Vector"
            d="M30.728 30.728L18 43.4558L5.27208 30.728C-1.75736 23.6984 -1.75736 12.3015 5.27208 5.27208C12.3015 -1.75736 23.6984 -1.75736 30.728 5.27208C37.7574 12.3015 37.7574 23.6984 30.728 30.728Z"
            fill="#FFCA28"
        />
    </svg>

    <div *ngIf="hasPhoto$ | ngrxPush" class="photo">
        <dtm-ui-icon name="camera"></dtm-ui-icon>
    </div>

    <div class="container">
        <dtm-ui-icon *ngIf="selectedTool.icon" [name]="selectedTool.icon"></dtm-ui-icon>
        <div *ngIf="selectedTool.letterIcon" class="letter-icon">{{ selectedTool.letterIcon }}</div>
    </div>
</ng-container>
