import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { IncidentList, IncidentStatus } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { MIN_PAGE_SIZE_VALUE } from "@dtm-frontend/shared/ui";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { Observable, catchError, map, throwError } from "rxjs";
import { DASHBOARD_ENDPOINTS, DashboardEndpoints } from "../dashboard.tokens";
import { DashboardErrorType } from "../models/dasboard.models";
import {
    GetIncidentsResponseBody,
    GetIncidentsScope,
    convertActivateIncidentErrorResponseBodyToDashboardIncidentActivationError,
    convertGetIncidentsResponseBodyToIncidentList,
} from "./dashboard-api.converters";

@Injectable()
export class DashboardApiService {
    constructor(@Inject(DASHBOARD_ENDPOINTS) private readonly endpoints: DashboardEndpoints, private readonly http: HttpClient) {}

    public getMyActiveIncidents(page?: PageEvent): Observable<IncidentList> {
        return this.http
            .get<GetIncidentsResponseBody>(this.endpoints.getIncidents, {
                params: {
                    scope: GetIncidentsScope.Coordinator,
                    status: IncidentStatus.Active,
                    page: page?.pageIndex ?? 0,
                    size: page?.pageSize ?? MIN_PAGE_SIZE_VALUE,
                },
            })
            .pipe(
                map((incidents) => convertGetIncidentsResponseBodyToIncidentList(incidents)),
                catchError(() => throwError(() => ({ type: DashboardErrorType.Unknown })))
            );
    }

    public getMyPlannedIncidents(page?: PageEvent): Observable<IncidentList> {
        return this.http
            .get<GetIncidentsResponseBody>(this.endpoints.getIncidents, {
                params: {
                    scope: GetIncidentsScope.Coordinator,
                    status: IncidentStatus.Planned,
                    page: page?.pageIndex ?? 0,
                    size: page?.pageSize ?? MIN_PAGE_SIZE_VALUE,
                },
            })
            .pipe(
                map((incidents) => convertGetIncidentsResponseBodyToIncidentList(incidents)),
                catchError(() => throwError(() => ({ type: DashboardErrorType.Unknown })))
            );
    }

    public getMyCompletedIncidents(page?: PageEvent): Observable<IncidentList> {
        return this.http
            .get<GetIncidentsResponseBody>(this.endpoints.getIncidents, {
                params: {
                    scope: GetIncidentsScope.Coordinator,
                    status: IncidentStatus.Completed,
                    page: page?.pageIndex ?? 0,
                    size: page?.pageSize ?? MIN_PAGE_SIZE_VALUE,
                },
            })
            .pipe(
                map((incidents) => convertGetIncidentsResponseBodyToIncidentList(incidents)),
                catchError(() => throwError(() => ({ type: DashboardErrorType.Unknown })))
            );
    }

    public activateIncident(incidentId: string): Observable<void> {
        return this.http
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.activateIncident, { incidentId }), null)
            .pipe(
                catchError((error) => throwError(() => convertActivateIncidentErrorResponseBodyToDashboardIncidentActivationError(error)))
            );
    }

    public completeIncident(incidentId: string): Observable<void> {
        return this.http
            .put<void>(StringUtils.replaceInTemplate(this.endpoints.completeIncident, { incidentId }), null)
            .pipe(catchError(() => throwError(() => ({ type: DashboardErrorType.Unknown }))));
    }

    public downloadIncidentReport(incidentId: string): Observable<Blob> {
        return this.http.get(StringUtils.replaceInTemplate(this.endpoints.downloadIncidentReport, { incidentId }), {
            responseType: "blob",
        });
    }
}
