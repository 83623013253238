<ng-container *ngrxLet="isProcessing$ as isProcessing">
    <div dtmUiInvalidFormScrollable class="panel-content scroll-shadows">
        <h2>{{ "sahClientLibIncidentCreator.incidentCreator.teamsPanel.header" | transloco }}</h2>

        <sah-client-lib-teams-creator
            dtmUiMarkValueAccessorControlsAsTouched
            [isProcessing]="isProcessing"
            [formControl]="teamsControl"
            [accessoryOptions]="defaultAccessoriesOptions$ | ngrxPush"
            [operators]="operators$ | ngrxPush"
            [operatorPilots]="operatorPilots$ | ngrxPush"
            (operatorChange)="onOperatorChange($event)"
            (pilotChange)="onPilotChange($event)"
        ></sah-client-lib-teams-creator>
    </div>

    <div class="panel-actions">
        <button type="button" class="button-secondary skip-button" [disabled]="isProcessing" (click)="stepSkip.emit()">
            {{ "sahClientLibIncidentCreator.incidentCreator.teamsPanel.skipButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" [disabled]="isProcessing" (click)="submit()">
            <mat-spinner *ngIf="isProcessing" diameter="20" color="accent"></mat-spinner>
            {{ "sahClientLibIncidentCreator.incidentCreator.teamsPanel.submitButtonLabel" | transloco }}
        </button>
    </div>
</ng-container>
