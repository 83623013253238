import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { MapArea } from "../../../models/incident-map.models";
import { Task } from "../../../models/task.models";

interface TaskPopupContentComponentState {
    task: Task | undefined;
    area: MapArea | undefined;
}

@Component({
    templateUrl: "task-popup-content.component.html",
    styleUrls: ["task-popup-content.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TaskPopupContentComponent {
    @Input() public set area(value: MapArea | undefined) {
        this.localStore.patchState({ area: value });
    }
    @Input() public set task(value: Task | undefined) {
        this.localStore.patchState({ task: value });
    }

    protected readonly area$ = this.localStore.selectByKey("area");
    protected readonly task$ = this.localStore.selectByKey("task");

    constructor(private readonly localStore: LocalComponentStore<TaskPopupContentComponentState>) {
        this.localStore.setState({ area: undefined, task: undefined });
    }
}
