import { METERS_IN_KILOMETER } from "@dtm-frontend/shared/utils";
import turfCircle from "@turf/circle";
import { Circle, GeoJSON } from "leaflet";
import { MapArea } from "../models/incident-map.models";

export function convertMapAreaToPolygon(mapArea: MapArea): GeoJSON.Polygon {
    if (mapArea instanceof Circle) {
        return turfCircle(mapArea.toGeoJSON(), (mapArea.getRadius() ?? 0) / METERS_IN_KILOMETER).geometry;
    }

    return mapArea.toGeoJSON().geometry as GeoJSON.Polygon;
}
