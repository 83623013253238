<div *ngrxLet="messages$ as messages" class="message-container scroll-shadows" [class.empty-list]="!messages.length">
    <ng-container *ngIf="messages.length; else emptyListTemplate">
        <div #messageElement *ngFor="let message of messages" class="message" [class.my-message]="message.isMine">
            <div class="message-header">
                <span *ngIf="!message.isMine" class="message-author">{{ message.createdBy }}</span>
                <span class="message-date">{{ message.createdAt | localizeDate : { dateStyle: "short" } }}</span>
                <span>{{ message.createdAt | localizeDate : { timeStyle: "short" } }}</span>
            </div>
            <div class="message-content">
                {{ message.content }}
            </div>
        </div>
    </ng-container>
    <ng-template #emptyListTemplate>
        <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary">
            <img imageSlot src="assets/images/no-results.svg" alt="" />
            <ng-container messageSlot>
                {{ "sahSharedLibIncident.chat.emptyListMessage" | transloco }}
            </ng-container>
        </dtm-ui-empty-state>
    </ng-template>
</div>
<div class="actions">
    <dtm-ui-loader-container [isShown]="isProcessing$ | ngrxPush">
        <dtm-ui-textarea-field [maxLength]="MESSAGE_MAX_LENGTH">
            <div>
                <textarea
                    matInput
                    cdkTextareaAutosize
                    [cdkAutosizeMinRows]="MESSAGE_INPUT_MIN_ROWS"
                    [cdkAutosizeMaxRows]="MESSAGE_INPUT_MAX_ROWS"
                    [formControl]="messageControl"
                ></textarea>
            </div>
            <div class="field-error" *dtmUiFieldHasError="messageControl; name: 'maxlength'; error as error">
                {{ "sahSharedLibIncident.chat.messageMaxLengthError" | transloco : { maxLength: error.requiredLength } }}
            </div>
        </dtm-ui-textarea-field>
    </dtm-ui-loader-container>
    <button type="button" class="button-primary" [disabled]="isMessageInputEmpty$ | ngrxPush" (click)="sendMessage()">
        {{ "sahSharedLibIncident.chat.sendMessageButtonLabel" | transloco }}
    </button>
</div>
