<ng-container *ngrxLet="{ statuses: statuses$, currentStatus: status$, isPreview: isPreview$ } as vm">
    <ng-container *ngIf="vm.currentStatus">
        <sah-shared-lib-task-status-badge
            *ngIf="vm.statuses?.length && !vm.isPreview; else plainStatusBadgeTemplate"
            [status]="vm.currentStatus"
            [matMenuTriggerFor]="statusChangeMenu"
            [matMenuTriggerData]="{ $implicit: vm.currentStatus }"
        ></sah-shared-lib-task-status-badge>

        <mat-menu #statusChangeMenu="matMenu">
            <ng-template matMenuContent let-currentStatus>
                <ng-container *ngFor="let status of vm.statuses">
                    <button
                        *ngIf="currentStatus !== status"
                        type="button"
                        mat-menu-item
                        class="badge-button"
                        (click)="openStatusChangeDialog(status)"
                    >
                        <sah-shared-lib-task-status-badge class="menu-items" [status]="status"></sah-shared-lib-task-status-badge>
                    </button>
                </ng-container>
            </ng-template>
        </mat-menu>

        <ng-template #plainStatusBadgeTemplate>
            <sah-shared-lib-task-status-badge class="plain-badge" [status]="vm.currentStatus"></sah-shared-lib-task-status-badge>
        </ng-template>
    </ng-container>
</ng-container>
