<ng-container
    *ngrxLet="{
        page: incidentsPage$,
        hasGetListErrorOccurred: hasGetListErrorOccurred$,
        actions: actions$,
        incidents: incidents$,
        isProcessing: isProcessing$,
        displayedTableColumns: displayedTableColumns$
    } as vm"
>
    <dtm-ui-loader-container [isShown]="vm.isProcessing">
        <dtm-ui-responsive-table [data]="vm.incidents">
            <table desktopTable mat-table class="dtm-table" [dataSource]="vm.incidents">
                <ng-container matColumnDef="categories">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "sahSharedLibIncident.incidentList.categoryLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.categories | incidentCategoryList | ngrxPush }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "sahSharedLibIncident.incidentList.nameLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">
                        {{ element.name ?? "-" }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="leadInstitution">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ "sahSharedLibIncident.incidentList.leadInstitutionLabel" | transloco }}
                    </th>
                    <td mat-cell *matCellDef="let element">{{ element.leadInstitution ?? "-" }}</td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef>{{ "sahSharedLibIncident.incidentList.actionsLabel" | transloco }}</th>
                    <td mat-cell *matCellDef="let element">
                        <button
                            type="button"
                            class="button-icon"
                            [matMenuTriggerFor]="rowActionsMenu"
                            [matMenuTriggerData]="{ $implicit: element }"
                        >
                            <dtm-ui-icon name="more"></dtm-ui-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="noResults">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="vm.displayedTableColumns.length">
                        <ng-container
                            *ngIf="vm.hasGetListErrorOccurred; else emptyListTemplate"
                            [ngTemplateOutlet]="getListErrorTemplate"
                        ></ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="pagination">
                    <td mat-footer-cell *matFooterCellDef [attr.colspan]="vm.displayedTableColumns.length">
                        <ng-container [ngTemplateOutlet]="paginationTemplate"></ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="vm.displayedTableColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: vm.displayedTableColumns"></tr>
                <tr
                    mat-footer-row
                    *matFooterRowDef="['noResults']"
                    class="no-results-row"
                    [class.hide-footer]="vm.incidents.length || vm.isProcessing"
                ></tr>
                <tr mat-footer-row *matFooterRowDef="['pagination']" class="pagination-row" [class.hide-footer]="!vm.incidents.length"></tr>
            </table>

            <div *dtmUiMobileTable="let row">
                <div class="mobile-table-header">
                    <h2>{{ row.name ?? "-" }}</h2>
                    <button
                        *ngIf="vm.actions.length"
                        type="button"
                        class="mobile-table-header-actions button-icon"
                        [matMenuTriggerFor]="rowActionsMenu"
                        [matMenuTriggerData]="{ $implicit: row }"
                    >
                        <dtm-ui-icon name="more"></dtm-ui-icon>
                    </button>
                </div>

                <div class="mobile-table-content">
                    <dtm-ui-label-value
                        [label]="'sahSharedLibIncident.incidentList.categoryLabel' | transloco"
                        [value]="row.categories | incidentCategoryList | ngrxPush"
                    >
                    </dtm-ui-label-value>
                    <dtm-ui-label-value
                        [label]="'sahSharedLibIncident.incidentList.leadInstitutionLabel' | transloco"
                        [value]="row.leadInstitution ?? '-'"
                    >
                    </dtm-ui-label-value>
                </div>
            </div>

            <ng-container mobileTablePagination [ngTemplateOutlet]="paginationTemplate"></ng-container>
            <ng-container
                *ngIf="vm.hasGetListErrorOccurred; else emptyListTemplate"
                mobileTableNoData
                [ngTemplateOutlet]="getListErrorTemplate"
            ></ng-container>
        </dtm-ui-responsive-table>

        <ng-template #paginationTemplate>
            <dtm-ui-pagination *ngIf="vm.page" [pagination]="vm.page" (page)="pageChange.emit($event)"></dtm-ui-pagination>
        </ng-template>

        <ng-template #getListErrorTemplate>
            <dtm-ui-error
                [errorMessage]="'sahSharedLibIncident.incidentList.getListErrorMessage' | transloco"
                (reload)="pageReload.emit()"
            ></dtm-ui-error>
        </ng-template>
        <ng-template #emptyListTemplate>
            <dtm-ui-no-results></dtm-ui-no-results>
        </ng-template>
    </dtm-ui-loader-container>

    <mat-menu #rowActionsMenu="matMenu">
        <ng-template matMenuContent let-element>
            <ng-container *ngFor="let action of vm.actions">
                <button *ngIf="action.type === IncidentListActionType.Button" mat-menu-item type="button" (click)="action.run(element)">
                    <dtm-ui-icon [name]="action.iconName"></dtm-ui-icon>
                    <span>{{ action.labelKey | transloco }}</span>
                </button>
                <a
                    *ngIf="action.type === IncidentListActionType.Link"
                    mat-menu-item
                    [routerLink]="action.link | pathInterpolation : { id: element.id }"
                >
                    <dtm-ui-icon [name]="action.iconName"></dtm-ui-icon>
                    <span>{{ action.labelKey | transloco }}</span>
                </a>
            </ng-container>
        </ng-template>
    </mat-menu>
</ng-container>
