import { animate, AnimationTriggerMetadata, style, transition, trigger } from "@angular/animations";

const ANIMATION_DURATION = 400;

export const MENU_TOGGLE_ANIMATION: AnimationTriggerMetadata[] = [
    trigger("menuToggle", [
        transition(":leave", [
            style({ transform: "translate(0, 0)", opacity: "100%" }),
            animate(`${ANIMATION_DURATION}ms ease-in-out`, style({ transform: "translate(-400px, 0)", opacity: 0 })),
        ]),
        transition(":enter", [
            style({ transform: "translate(-400px, 0)" }),
            animate(`${ANIMATION_DURATION}ms ease-in-out`, style({ transform: "translate(0, 0)" })),
        ]),
    ]),
];
