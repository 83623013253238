<ng-container
    *ngrxLet="{
        tasks: tasks$,
        teams: teams$,
        taskStatusPool: taskStatusPool$,
        isStatusChangeEnabled: isStatusChangeEnabled$,
        isTaskEditEnabled: isTaskEditEnabled$
    } as vm"
>
    <div *ngIf="vm.tasks.length; else emptyListTemplate" class="task-list">
        <sah-shared-lib-task-card
            *ngFor="let task of vm.tasks"
            [availableStatuses]="vm.isStatusChangeEnabled ? (task | invoke : getAvailableStatuses : vm.taskStatusPool) : []"
            [availableTeams]="vm.teams"
            [task]="task"
            [isTeamChangeEnabled]="task.status | invoke : canModifyAssignedTeam"
            (statusChange)="statusChange.emit($event)"
            (teamAttach)="teamAttach.emit($event)"
            (teamDetach)="teamDetach.emit($event)"
        >
            <ng-container actions>
                <ng-container *ngIf="task.status === TaskStatus.PendingAcceptance; else defaultActionsTemplate">
                    <button type="button" class="button-icon" (click)="pilotTaskAccept.emit(task)">
                        <dtm-ui-icon name="check"></dtm-ui-icon>
                    </button>
                    <button type="button" class="button-icon" (click)="pilotTaskReject.emit(task)">
                        <dtm-ui-icon name="close"></dtm-ui-icon>
                    </button>
                </ng-container>

                <ng-template #defaultActionsTemplate>
                    <button *ngIf="task.areaId" type="button" class="button-icon" (click)="taskAreaSelect.emit(task.areaId)">
                        <dtm-ui-icon name="road-map"></dtm-ui-icon>
                    </button>
                    <button
                        *ngIf="vm.isTaskEditEnabled && (task.status | invoke : doesTaskStatusAllowEdit)"
                        type="button"
                        class="button-icon"
                        (click)="taskEdit.emit(task)"
                    >
                        <dtm-ui-icon name="edit"></dtm-ui-icon>
                    </button>
                    <button
                        *ngIf="task.status | invoke : doesTaskStatusAllowRemoval"
                        type="button"
                        class="button-icon"
                        (click)="taskRemove.emit(task)"
                    >
                        <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                    </button>
                </ng-template>
            </ng-container>
        </sah-shared-lib-task-card>
    </div>

    <ng-template #emptyListTemplate>
        <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary">
            <img imageSlot src="assets/images/no-results.svg" alt="" />
            <ng-container messageSlot>
                {{ "sahClientLibIncident.areaSidePanel.emptyTaskListMessage" | transloco }}
            </ng-container>
        </dtm-ui-empty-state>
    </ng-template>
</ng-container>
