import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ActionMarkerMapTool } from "../../../models/incident-map.models";

interface ActionMarkerComponentState {
    selectedTool: ActionMarkerMapTool | undefined;
    hasPhoto: boolean;
}

@Component({
    selector: "sah-shared-lib-action-marker",
    templateUrl: "action-marker.component.html",
    styleUrls: ["action-marker.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ActionMarkerComponent {
    @Input() public set selectedTool(value: ActionMarkerMapTool | undefined) {
        this.localStore.patchState({ selectedTool: value });
    }

    @Input() public set hasPhoto(value: BooleanInput) {
        this.localStore.patchState({ hasPhoto: coerceBooleanProperty(value) });
    }

    protected readonly selectedTool$ = this.localStore.selectByKey("selectedTool");
    protected readonly hasPhoto$ = this.localStore.selectByKey("hasPhoto");

    constructor(private readonly localStore: LocalComponentStore<ActionMarkerComponentState>) {
        this.localStore.setState({ selectedTool: undefined, hasPhoto: false });
    }
}
