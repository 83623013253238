<form *ngrxLet="isDisabled$ as isDisabled" autocomplete="off" [formGroup]="teamCreatorForm">
    <section>
        <div class="section-header">
            {{ "sahClientLibShared.teamCreator.teamHeader" | transloco }}
            <ng-content select="[actionsPanel]"></ng-content>
        </div>

        <div *ngrxLet="{ isProcessing: isProcessing$, operators: operators$, pilots: pilots$ } as vm" class="section-controls">
            <dtm-ui-input-field>
                <label for="nameControl">{{ "sahClientLibShared.teamCreator.teamNameLabel" | transloco }}</label>
                <input matInput id="nameControl" type="text" [formControl]="teamCreatorForm.controls.name" />
                <div class="field-error" *dtmUiFieldHasError="teamCreatorForm.controls.name; name: ['required', 'pattern']">
                    {{ "sahClientLibShared.teamCreator.fieldRequiredError" | transloco }}
                </div>
                <div class="field-error" *dtmUiFieldHasError="teamCreatorForm.controls.name; name: 'maxlength'; error as error">
                    {{ "sahClientLibShared.teamCreator.fieldMaxLengthError" | transloco : { maxLength: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>

            <dtm-ui-input-field *ngIf="!teamCreatorForm.controls.operator.value">
                <label for="pilotNameControl">{{ "sahClientLibShared.teamCreator.pilotNameLabel" | transloco }}</label>
                <input matInput id="pilotNameControl" type="text" [formControl]="teamCreatorForm.controls.pilotName" />
                <div class="field-error" *dtmUiFieldHasError="teamCreatorForm.controls.pilotName; name: 'maxlength'; error as error">
                    {{ "sahClientLibShared.teamCreator.fieldMaxLengthError" | transloco : { maxLength: error.requiredLength } }}
                </div>
            </dtm-ui-input-field>

            <div *ngIf="!teamCreatorForm.controls.operator.value" class="or-divider">
                {{ "sahClientLibShared.teamCreator.orLabel" | transloco }}
            </div>

            <dtm-ui-select-field
                *ngIf="vm.operators?.length; else noOperatorsTemplate"
                [formControl]="teamCreatorForm.controls.operator"
                [compareWith]="areOperatorsEqual"
            >
                <label>{{ "sahClientLibShared.teamCreator.operatorLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let operator of vm.operators" [value]="operator">
                    {{ operator.name }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>
            <ng-template #noOperatorsTemplate>
                <dtm-ui-info-message *ngIf="!vm.isProcessing">{{
                    "sahClientLibShared.teamCreator.noOperatorsMessage" | transloco
                }}</dtm-ui-info-message>
            </ng-template>

            <ng-container *ngIf="teamCreatorForm.controls.operator.value">
                <dtm-ui-select-field
                    *ngIf="vm.pilots?.length; else noPilotsTemplate"
                    [formControl]="teamCreatorForm.controls.pilot"
                    [compareWith]="arePilotsEqual"
                >
                    <label>{{ "sahClientLibShared.teamCreator.pilotLabel" | transloco }}</label>
                    <dtm-ui-select-option *ngFor="let pilot of vm.pilots" [value]="pilot">
                        {{ pilot.firstName + " " + pilot.lastName }}
                    </dtm-ui-select-option>
                </dtm-ui-select-field>

                <ng-template #noPilotsTemplate>
                    <dtm-ui-info-message *ngIf="!vm.isProcessing">{{
                        "sahClientLibShared.teamCreator.noPilotsMessage" | transloco
                    }}</dtm-ui-info-message>
                </ng-template>
            </ng-container>
        </div>
    </section>

    <section *ngrxLet="{ accessoryOptions: accessoryOptions$ } as vm">
        <div class="section-header">{{ "sahClientLibShared.teamCreator.uavHeader" | transloco }}</div>

        <div
            *ngFor="let uavControl of teamCreatorForm.controls.uavs.controls; index as uavIndex"
            class="section-controls uav-container"
            [formGroup]="uavControl"
        >
            <div class="uav-first-row">
                <dtm-ui-input-field *ngIf="uavControl.controls.name as nameControl">
                    <label for="uavNameControl">{{ "sahClientLibShared.teamCreator.uavNameLabel" | transloco }}</label>
                    <input matInput id="uavNameControl" type="text" [formControl]="nameControl" />
                    <div class="field-error" *dtmUiFieldHasError="nameControl; name: ['required', 'pattern']">
                        {{ "sahClientLibShared.teamCreator.fieldRequiredError" | transloco }}
                    </div>
                    <div class="field-error" *dtmUiFieldHasError="nameControl; name: 'maxlength'; error as error">
                        {{ "sahClientLibShared.teamCreator.fieldMaxLengthError" | transloco : { maxLength: error.requiredLength } }}
                    </div>
                </dtm-ui-input-field>

                <button
                    type="button"
                    class="button-icon action-button"
                    [matTooltip]="'sahClientLibShared.teamCreator.removeUavButtonTooltipLabel' | transloco"
                    [matTooltipDisabled]="isDisabled"
                    [disabled]="isDisabled"
                    (click)="removeUav(uavControl, uavIndex)"
                >
                    <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
                </button>
            </div>

            <dtm-ui-select-field *ngIf="uavControl.controls.type as typeControl" [formControl]="typeControl">
                <label>{{ "sahClientLibShared.teamCreator.uavTypeLabel" | transloco }}</label>
                <dtm-ui-select-option *ngFor="let type of UAV_TYPES" [value]="type">
                    {{ "sahClientLibShared.teamCreator.uavTypeValueLabel" | transloco : { value: type } }}
                </dtm-ui-select-option>
            </dtm-ui-select-field>

            <dtm-ui-chips-field
                *ngIf="uavControl.controls.equipment as equipmentControl"
                [formControl]="equipmentControl"
                [options]="vm.accessoryOptions"
            >
                <label>{{ "sahClientLibShared.teamCreator.uavAccessoriesLabel" | transloco }}</label>
                <div class="field-hint">{{ "sahClientLibShared.teamCreator.uavAccessoriesHint" | transloco }}</div>
            </dtm-ui-chips-field>
        </div>
    </section>

    <button type="button" class="button-secondary action-button" [disabled]="isDisabled" (click)="addUav()">
        <dtm-ui-icon name="add"></dtm-ui-icon>
        {{ "sahClientLibShared.teamCreator.addNextUavButtonLabel" | transloco }}
    </button>
</form>
