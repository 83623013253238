import { Injectable } from "@angular/core";
import { UploadedFile as UploadedFileBody } from "@dtm-frontend/shared/utils";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { EMPTY, Observable, Subscription, catchError, finalize, of, switchMap, tap } from "rxjs";
import { IncidentEvent, IncidentMessage, IncidentSummary, SahMapUtils, TaskAssignmentUpdateType, TeamRemovedEventBody } from "../index";
import { ActionMarkerData, HandDrawingPolyline, MapArea, MapAreaData } from "../models/incident-map.models";
import {
    AreaRemovedEventBody,
    AreaTaskAssignmentUpdatedEventBody,
    IncidentError,
    IncidentStatus,
    TaskRemovedEventBody,
    TeamTaskAssignmentUpdatedEventBody,
} from "../models/incident.models";
import { Task } from "../models/task.models";
import { Team } from "../models/team.models";
import { IncidentSharedDataApiService } from "../services/incident-shared-data-api.service";
import { IncidentSharedDataActions } from "./incident-shared-data.actions";

interface IncidentSharedDataStateModel {
    isProcessing: boolean;

    incidentId: string | undefined;
    incidentArea: MapArea | undefined;
    incidentStatus: IncidentStatus | undefined;
    incidentMapDataError: IncidentError | undefined;

    tasks: Task[];

    areas: MapArea[];
    createdArea: MapArea | undefined;
    updatedArea: MapArea | undefined;
    removedAreaId: string | undefined;

    handDrawings: HandDrawingPolyline[];
    createdHandDrawing: HandDrawingPolyline | undefined;
    removedHandDrawingId: string | undefined;

    actionMarkers: Partial<ActionMarkerData>[];
    selectedActionMarkerData: Partial<ActionMarkerData> | undefined;
    isActionMarkerProcessing: boolean;
    createActionMarkerError: IncidentError | undefined;
    updateActionMarkerError: IncidentError | undefined;
    removeActionMarkerError: IncidentError | undefined;
    getActionMarkerPhotoError: IncidentError | undefined;
    addActionMarkerPhotoError: IncidentError | undefined;
    removeActionMarkerPhotoError: IncidentError | undefined;

    teams: Team[];
    team: Team | undefined | null;
    isPilotTaskApprovalRequired: boolean;

    airOperationsCoordinatorId: string | undefined;
    deputyAirOperationsCoordinatorsIds: string[] | undefined;
    flightsDirectorsIds: string[] | undefined;

    messages: IncidentMessage[];
    sendIncidentMessageError: IncidentError | undefined;
}

const DEFAULT_STATE: IncidentSharedDataStateModel = {
    isProcessing: false,

    incidentId: undefined,
    incidentArea: undefined,
    incidentStatus: undefined,
    incidentMapDataError: undefined,

    tasks: [],

    areas: [],
    createdArea: undefined,
    updatedArea: undefined,
    removedAreaId: undefined,

    handDrawings: [],
    createdHandDrawing: undefined,
    removedHandDrawingId: undefined,

    actionMarkers: [],
    selectedActionMarkerData: undefined,
    isActionMarkerProcessing: false,
    createActionMarkerError: undefined,
    updateActionMarkerError: undefined,
    removeActionMarkerError: undefined,
    getActionMarkerPhotoError: undefined,
    addActionMarkerPhotoError: undefined,
    removeActionMarkerPhotoError: undefined,

    teams: [],
    team: undefined,
    isPilotTaskApprovalRequired: false,

    airOperationsCoordinatorId: undefined,
    deputyAirOperationsCoordinatorsIds: undefined,
    flightsDirectorsIds: undefined,

    messages: [],
    sendIncidentMessageError: undefined,
};

@State<IncidentSharedDataStateModel>({
    name: "incidentSharedData",
    defaults: DEFAULT_STATE,
})
@Injectable()
export class IncidentSharedDataState {
    private incidentUpdatesSubscription: Subscription | undefined;

    @Selector()
    public static isProcessing(state: IncidentSharedDataStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static incidentId(state: IncidentSharedDataStateModel): string | undefined {
        return state.incidentId;
    }

    @Selector()
    public static incidentArea(state: IncidentSharedDataStateModel): MapArea | undefined {
        return state.incidentArea;
    }

    @Selector()
    public static incidentStatus(state: IncidentSharedDataStateModel): IncidentStatus | undefined {
        return state.incidentStatus;
    }

    @Selector()
    public static incidentMapDataError(state: IncidentSharedDataStateModel): IncidentError | undefined {
        return state.incidentMapDataError;
    }

    @Selector()
    public static tasks(state: IncidentSharedDataStateModel): Task[] {
        return state.tasks;
    }

    @Selector()
    public static areas(state: IncidentSharedDataStateModel): MapArea[] {
        return state.areas;
    }

    @Selector()
    public static createdArea(state: IncidentSharedDataStateModel): MapArea | undefined {
        return state.createdArea;
    }

    @Selector()
    public static updatedArea(state: IncidentSharedDataStateModel): MapArea | undefined {
        return state.updatedArea;
    }

    @Selector()
    public static removedAreaId(state: IncidentSharedDataStateModel): string | undefined {
        return state.removedAreaId;
    }

    @Selector()
    public static handDrawings(state: IncidentSharedDataStateModel): HandDrawingPolyline[] {
        return state.handDrawings;
    }

    @Selector()
    public static createdHandDrawing(state: IncidentSharedDataStateModel): HandDrawingPolyline | undefined {
        return state.createdHandDrawing;
    }

    @Selector()
    public static removedHandDrawingId(state: IncidentSharedDataStateModel): string | undefined {
        return state.removedHandDrawingId;
    }

    @Selector()
    public static actionMarkers(state: IncidentSharedDataStateModel): Partial<ActionMarkerData>[] {
        return state.actionMarkers;
    }

    @Selector()
    public static selectedActionMarkerData(state: IncidentSharedDataStateModel): Partial<ActionMarkerData> | undefined {
        return state.selectedActionMarkerData;
    }

    @Selector()
    public static isActionMarkerProcessing(state: IncidentSharedDataStateModel): boolean {
        return state.isActionMarkerProcessing;
    }

    @Selector()
    public static createActionMarkerError(state: IncidentSharedDataStateModel): IncidentError | undefined {
        return state.createActionMarkerError;
    }

    @Selector()
    public static updateActionMarkerError(state: IncidentSharedDataStateModel): IncidentError | undefined {
        return state.updateActionMarkerError;
    }

    @Selector()
    public static removeActionMarkerError(state: IncidentSharedDataStateModel): IncidentError | undefined {
        return state.removeActionMarkerError;
    }

    @Selector()
    public static addActionMarkerPhotoError(state: IncidentSharedDataStateModel): IncidentError | undefined {
        return state.addActionMarkerPhotoError;
    }

    @Selector()
    public static removeActionMarkerPhotoError(state: IncidentSharedDataStateModel): IncidentError | undefined {
        return state.removeActionMarkerPhotoError;
    }

    @Selector()
    public static getActionMarkerPhotoError(state: IncidentSharedDataStateModel): IncidentError | undefined {
        return state.getActionMarkerPhotoError;
    }

    @Selector()
    public static teams(state: IncidentSharedDataStateModel): Team[] {
        return state.teams;
    }

    @Selector()
    public static team(state: IncidentSharedDataStateModel): Team | undefined | null {
        return state.team;
    }

    @Selector()
    public static isPilotTaskApprovalRequired(state: IncidentSharedDataStateModel): boolean {
        return state.isPilotTaskApprovalRequired;
    }

    @Selector()
    public static airOperationsCoordinatorId(state: IncidentSharedDataStateModel): string | undefined {
        return state.airOperationsCoordinatorId;
    }

    @Selector()
    public static deputyAirOperationsCoordinatorsIds(state: IncidentSharedDataStateModel): string[] | undefined {
        return state.deputyAirOperationsCoordinatorsIds;
    }

    @Selector()
    public static flightsDirectorsIds(state: IncidentSharedDataStateModel): string[] | undefined {
        return state.flightsDirectorsIds;
    }

    @Selector()
    public static messages(state: IncidentSharedDataStateModel): IncidentMessage[] {
        return state.messages;
    }

    @Selector()
    public static sendIncidentMessageError(state: IncidentSharedDataStateModel): IncidentError | undefined {
        return state.sendIncidentMessageError;
    }

    constructor(private readonly incidentSharedDataApiService: IncidentSharedDataApiService, private readonly store: Store) {}

    @Action(IncidentSharedDataActions.ResetState)
    public resetState(context: StateContext<IncidentSharedDataStateModel>) {
        context.patchState(DEFAULT_STATE);
    }

    @Action(IncidentSharedDataActions.StartIncidentUpdatesWatch)
    public startIncidentUpdatesWatch(
        context: StateContext<IncidentSharedDataStateModel>,
        { incidentId }: IncidentSharedDataActions.StartIncidentUpdatesWatch
    ) {
        if (!incidentId || this.incidentUpdatesSubscription) {
            return;
        }

        this.incidentUpdatesSubscription = this.incidentSharedDataApiService.startIncidentWatch(incidentId).subscribe((message) => {
            if (!message) {
                return;
            }

            const { type, body } = message;
            switch (type) {
                case IncidentEvent.AreaCreated:
                    return this.onAreaCreated(context, body);
                case IncidentEvent.AreaUpdated:
                    return this.onAreaUpdated(context, body);
                case IncidentEvent.AreaRemoved:
                    return this.onAreaRemoved(context, body);
                case IncidentEvent.AreaTaskAssignmentUpdated:
                    return this.onAreaTaskAssignmentUpdate(context, body);
                case IncidentEvent.DoodleCreated:
                    return this.onHandDrawingCreated(context, body);
                case IncidentEvent.DoodleRemoved:
                    return this.onHandDrawingRemoved(context, body);
                case IncidentEvent.IncidentUpdated:
                    return this.onIncidentUpdate(context, body);
                case IncidentEvent.MarkerCreated:
                    return this.onActionMarkerCreated(context, body);
                case IncidentEvent.MarkerUpdated:
                    return this.onActionMarkerUpdated(context, body);
                case IncidentEvent.MarkerRemoved:
                    return this.onActionMarkerRemoved(context, body);
                case IncidentEvent.TaskCreated:
                    return this.onTaskCreated(context, body);
                case IncidentEvent.TaskUpdated:
                    return this.onTaskUpdated(context, body);
                case IncidentEvent.TaskRemoved:
                    return this.onTaskRemoved(context, body);
                case IncidentEvent.TeamCreated:
                    return this.onTeamCreated(context, body);
                case IncidentEvent.TeamRemoved:
                    return this.onTeamRemoved(context, body);
                case IncidentEvent.TeamTaskAssignmentUpdated:
                    return this.onTeamTaskAssignmentUpdate(context, body);
                case IncidentEvent.CommentCreated:
                    return this.onMessageSend(context, body);
            }
        });
    }

    @Action(IncidentSharedDataActions.StopIncidentUpdatesWatch)
    public stopIncidentUpdatesWatch(context: StateContext<IncidentSharedDataStateModel>) {
        this.incidentUpdatesSubscription?.unsubscribe();
        this.incidentUpdatesSubscription = undefined;
        context.patchState({
            createdArea: undefined,
            updatedArea: undefined,
            removedAreaId: undefined,
            createdHandDrawing: undefined,
            removedHandDrawingId: undefined,
        });
    }

    @Action(IncidentSharedDataActions.GetOperationalSituation)
    public getOperationalSituation(
        context: StateContext<IncidentSharedDataStateModel>,
        { incidentId }: IncidentSharedDataActions.GetOperationalSituation
    ) {
        if (!incidentId) {
            return;
        }

        context.patchState({ incidentMapDataError: undefined, isProcessing: true });

        return this.incidentSharedDataApiService.getOperationalSituation(incidentId).pipe(
            tap(
                ({
                    incidentStatus,
                    incidentArea,
                    teams,
                    tasks,
                    areas,
                    handDrawings,
                    actionMarkers,
                    team,
                    isPilotTaskApprovalRequired,
                    messages,
                    airOperationsCoordinatorId,
                    deputyAirOperationsCoordinatorsIds,
                    flightsDirectorsIds,
                }) =>
                    context.patchState({
                        incidentId,
                        incidentStatus,
                        incidentArea,
                        teams: teams ?? [],
                        tasks,
                        areas,
                        handDrawings,
                        actionMarkers,
                        team,
                        isPilotTaskApprovalRequired,
                        messages,
                        airOperationsCoordinatorId,
                        deputyAirOperationsCoordinatorsIds: deputyAirOperationsCoordinatorsIds ?? [],
                        flightsDirectorsIds: flightsDirectorsIds ?? [],
                    })
            ),
            catchError((error) => {
                context.patchState({ incidentMapDataError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentSharedDataActions.SendIncidentMessage)
    public sendIncidentMessage(
        context: StateContext<IncidentSharedDataStateModel>,
        { message }: IncidentSharedDataActions.SendIncidentMessage
    ) {
        const { incidentId } = context.getState();

        if (!incidentId) {
            return;
        }

        context.patchState({ isProcessing: true, sendIncidentMessageError: undefined });

        return this.incidentSharedDataApiService.sendIncidentMessage(incidentId, message).pipe(
            catchError((error) => {
                context.patchState({ sendIncidentMessageError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentSharedDataActions.CreateActionMarker)
    public createActionMarker(
        context: StateContext<IncidentSharedDataStateModel>,
        { markerData }: IncidentSharedDataActions.CreateActionMarker
    ) {
        const { incidentId } = context.getState();
        if (!incidentId || !markerData) {
            return;
        }

        context.patchState({ isActionMarkerProcessing: true, createActionMarkerError: undefined, addActionMarkerPhotoError: undefined });

        return this.incidentSharedDataApiService.createActionMarker(incidentId, markerData).pipe(
            switchMap((response) => {
                if (markerData.photo) {
                    return context.dispatch(new IncidentSharedDataActions.AddActionMarkerPhoto({ ...response, photo: markerData.photo }));
                }

                return of(response);
            }),
            catchError((error) => {
                context.patchState({ createActionMarkerError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionMarkerProcessing: false }))
        );
    }

    @Action(IncidentSharedDataActions.UpdateActionMarker)
    public updateActionMarker(
        context: StateContext<IncidentSharedDataStateModel>,
        { markerData }: IncidentSharedDataActions.UpdateActionMarker
    ) {
        const { incidentId } = context.getState();
        if (!incidentId || !markerData) {
            return;
        }

        context.patchState({
            isActionMarkerProcessing: true,
            updateActionMarkerError: undefined,
            addActionMarkerPhotoError: undefined,
            removeActionMarkerPhotoError: undefined,
        });

        return this.incidentSharedDataApiService.updateActionMarker(incidentId, markerData).pipe(
            switchMap((response) => {
                if (markerData.hasPhotoBeenUpdated && markerData.photo) {
                    return context.dispatch(new IncidentSharedDataActions.AddActionMarkerPhoto(markerData));
                }

                if (markerData.hasPhotoBeenUpdated && !markerData.photo && markerData.photoId) {
                    return context.dispatch(new IncidentSharedDataActions.RemoveActionMarkerPhoto(markerData));
                }

                return of(response);
            }),
            catchError((error) => {
                context.patchState({ updateActionMarkerError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionMarkerProcessing: false }))
        );
    }

    @Action(IncidentSharedDataActions.RemoveActionMarker)
    public removeActionMarker(context: StateContext<IncidentSharedDataStateModel>, action: IncidentSharedDataActions.RemoveActionMarker) {
        const { incidentId } = context.getState();
        if (!incidentId) {
            return;
        }

        context.patchState({ isActionMarkerProcessing: true, removeActionMarkerError: undefined });

        return this.incidentSharedDataApiService.removeActionMarker(incidentId, action.markerId).pipe(
            catchError((error) => {
                context.patchState({ removeActionMarkerError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionMarkerProcessing: false }))
        );
    }

    @Action(IncidentSharedDataActions.AddActionMarkerPhoto)
    public addActionMarkerPhoto(
        context: StateContext<IncidentSharedDataStateModel>,
        { markerData }: IncidentSharedDataActions.AddActionMarkerPhoto
    ): Observable<UploadedFileBody> | undefined {
        const { incidentId } = context.getState();
        if (!incidentId || !markerData?.id || !markerData?.photo) {
            return;
        }

        context.patchState({ isActionMarkerProcessing: true, addActionMarkerPhotoError: undefined });

        return this.incidentSharedDataApiService.addActionMarkerPhoto(incidentId, markerData.id, markerData.photo).pipe(
            catchError((error) => {
                context.patchState({ addActionMarkerPhotoError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionMarkerProcessing: false }))
        );
    }

    @Action(IncidentSharedDataActions.RemoveActionMarkerPhoto)
    public removeActionMarkerPhoto(
        context: StateContext<IncidentSharedDataStateModel>,
        { markerData }: IncidentSharedDataActions.AddActionMarkerPhoto
    ) {
        const { incidentId } = context.getState();
        if (!incidentId || !markerData.id) {
            return;
        }

        context.patchState({ isActionMarkerProcessing: true, removeActionMarkerPhotoError: undefined });

        return this.incidentSharedDataApiService.removeActionMarkerPhoto(incidentId, markerData.id).pipe(
            catchError((error) => {
                context.patchState({ removeActionMarkerPhotoError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionMarkerProcessing: false }))
        );
    }

    @Action(IncidentSharedDataActions.GetActionMarkerPhoto)
    public getActionMarkerPhoto(
        context: StateContext<IncidentSharedDataStateModel>,
        { markerData }: IncidentSharedDataActions.GetActionMarkerPhoto
    ) {
        const { incidentId, actionMarkers } = context.getState();
        if (!incidentId || !markerData?.id) {
            return;
        }

        context.patchState({ isActionMarkerProcessing: true, getActionMarkerPhotoError: undefined });

        return this.incidentSharedDataApiService.getActionMarkerPhoto(incidentId, markerData.id).pipe(
            tap((photo) => {
                context.patchState({
                    actionMarkers: actionMarkers.map((actionMarker) =>
                        actionMarker.id === markerData.id ? { ...markerData, photo } : actionMarker
                    ),
                    selectedActionMarkerData: { ...markerData, photo },
                });
            }),
            catchError((error) => {
                context.patchState({ getActionMarkerPhotoError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isActionMarkerProcessing: false }))
        );
    }

    @Action(IncidentSharedDataActions.SelectActionMarker)
    public selectActionMarker(
        context: StateContext<IncidentSharedDataStateModel>,
        { marker }: IncidentSharedDataActions.SelectActionMarker
    ) {
        context.patchState({ selectedActionMarkerData: marker });
    }

    private onAreaCreated(context: StateContext<IncidentSharedDataStateModel>, createdArea: MapArea) {
        const { areas } = context.getState();

        context.patchState({ areas: [...areas, createdArea], createdArea });
    }

    private onAreaUpdated(context: StateContext<IncidentSharedDataStateModel>, updatedArea: MapArea) {
        const { areas } = context.getState();

        context.patchState({ areas: areas.map((area) => (area.data?.id === updatedArea.data?.id ? updatedArea : area)), updatedArea });
    }

    private onAreaRemoved(context: StateContext<IncidentSharedDataStateModel>, areaRemovedEvent: AreaRemovedEventBody) {
        const { areas, tasks } = context.getState();
        const updatedTasks = tasks.map((task) => {
            const taskUpdate = areaRemovedEvent.taskStatusUpdates.find((updatedTask) => updatedTask.id === task.id);
            if (taskUpdate) {
                return { ...task, status: taskUpdate.status, areaId: undefined };
            }

            return task;
        });

        context.patchState({
            areas: areas.filter((area) => area.data?.id !== areaRemovedEvent.id),
            removedAreaId: areaRemovedEvent.id,
            tasks: updatedTasks,
        });
    }

    private onAreaTaskAssignmentUpdate(
        context: StateContext<IncidentSharedDataStateModel>,
        { areaVersionUpdate, taskStatusUpdate, assignmentType }: AreaTaskAssignmentUpdatedEventBody
    ) {
        context.patchState({ updatedArea: undefined });

        const { areas, tasks } = context.getState();

        const updatedArea = areas.find((area) => area.data?.id === areaVersionUpdate.areaId);
        if (!updatedArea) {
            return;
        }

        const areaToUpdate = SahMapUtils.createMutableMapArea(updatedArea, {
            ...(updatedArea.data as MapAreaData),
            taskIds:
                assignmentType === TaskAssignmentUpdateType.Assign
                    ? [...(updatedArea.data?.taskIds ?? []), taskStatusUpdate.id]
                    : updatedArea.data?.taskIds?.filter((taskId) => taskId !== taskStatusUpdate.id) ?? [],
        });

        context.patchState({
            tasks: tasks.map((task) => {
                if (task.id !== taskStatusUpdate.id) {
                    return task;
                }

                return {
                    ...task,
                    status: taskStatusUpdate.status,
                    areaId: assignmentType === TaskAssignmentUpdateType.Assign ? areaVersionUpdate.areaId : undefined,
                };
            }),
        });
        this.onAreaUpdated(context, areaToUpdate);
    }

    private onHandDrawingCreated(context: StateContext<IncidentSharedDataStateModel>, createdHandDrawing: HandDrawingPolyline) {
        const { handDrawings } = context.getState();

        context.patchState({ handDrawings: [...handDrawings, createdHandDrawing], createdHandDrawing });
    }

    private onHandDrawingRemoved(context: StateContext<IncidentSharedDataStateModel>, removedHandDrawingId: string) {
        const { handDrawings } = context.getState();

        context.patchState({
            handDrawings: handDrawings.filter((handDrawing) => handDrawing.data?.id !== removedHandDrawingId),
            removedHandDrawingId,
        });
    }

    private onActionMarkerCreated(context: StateContext<IncidentSharedDataStateModel>, createdActionMarker: Partial<ActionMarkerData>) {
        const { actionMarkers } = context.getState();

        context.patchState({ actionMarkers: [...actionMarkers, createdActionMarker] });
    }

    private onActionMarkerUpdated(context: StateContext<IncidentSharedDataStateModel>, updatedActionMarker: Partial<ActionMarkerData>) {
        const { actionMarkers, selectedActionMarkerData } = context.getState();

        context.patchState({
            actionMarkers: actionMarkers.map((actionMarker) => {
                if (actionMarker.id !== updatedActionMarker.id) {
                    return actionMarker;
                }

                if (actionMarker.photo && actionMarker.photoId === updatedActionMarker.photoId) {
                    updatedActionMarker.photo = actionMarker.photo;

                    return updatedActionMarker;
                }

                return updatedActionMarker;
            }),
        });

        if (selectedActionMarkerData?.id === updatedActionMarker.id) {
            context.patchState({ selectedActionMarkerData: updatedActionMarker });
        }
    }

    private onActionMarkerRemoved(context: StateContext<IncidentSharedDataStateModel>, removedActionMarkerId: string) {
        const { actionMarkers, selectedActionMarkerData } = context.getState();

        context.patchState({ actionMarkers: actionMarkers.filter((actionMarker) => actionMarker.id !== removedActionMarkerId) });

        if (selectedActionMarkerData?.id === removedActionMarkerId) {
            context.patchState({ selectedActionMarkerData: undefined });
        }
    }

    private onActionMarkerPhotoAdded(context: StateContext<IncidentSharedDataStateModel>, actionMarkerId: string) {
        const { actionMarkers } = context.getState();

        context.patchState({
            actionMarkers: actionMarkers.map((actionMarker) =>
                actionMarker.id === actionMarkerId ? { ...actionMarker, photo: undefined } : actionMarker
            ),
        });
    }

    private onActionMarkerPhotoRemoved(context: StateContext<IncidentSharedDataStateModel>, actionMarkerId: string) {
        const { actionMarkers } = context.getState();

        context.patchState({
            actionMarkers: actionMarkers.map((actionMarker) =>
                actionMarker.id === actionMarkerId ? { ...actionMarker, photo: undefined } : actionMarker
            ),
        });
    }

    private onTaskCreated(context: StateContext<IncidentSharedDataStateModel>, createdTask: Task) {
        const { tasks } = context.getState();

        context.patchState({ tasks: [createdTask, ...tasks] });
    }

    private onTaskUpdated(context: StateContext<IncidentSharedDataStateModel>, updatedTask: Task) {
        context.patchState({ updatedArea: undefined });
        const { areas, tasks } = context.getState();

        context.patchState({ tasks: tasks.map((task) => (task.id === updatedTask.id ? updatedTask : task)) });

        if (!updatedTask.areaId) {
            return;
        }

        const areaToUpdate = areas.find((area) => area.data?.id === updatedTask.areaId);
        context.patchState({ updatedArea: areaToUpdate });
    }

    private onTaskRemoved(context: StateContext<IncidentSharedDataStateModel>, { id, areaVersionUpdated }: TaskRemovedEventBody) {
        context.patchState({ updatedArea: undefined });

        const { areas, tasks } = context.getState();

        context.patchState({ tasks: tasks.filter((task) => task.id !== id) });

        const areaToUpdate = areas.find((area) => area.data?.id === areaVersionUpdated?.areaId);
        if (areaToUpdate) {
            const updatedArea = SahMapUtils.createMutableMapArea(areaToUpdate, {
                ...(areaToUpdate.data as MapAreaData),
                taskIds: areaToUpdate.data?.taskIds?.filter((taskId) => taskId !== id) ?? [],
            });
            this.onAreaUpdated(context, updatedArea);
        }
    }

    private onTeamCreated(context: StateContext<IncidentSharedDataStateModel>, createdTeam: Team) {
        const { teams } = context.getState();

        context.patchState({ teams: [createdTeam, ...(teams ?? [])] });
    }

    private onTeamRemoved(context: StateContext<IncidentSharedDataStateModel>, removedTeamBodyEvent: TeamRemovedEventBody) {
        const { teams, tasks, team } = context.getState();

        if (team && team.id === removedTeamBodyEvent.id) {
            context.patchState({ team: null });
        }

        context.patchState({ teams: teams.filter((existingTeam) => existingTeam.id !== removedTeamBodyEvent.id) });

        tasks.forEach((task) => {
            const matchingTaskStatusUpdate = removedTeamBodyEvent.taskStatusUpdates.find(
                (taskStatusUpdate) => taskStatusUpdate.id === task.id
            );

            if (matchingTaskStatusUpdate) {
                this.onTeamTaskAssignmentUpdate(context, {
                    taskStatusUpdate: matchingTaskStatusUpdate,
                    assignmentType: TaskAssignmentUpdateType.Unassign,
                });
            }
        });
    }

    private onTeamTaskAssignmentUpdate(
        context: StateContext<IncidentSharedDataStateModel>,
        { assignedTeamUpdate, taskStatusUpdate, assignmentType }: TeamTaskAssignmentUpdatedEventBody
    ) {
        context.patchState({ updatedArea: undefined });

        const { tasks } = context.getState();
        const matchingTask = tasks.find((task) => task.id === taskStatusUpdate.id);

        if (!matchingTask) {
            return;
        }

        const updatedTask = {
            ...matchingTask,
            status: taskStatusUpdate.status,
            attachedTeam: assignmentType === TaskAssignmentUpdateType.Assign ? assignedTeamUpdate : undefined,
        };

        this.onTaskUpdated(context, updatedTask);
    }

    private onMessageSend(context: StateContext<IncidentSharedDataStateModel>, message: IncidentMessage) {
        const { messages } = context.getState();
        if (messages.some((existingMessage) => existingMessage.id === message.id)) {
            return;
        }

        context.patchState({ messages: [...messages, message] });
    }

    private onIncidentUpdate(context: StateContext<IncidentSharedDataStateModel>, { incident, incidentArea }: IncidentSummary) {
        context.patchState({
            incidentArea: incidentArea,
            airOperationsCoordinatorId: incident.airOperationsCoordinatorId,
            deputyAirOperationsCoordinatorsIds: incident.deputyAirOperationsCoordinatorsIds,
            flightsDirectorsIds: incident.flightsDirectorsIds,
        });
        context.dispatch(new IncidentSharedDataActions.UpdateIncidentAreaOnMap(incidentArea));
    }
}
