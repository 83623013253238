import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { HandDrawingPolyline } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface HandDrawingMenuComponentState {
    handDrawing: HandDrawingPolyline | undefined;
}

@Component({
    selector: "sah-client-lib-hand-drawing-menu[handDrawing]",
    templateUrl: "hand-drawing-menu.component.html",
    styleUrls: ["hand-drawing-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class HandDrawingMenuComponent {
    @Input() public set handDrawing(value: HandDrawingPolyline | undefined) {
        this.localStore.patchState({ handDrawing: value });
    }

    @Output() public readonly drawingRemove = new EventEmitter<HandDrawingPolyline>();

    protected readonly handDrawing$ = this.localStore.selectByKey("handDrawing");

    constructor(private readonly localStore: LocalComponentStore<HandDrawingMenuComponentState>) {
        this.localStore.setState({ handDrawing: undefined });
    }
}
