import { ChangeDetectionStrategy, Component } from "@angular/core";
import { SahAbsolutePath } from "@dtm-frontend/search-and-help-client-lib/shared";
import { AuthActions, AuthState } from "@dtm-frontend/shared/auth";
import { AvailableLanguages, IconName, UIActions, UIState } from "@dtm-frontend/shared/ui";
import { LANGUAGE_CONFIGURATION, LanguageCode } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatestWith, map, tap } from "rxjs";
import { MENU_TOGGLE_ANIMATION } from "./menu-toggle-animation";

interface MenuComponentState {
    isExpanded: boolean;
}

interface MenuItem {
    label: string;
    link: string;
    iconName: IconName;
}

const MENU_ITEMS: MenuItem[] = [
    {
        label: "sahClient.menuItems.homeLabel",
        link: SahAbsolutePath.Dashboard,
        iconName: "home",
    },
];

@UntilDestroy()
@Component({
    selector: "sah-client-menu",
    templateUrl: "./menu.component.html",
    styleUrls: ["./menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [MENU_TOGGLE_ANIMATION],
})
export class MenuComponent {
    protected readonly MENU_ITEMS = MENU_ITEMS;
    protected readonly LANGUAGE = {
        polish: LANGUAGE_CONFIGURATION.languageDefinitions.find(
            (languageDefinition) => languageDefinition.alpha2Code === AvailableLanguages.Polish
        ),
        english: LANGUAGE_CONFIGURATION.languageDefinitions.find(
            (languageDefinition) => languageDefinition.alpha2Code === AvailableLanguages.English
        ),
    };

    protected readonly isExpanded$ = this.localStore.selectByKey("isExpanded");
    protected readonly userFullName$ = this.store.select(AuthState.userFirstName).pipe(
        combineLatestWith(this.store.select(AuthState.userLastName)),
        map(([firstName, lastName]) => `${firstName} ${lastName}`)
    );
    protected readonly activeLanguage$ = this.store
        .select(UIState.activeLanguage)
        .pipe(tap((activeLanguage) => this.translocoService.setActiveLang(activeLanguage)));

    constructor(
        private readonly localStore: LocalComponentStore<MenuComponentState>,
        private readonly store: Store,
        private readonly translocoService: TranslocoService
    ) {
        this.localStore.setState({ isExpanded: false });
    }

    protected openMenu(): void {
        this.localStore.patchState({ isExpanded: true });
    }

    protected closeMenu(): void {
        this.localStore.patchState({ isExpanded: false });
    }

    protected logOut(): void {
        this.store.dispatch(new AuthActions.Logout());
        this.closeMenu();
    }

    protected updateLanguage(language: LanguageCode): void {
        this.store.dispatch(new UIActions.SetActiveLanguage(language));
    }
}
