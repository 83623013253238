import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { IncidentCategory, MapArea } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { AnimationUtils, LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { combineLatestWith, first, startWith, take, tap, withLatestFrom } from "rxjs";
import { Coordinator, IncidentFormValues, Institution, Restriction, Unit } from "../../../models/incident-creator.models";

interface IncidentCreatorDataComponentState {
    airOperationsCoordinators: Coordinator[] | undefined;
    deputyAirOperationsCoordinatorsPool: Coordinator[] | undefined;
    flightsCoordinators: Coordinator[] | undefined;
    flightsCoordinatorsPool: Coordinator[] | undefined;
    institutions: Institution[] | undefined;
    units: Unit[] | undefined;
    restrictions: Restriction[] | undefined;
    userId: string | undefined;
    isProcessing: boolean;
    hasGetRestrictionsError: boolean;
    isEditMode: boolean;
}

interface IncidentForm {
    name: FormControl<string | null>;
    categories: FormControl<IncidentCategory[] | null>;
    isPlanned: FormControl<boolean | null>;
    isCustomArea: FormControl<boolean | null>;
    customArea: FormControl<MapArea | null | undefined>;
    restriction: FormControl<Restriction | null | undefined>;
    leadInstitution: FormControl<Institution | null>;
    unit: FormControl<Unit | null>;
    airOperationsCoordinator: FormControl<Coordinator | null>;
    deputyAirOperationsCoordinators: FormControl<Coordinator[] | null>;
    isRequiredTaskReconciliation: FormControl<boolean | null>;
    flightsCoordinators: FormControl<Coordinator[] | null>;
}

const NAME_MAX_LENGTH = 256;
const INCIDENT_CATEGORIES = Object.values(IncidentCategory);

@UntilDestroy()
@Component({
    selector:
        "sah-client-lib-incident-creator-data[airOperationsCoordinators][flightsCoordinators][institutions][units][restrictions][userId][customArea][isProcessing]",
    templateUrl: "./incident-creator-data.component.html",
    styleUrls: ["../incident-creator-side-panel.scss", "./incident-creator-data.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInAnimation()],
})
export class IncidentCreatorDataComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable: InvalidFormScrollableDirective | undefined;

    @Input() public set data(value: IncidentFormValues | undefined) {
        if (value) {
            this.incidentForm.patchValue(value);
            this.incidentForm.controls.isPlanned.disable();
        }

        if (value?.restriction) {
            this.syncRestrictionsWithLoadedData(value.restriction);
        }
    }

    @Input() public set isEditMode(value: BooleanInput) {
        this.localStore.patchState({ isEditMode: coerceBooleanProperty(value) });
    }

    @Input() public set airOperationsCoordinators(value: Coordinator[] | undefined) {
        this.localStore.patchState({ airOperationsCoordinators: value });
    }

    @Input() public set flightsCoordinators(value: Coordinator[] | undefined) {
        this.localStore.patchState({ flightsCoordinators: value, flightsCoordinatorsPool: value });
    }

    @Input() public set institutions(value: Institution[] | undefined) {
        this.localStore.patchState({ institutions: value });
    }

    @Input() public set units(value: Unit[] | undefined) {
        this.localStore.patchState({ units: value });
    }

    @Input() public set restrictions(value: Restriction[] | undefined) {
        this.localStore.patchState({ restrictions: value });
    }

    @Input() public set userId(value: string | undefined) {
        this.localStore.patchState({ userId: value });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set customArea(value: MapArea | undefined) {
        this.incidentForm.controls.customArea.setValue(value ?? null);
    }

    @Input() public set hasGetRestrictionsError(value: BooleanInput) {
        this.localStore.patchState({ hasGetRestrictionsError: coerceBooleanProperty(value) });
    }

    @Output() protected readonly isCustomAreaChange = new EventEmitter<boolean>();
    @Output() protected readonly restrictedAreaChange = new EventEmitter<Restriction | null>();
    @Output() protected readonly formSubmit = new EventEmitter<IncidentFormValues>();
    @Output() protected readonly formCancel = new EventEmitter<void>();

    protected readonly INCIDENT_CATEGORIES = INCIDENT_CATEGORIES;

    protected readonly incidentForm: FormGroup<IncidentForm> = new FormGroup<IncidentForm>({
        name: new FormControl(null, [Validators.required, Validators.maxLength(NAME_MAX_LENGTH)]),
        categories: new FormControl(null, [Validators.required]),
        isPlanned: new FormControl(null),
        isCustomArea: new FormControl(null, [Validators.required]),
        customArea: new FormControl(null, [Validators.required]),
        restriction: new FormControl(null, [Validators.required]),
        leadInstitution: new FormControl(null, [Validators.required]),
        unit: new FormControl(null, [Validators.required]),
        airOperationsCoordinator: new FormControl(null, [Validators.required]),
        deputyAirOperationsCoordinators: new FormControl(null),
        isRequiredTaskReconciliation: new FormControl(null),
        flightsCoordinators: new FormControl(null),
    });

    protected readonly isEditMode$ = this.localStore.selectByKey("isEditMode");
    protected readonly deputyAirOperationsCoordinatorsPool$ = this.localStore.selectByKey("deputyAirOperationsCoordinatorsPool");
    protected readonly flightsCoordinatorsPool$ = this.localStore.selectByKey("flightsCoordinatorsPool");
    protected readonly institutions$ = this.localStore.selectByKey("institutions");
    protected readonly units$ = this.localStore.selectByKey("units");
    protected readonly restrictions$ = this.localStore.selectByKey("restrictions");
    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly hasGetRestrictionsError$ = this.localStore.selectByKey("hasGetRestrictionsError");

    constructor(private readonly localStore: LocalComponentStore<IncidentCreatorDataComponentState>) {
        this.localStore.setState({
            airOperationsCoordinators: undefined,
            deputyAirOperationsCoordinatorsPool: undefined,
            flightsCoordinators: undefined,
            flightsCoordinatorsPool: undefined,
            institutions: undefined,
            units: undefined,
            restrictions: undefined,
            userId: undefined,
            isProcessing: false,
            hasGetRestrictionsError: false,
            isEditMode: false,
        });

        this.watchForUserIdAndAirOperationsCoordinatorsListInit();
        this.watchForIsCustomAreaChange();
        this.watchRestrictedAreaChange();
        this.watchForDeputyAirOperationsCoordinatorsChange();
        this.watchForIsRequiredTaskReconciliationChange();
    }

    protected submit(): void {
        if (this.incidentForm.invalid) {
            this.incidentForm.markAllAsTouched();
            this.invalidFormScrollable?.scrollToFirstInvalidField();

            return;
        }

        const dataToSubmit = this.incidentForm.value;
        dataToSubmit.restriction = this.localStore
            .selectSnapshotByKey("restrictions")
            ?.find((restriction) => restriction.id === dataToSubmit.restriction?.id);

        this.formSubmit.emit(dataToSubmit as IncidentFormValues);
    }

    protected getCoordinatorFullName(coordinator: Coordinator | null): string {
        if (!coordinator) {
            return "";
        }

        return `${coordinator.firstName} ${coordinator.lastName}`;
    }

    protected compareSelectOptionsWithIds(left: { id: string } | null, right: { id: string } | null): boolean {
        return left?.id === right?.id;
    }

    protected compareUnits(left: Unit | null, right: Unit | null): boolean {
        return left?.name === right?.name;
    }

    private watchForUserIdAndAirOperationsCoordinatorsListInit(): void {
        this.localStore
            .selectByKey("userId")
            .pipe(
                RxjsUtils.filterFalsy(),
                combineLatestWith(
                    this.localStore.selectByKey("airOperationsCoordinators").pipe(RxjsUtils.filterFalsy()),
                    this.localStore.selectByKey("flightsCoordinators").pipe(RxjsUtils.filterFalsy())
                ),
                first(),
                untilDestroyed(this)
            )
            .subscribe(([userId, airOperationsCoordinators, flightsCoordinators]) => {
                const user = airOperationsCoordinators?.find((coordinator) => coordinator.id === userId) ?? null;

                this.incidentForm.controls.airOperationsCoordinator.setValue(user);

                if (user) {
                    const deputyAirOperationsCoordinatorsPool = airOperationsCoordinators.filter(
                        (coordinator) => user?.id !== coordinator.id
                    );
                    const flightsCoordinatorsPool = flightsCoordinators.filter((coordinator) => user?.id !== coordinator.id);

                    this.localStore.patchState({ deputyAirOperationsCoordinatorsPool, flightsCoordinatorsPool });
                }
            });
    }

    private watchForIsCustomAreaChange(): void {
        const {
            isCustomArea: isCustomAreaControl,
            restriction: restrictedAreaControl,
            customArea: customAreaControl,
        } = this.incidentForm.controls;

        isCustomAreaControl.valueChanges.pipe(startWith(isCustomAreaControl.value), untilDestroyed(this)).subscribe((isCustomAreaValue) => {
            this.manageControlStateOnCondition(restrictedAreaControl, isCustomAreaValue === false, false);
            this.manageControlStateOnCondition(customAreaControl, !!isCustomAreaValue, false);
            this.isCustomAreaChange.emit(!!isCustomAreaValue);
        });
    }

    private watchRestrictedAreaChange(): void {
        const { restriction: restrictedAreaControl } = this.incidentForm.controls;

        restrictedAreaControl.valueChanges
            .pipe(startWith(restrictedAreaControl.value), untilDestroyed(this))
            .subscribe((restrictedArea) => {
                this.restrictedAreaChange.emit(restrictedArea);
            });
    }

    private watchForDeputyAirOperationsCoordinatorsChange(): void {
        const {
            airOperationsCoordinator: airOperationsCoordinatorControl,
            deputyAirOperationsCoordinators: deputyAirOperationsCoordinatorsControl,
            flightsCoordinators: flightsCoordinatorsControl,
        } = this.incidentForm.controls;

        deputyAirOperationsCoordinatorsControl.valueChanges
            .pipe(withLatestFrom(airOperationsCoordinatorControl.valueChanges), untilDestroyed(this))
            .subscribe(([deputyAirOperationsCoordinatorsValue, airOperationsCoordinatorValue]) => {
                const flightsCoordinatorsPool = this.localStore
                    .selectSnapshotByKey("flightsCoordinators")
                    ?.filter(
                        (coordinator) =>
                            airOperationsCoordinatorValue?.id !== coordinator.id &&
                            !deputyAirOperationsCoordinatorsValue?.find(
                                (deputyAirCoordinator) => deputyAirCoordinator.id === coordinator.id
                            )
                    );

                this.localStore.patchState({ flightsCoordinatorsPool });

                if (flightsCoordinatorsControl.value) {
                    const filteredFlightsCoordinators = flightsCoordinatorsControl.value?.filter((flightsCoordinator) =>
                        flightsCoordinatorsPool?.some(
                            (availableFlightCoordinator) => availableFlightCoordinator.id === flightsCoordinator.id
                        )
                    );

                    flightsCoordinatorsControl.setValue(filteredFlightsCoordinators);
                }
            });
    }

    private watchForIsRequiredTaskReconciliationChange(): void {
        const { isRequiredTaskReconciliation: isRequiredTaskReconciliationControl, flightsCoordinators: flightsCoordinatorsControl } =
            this.incidentForm.controls;

        isRequiredTaskReconciliationControl.valueChanges
            .pipe(startWith(isRequiredTaskReconciliationControl.value), untilDestroyed(this))
            .subscribe((isRequiredTaskReconciliation: boolean | null) =>
                this.manageControlStateOnCondition(flightsCoordinatorsControl, !!isRequiredTaskReconciliation)
            );
    }

    private manageControlStateOnCondition(control: AbstractControl, enableCondition: boolean, shouldResetValueOnDisabled = true): void {
        if (enableCondition && control.disabled) {
            control.enable({ emitEvent: false });
        } else if (!enableCondition && control.enabled) {
            if (shouldResetValueOnDisabled) {
                control.reset();
            }
            control.disable({ emitEvent: false });
            control.setErrors(null, { emitEvent: false });
        }
    }

    private syncRestrictionsWithLoadedData(loadedRestriction: Restriction): void {
        this.localStore
            .selectByKey("restrictions")
            .pipe(
                RxjsUtils.filterFalsy(),
                take(1),
                tap((restrictions) => {
                    const hasMatchingRestriction = restrictions.some((restriction) => restriction.id === loadedRestriction.id);

                    if (!hasMatchingRestriction) {
                        this.localStore.patchState({ restrictions: [loadedRestriction, ...restrictions] });

                        return;
                    }

                    this.localStore.patchState({
                        restrictions: restrictions.map((restriction) =>
                            restriction.id === loadedRestriction.id ? loadedRestriction : restriction
                        ),
                    });
                }),
                untilDestroyed(this)
            )
            .subscribe();
    }
}
