import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";

export namespace DashboardActions {
    export class GetMyActiveIncidents {
        public static readonly type = "[Dashboard] Get my active incidents";

        constructor(public pageEvent?: PageEvent) {}
    }

    export class GetMyPlannedIncidents {
        public static readonly type = "[Dashboard] Get my planned incidents";

        constructor(public pageEvent?: PageEvent) {}
    }

    export class GetMyCompletedIncidents {
        public static readonly type = "[Dashboard] Get my completed incidents";

        constructor(public pageEvent?: PageEvent) {}
    }

    export class ActivateIncident {
        public static readonly type = "[Dashboard] Activate incident";
        constructor(public incidentId: string) {}
    }

    export class CompleteIncident {
        public static readonly type = "[Dashboard] Complete incident";
        constructor(public incidentId: string) {}
    }

    export class DownloadIncidentReport {
        public static readonly type = "[Dashboard] Download incident report";
        constructor(public incidentId: string, public reportName: string) {}
    }
}
