import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import {
    IncidentSharedDataState,
    IncidentStatus,
    SidePanelViewType,
    Task,
    TaskStatus,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { AnimationUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { map } from "rxjs/operators";
import { AreaEditService } from "../../services/area-edit.service";
import { TaskService } from "../../services/task.service";
import { IncidentActions } from "../../state/incident.actions";
import { IncidentState } from "../../state/incident.state";

interface TaskSidePanelComponentState {
    taskStatusPool: TaskStatus[];
}

@Component({
    selector: "sah-client-lib-task-side-panel[taskStatusPool]",
    templateUrl: "task-side-panel.component.html",
    styleUrls: ["task-side-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
    animations: [AnimationUtils.slideInSideOffScreenRight()],
})
export class TaskSidePanelComponent {
    @Input() public set taskStatusPool(value: TaskStatus[] | undefined) {
        this.localStore.patchState({ taskStatusPool: value ?? [] });
    }

    protected readonly IncidentStatus = IncidentStatus;
    protected readonly SidePanelViewType = SidePanelViewType;

    protected readonly isProcessing$ = this.store.select(IncidentState.isProcessing);
    protected readonly tasks$ = this.store.select(IncidentSharedDataState.tasks);
    protected readonly teams$ = this.store.select(IncidentSharedDataState.teams);
    protected readonly incidentStatus$ = this.store.select(IncidentSharedDataState.incidentStatus);
    protected readonly viewType$ = this.store.select(IncidentState.sidePanelViewOpenState);
    protected readonly editedTask$ = this.store.select(IncidentState.editedTask);
    protected readonly isExpanded$ = this.store
        .select(IncidentState.sidePanelViewOpenState)
        .pipe(
            map(
                (sidePanelOpenState) =>
                    sidePanelOpenState[SidePanelViewType.TaskList] ||
                    sidePanelOpenState[SidePanelViewType.TaskCreator] ||
                    sidePanelOpenState[SidePanelViewType.TaskEditor]
            )
        );

    protected readonly taskStatusPool$ = this.localStore.selectByKey("taskStatusPool");

    constructor(
        protected readonly areaService: AreaEditService,
        private readonly localStore: LocalComponentStore<TaskSidePanelComponentState>,
        private readonly store: Store,
        protected readonly taskService: TaskService
    ) {
        this.localStore.setState({
            taskStatusPool: [],
        });
    }

    protected closePanel(): void {
        this.store.dispatch(IncidentActions.CloseSidePanel);
    }

    protected changeViewType(viewType: SidePanelViewType): void {
        this.store.dispatch(new IncidentActions.OpenSidePanel(viewType));
    }

    protected openTaskEditor(task: Task): void {
        this.store.dispatch(new IncidentActions.OpenTaskEditor(task));
    }
}
