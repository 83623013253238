<ng-container *ngIf="photo$ | ngrxPush as photo">
    <img class="thumbnail" alt="" [src]="photo" />

    <div class="actions-container">
        <div class="actions">
            <button type="button" class="button-icon" (click)="openImagePreview()">
                <dtm-ui-icon name="zoom-in-line"></dtm-ui-icon>
            </button>

            <button type="button" class="button-icon" (click)="downloadImage()">
                <dtm-ui-icon name="download"></dtm-ui-icon>
            </button>

            <ng-content> </ng-content>
        </div>
    </div>

    <ng-template #photoPreviewTemplateRef>
        <img class="preview" alt="" [src]="photo" />
    </ng-template>
</ng-container>
