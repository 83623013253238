import { HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ServiceWorkerModule } from "@angular/service-worker";
import { DASHBOARD_ENDPOINTS, DashboardModule } from "@dtm-frontend/search-and-help-client-lib/dashboard";
import { INCIDENT_ENDPOINTS, IncidentModule } from "@dtm-frontend/search-and-help-client-lib/incident";
import { INCIDENT_CREATOR_ENDPOINTS, IncidentCreatorModule } from "@dtm-frontend/search-and-help-client-lib/incident-creator";
import { INCIDENT_SHARED_ENDPOINTS } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { SearchAndHelpSharedModule } from "@dtm-frontend/search-and-help-shared-lib/shared";
import { KEYCLOAK_CONFIG, OfflineCapableAuthModule } from "@dtm-frontend/shared/auth";
import { AZURE_MAPS_SUBSCRIPTION_KEY, GeolocationService, WebGeolocationService } from "@dtm-frontend/shared/map";
import { LEAFLET_MAP_CONFIG } from "@dtm-frontend/shared/map/leaflet";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    I18nRootModule,
    LANGUAGE_CONFIGURATION,
    LanguageCode,
    TRANSLATION_ENDPOINTS,
    TranslationHelperService,
    getTranslocoInlineLoader,
} from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { FunctionUtils, Logger, LoggerModule } from "@dtm-frontend/shared/utils";
import { SharedWebsocketModule, SharedWebsocketModuleConfig, WEBSOCKET_CONFIGURATION } from "@dtm-frontend/shared/websocket";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsStoragePluginModule, StorageOption } from "@ngxs/storage-plugin";
import { NgxsModule } from "@ngxs/store";
import { first, tap } from "rxjs/operators";
import { SearchAndHelpClientEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { MenuComponent } from "./components/menu/menu.component";
import { ActionsHandler } from "./services/actions.handler";

Logger.initialize("https://869b52ca5e77b8ee62751126b2975280@sentry.pansa.cloud/15");

export function createAppModule(environment: SearchAndHelpClientEnvironment) {
    @NgModule({
        imports: [
            NgxsModule.forRoot([], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            DashboardModule,
            HttpClientModule,
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            IncidentCreatorModule,
            IncidentModule,
            LetModule,
            LoggerModule.forRoot(environment.name, !environment.production),
            MatProgressSpinnerModule,
            MatTooltipModule,
            NgxsStoragePluginModule.forRoot({
                key: ["ui"],
                storage: StorageOption.LocalStorage,
            }),
            NgxsReduxDevtoolsPluginModule.forRoot({
                disabled: environment.production,
                name: "SearchAndHelpClient",
            }),
            OfflineCapableAuthModule,
            PushModule,
            ServiceWorkerModule.register("ngsw-worker.js", {
                enabled: environment.production,
                // Register the ServiceWorker as soon as the application is stable
                // or after 30 seconds (whichever comes first).
                registrationStrategy: "registerWhenStable:30000",
            }),
            SearchAndHelpSharedModule,
            SharedToastModule.forRoot({ positionClass: "toast-top-center" }),
            SharedUiModule,
            SharedWebsocketModule.forRoot(),
        ],
        declarations: [AppComponent, MenuComponent],
        providers: [
            {
                provide: APP_INITIALIZER,
                useFactory: () => FunctionUtils.noop,
                deps: [ActionsHandler],
                multi: true,
            },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "sahClient",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
            {
                provide: AZURE_MAPS_SUBSCRIPTION_KEY,
                useValue: environment.azureMapsSubscriptionKey,
            },
            {
                provide: DASHBOARD_ENDPOINTS,
                useValue: environment.dashboardEndpoints,
            },
            {
                provide: INCIDENT_ENDPOINTS,
                useValue: environment.incidentEndpoints,
            },
            {
                provide: INCIDENT_SHARED_ENDPOINTS,
                useValue: environment.incidentSharedEndpoints,
            },
            {
                provide: INCIDENT_CREATOR_ENDPOINTS,
                useValue: environment.incidentCreatorEndpoints,
            },
            {
                provide: TRANSLATION_ENDPOINTS,
                useValue: environment.translationEndpoints,
            },
            {
                provide: LEAFLET_MAP_CONFIG,
                useValue: environment.leafletMapConfig,
            },
            {
                provide: KEYCLOAK_CONFIG,
                useValue: environment.keycloakConfig,
            },
            {
                provide: WEBSOCKET_CONFIGURATION,
                useFactory: (): SharedWebsocketModuleConfig => ({
                    endpoint: environment.websocketEndpoint,
                }),
            },
            {
                provide: GeolocationService,
                useClass: WebGeolocationService,
            },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {
        constructor(translocoHelper: TranslationHelperService, titleService: Title) {
            translocoHelper
                .waitForTranslation("sahClient.appTitle")
                .pipe(first(), tap(titleService.setTitle.bind(titleService)))
                .subscribe();
        }
    }

    return AppModule;
}
