<div class="dialog-header">
    <div mat-dialog-title>{{ "sahClientLibIncident.areaNameChangeDialog.dialogHeader" | transloco }}</div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>
<div mat-dialog-content>
    <dtm-ui-input-field>
        <label>{{ "sahClientLibIncident.areaNameChangeDialog.areaNameLabel" | transloco }}</label>
        <input [formControl]="areaNameControl" matInput type="text" />
        <div class="field-error" *dtmUiFieldHasError="areaNameControl; name: 'maxlength'; error as error">
            {{ "sahClientLibIncident.areaNameChangeDialog.maxLengthError" | transloco : { maxLength: error.requiredLength } }}
        </div>
        <div class="field-error" *dtmUiFieldHasError="areaNameControl; name: ['required', 'pattern']">
            {{ "sahClientLibIncident.areaNameChangeDialog.requiredError" | transloco }}
        </div>
    </dtm-ui-input-field>
</div>
<div mat-dialog-actions align="end">
    <button type="button" class="button-secondary" cdkFocusInitial [mat-dialog-close]="null">
        {{ "sahClientLibIncident.areaNameChangeDialog.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" (click)="onConfirmClick()">
        {{ "sahClientLibIncident.areaNameChangeDialog.confirmButtonLabel" | transloco }}
    </button>
</div>
