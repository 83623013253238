import {
    HandDrawingPolyline,
    MapArea,
    MapFiltersState,
    MapToolName,
    SidePanelViewType,
    Task,
    TaskCreatorFormValues,
    TaskStatus,
    Team,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { TeamCreatorFormValues } from "../../shared/models/team.models";

export namespace IncidentActions {
    export class ResetState {
        public static readonly type = "[Incident] Reset state";
    }

    export class CreateTask {
        public static readonly type = "[Incident] Create task";
        constructor(public formValues: TaskCreatorFormValues) {}
    }

    export class UpdateTask {
        public static readonly type = "[Incident] Update task";
        constructor(public taskId: string, public formValues: TaskCreatorFormValues) {}
    }

    export class RemoveTask {
        public static readonly type = "[Incident] Remove task";
        constructor(public task: Task) {}
    }

    export class ChangeTaskStatus {
        public static readonly type = "[Incident] Change task status";

        constructor(public status: TaskStatus, public task: Task) {}
    }

    export class CreateArea {
        public static readonly type = "[Incident] Create area";
        constructor(public mapArea: MapArea) {}
    }

    export class UpdateArea {
        public static readonly type = "[Incident] Update area";
        constructor(public mapArea: MapArea) {}
    }

    export class RemoveArea {
        public static readonly type = "[Incident] Remove area";
        constructor(public mapArea: MapArea) {}
    }

    export class CreateHandDrawing {
        public static readonly type = "[Incident] Create hand drawing";

        constructor(public drawing: HandDrawingPolyline) {}
    }

    export class RemoveHandDrawing {
        public static readonly type = "[Incident] Remove hand drawing";

        constructor(public drawing: HandDrawingPolyline) {}
    }

    export class AssignAreaToTask {
        public static readonly type = "[Incident] Assign area to task";
        constructor(public task: Task, public area: MapArea) {}
    }

    export class DetachAreaFromTask {
        public static readonly type = "[Incident] Detach area from task";
        constructor(public task: Task, public area: MapArea) {}
    }

    export class AttachTeamToTask {
        public static readonly type = "[Incident] Attach team to task";
        constructor(public task: Task, public team: Team) {}
    }

    export class DetachTeamFromTask {
        public static readonly type = "[Incident] Detach team from task";
        constructor(public task: Task) {}
    }

    export class CreateTeamsInIncident {
        public static readonly type = "[Incident] Create teams in incident";
        constructor(public teams: TeamCreatorFormValues[]) {}
    }

    export class OpenSidePanel {
        public static readonly type = "[Incident] Open side panel";
        constructor(public sidePanelViewType: SidePanelViewType) {}
    }

    export class CloseSidePanel {
        public static readonly type = "[Incident] Close side panel";
    }

    export class GetTeamCreatorHints {
        public static readonly type = "[Incident] Get team creator hints";
    }

    export class UpdateMapFilters {
        public static readonly type = "[Incident] Update map filters";
        constructor(public mapFiltersState: MapFiltersState) {}
    }

    export class SelectMapTool {
        public static readonly type = "[Incident] Select map tool";
        constructor(public mapToolName: MapToolName) {}
    }

    export class SelectArea {
        public static readonly type = "[Incident] Select area";
        constructor(public areaId: string | undefined) {}
    }

    export class OpenAreaPreview {
        public static readonly type = "[Incident] Open area preview";
        constructor(public areaId: string | undefined) {}
    }

    export class OpenTaskEditor {
        public static readonly type = "[Incident] Open task editor";
        constructor(public task: Task | undefined) {}
    }

    export class GetOperators {
        public static readonly type = "[Incident] Get operators";
    }

    export class GetPilots {
        public static readonly type = "[Incident] Get pilots";
        constructor(public operatorId: string) {}
    }

    export class GetPilotsUavs {
        public static readonly type = "[Incident] Get pilot's uavs";
        constructor(public operatorId: string, public pilotId: string) {}
    }

    export class RemoveTeam {
        public static readonly type = "[Incident] Remove team";
        constructor(public teamId: string) {}
    }

    export class AcceptPilotTask {
        public static readonly type = "[Incident] Accept pilot task";
        constructor(public task: Task, public shouldBeActivated: boolean) {}
    }

    export class RejectPilotTask {
        public static readonly type = "[Incident] Reject pilot task";
        constructor(public task: Task, public rejectionReason: string | null) {}
    }
}
