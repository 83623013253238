import { ChangeDetectionStrategy, Component, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { SidePanelViewType } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { AnimationUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { combineLatestWith, distinctUntilChanged, first, mergeMap, of, tap, toArray } from "rxjs";
import { map } from "rxjs/operators";
import { BasicUavEquipment, Operator, Pilot, TeamCreatorFormValues } from "../../../shared/models/team.models";
import { IncidentActions } from "../../state/incident.actions";
import { IncidentState } from "../../state/incident.state";

@UntilDestroy()
@Component({
    selector: "sah-client-lib-team-creator-side-panel",
    templateUrl: "team-creator-side-panel.component.html",
    styleUrls: ["team-creator-side-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInSideOffScreenRight()],
})
export class TeamCreatorSidePanelComponent {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    protected readonly SidePanelViewType = SidePanelViewType;

    protected readonly teamsControl = new FormControl<Partial<TeamCreatorFormValues>[]>([{}]);

    private readonly defaultAccessoriesOptions$ = of(...Object.values(BasicUavEquipment)).pipe(
        mergeMap((accessory) =>
            this.translationHelper
                .waitForTranslation("sahClientLibIncident.teamPanel.uavAccessoriesValueLabel", {
                    value: accessory,
                })
                .pipe(
                    first(),
                    map((label) => ({ value: accessory, label, isDisabled: false }))
                )
        ),
        toArray()
    );
    protected readonly teamUavAccessories$ = this.store.select(IncidentState.teamUavAccessories).pipe(
        combineLatestWith(this.defaultAccessoriesOptions$),
        map(([accessories, defaultAccessories]) => [...defaultAccessories, ...(accessories ?? [])])
    );
    protected readonly isProcessing$ = this.store.select(IncidentState.isProcessing);
    protected readonly isExpanded$ = this.store.select(IncidentState.sidePanelViewOpenState).pipe(
        map((sidePanelOpenState) => sidePanelOpenState[SidePanelViewType.TeamCreator]),
        distinctUntilChanged(),
        tap((isSidePanelOpened) => {
            if (!isSidePanelOpened) {
                return;
            }

            this.refresh();
        })
    );
    protected readonly operators$ = this.store.select(IncidentState.operators);
    protected readonly operatorPilots$ = this.store.select(IncidentState.operatorPilots);
    protected readonly viewType$ = this.store.select(IncidentState.sidePanelViewOpenState);

    constructor(
        private readonly store: Store,
        private readonly toastrService: ToastrService,
        private readonly translationHelper: TranslationHelperService,
        private readonly translocoService: TranslocoService
    ) {}

    protected submit(): void {
        this.teamsControl.updateValueAndValidity();
        if (this.teamsControl.invalid) {
            this.teamsControl.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.store
            .dispatch(new IncidentActions.CreateTeamsInIncident(this.teamsControl.getRawValue() as TeamCreatorFormValues[]))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.createTeamsError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahClientLibIncident.teamPanel.createTeamsError"));

                    return;
                }

                this.toastrService.success(this.translocoService.translate("sahClientLibIncident.teamPanel.createTeamsSuccessMessage"));
                this.store.dispatch(IncidentActions.CloseSidePanel);
            });
    }

    protected closePanel(): void {
        this.store.dispatch(IncidentActions.CloseSidePanel);
    }

    protected getOperatorsPilots(operator: Operator): void {
        this.store.dispatch(new IncidentActions.GetPilots(operator.id));
    }

    protected getPilotsUavs(change: { operator: Operator; pilot: Pilot }): void {
        this.store.dispatch(new IncidentActions.GetPilotsUavs(change.operator.id, change.pilot.pilotId));
    }

    private refresh(): void {
        this.teamsControl.reset([{}]);
        this.store.dispatch(IncidentActions.GetTeamCreatorHints);
        this.store.dispatch(IncidentActions.GetOperators);
    }
}
