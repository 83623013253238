import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { Task, TaskStatus, TaskStatusChange, TaskTeamChange, Team } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AreaAssignedTasksListComponentState {
    assignedTasks: Task[];
    teams: Team[];
    taskStatusPool: TaskStatus[];
}

@Component({
    selector: "sah-client-lib-area-assigned-tasks-list[assignedTasks]",
    templateUrl: "area-assigned-tasks-list.component.html",
    styleUrls: ["area-assigned-tasks-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AreaAssignedTasksListComponent {
    @Input() public set assignedTasks(value: Task[] | undefined) {
        this.localStore.patchState({ assignedTasks: value });
    }
    @Input() public set teams(value: Team[] | undefined) {
        this.localStore.patchState({ teams: value ?? [] });
    }
    @Input() public set taskStatusPool(value: TaskStatus[] | undefined) {
        this.localStore.patchState({ taskStatusPool: value ?? [] });
    }

    @Output() protected readonly newTaskAssign = new EventEmitter();
    @Output() protected readonly assignedTaskRemove = new EventEmitter<Task>();
    @Output() protected readonly taskAreaSelect = new EventEmitter<string>();
    @Output() protected readonly teamAttach = new EventEmitter<TaskTeamChange>();
    @Output() protected readonly teamDetach = new EventEmitter<Task>();
    @Output() protected readonly statusChange = new EventEmitter<TaskStatusChange>();
    @Output() protected readonly pilotTaskAccept = new EventEmitter<Task>();
    @Output() protected readonly pilotTaskReject = new EventEmitter<Task>();

    protected readonly assignedTasks$ = this.localStore.selectByKey("assignedTasks");
    protected readonly teams$ = this.localStore.selectByKey("teams");
    protected readonly taskStatusPool$ = this.localStore.selectByKey("taskStatusPool");

    constructor(private readonly localStore: LocalComponentStore<AreaAssignedTasksListComponentState>) {
        this.localStore.setState({
            assignedTasks: [],
            teams: [],
            taskStatusPool: [],
        });
    }
}
