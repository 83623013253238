import { Injectable } from "@angular/core";
import {
    IncidentErrorType,
    SidePanelViewType,
    Task,
    TaskCreatorFormValues,
    TaskStatusChange,
    TaskTeamChange,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { first, switchMap } from "rxjs";
import { AcceptPilotTaskDialogComponent } from "../components/task-side-panel/accept-pilot-task-dialog/accept-pilot-task-dialog.component";
import { RejectPilotTaskDialogComponent } from "../components/task-side-panel/reject-pilot-task-dialog/reject-pilot-task-dialog.component";
import { IncidentActions } from "../state/incident.actions";
import { IncidentState } from "../state/incident.state";

@UntilDestroy()
@Injectable()
export class TaskService {
    constructor(
        private readonly dialogService: DialogService,
        private readonly store: Store,
        private readonly toastrService: ToastrService,
        private readonly translationHelper: TranslationHelperService,
        private readonly translocoService: TranslocoService
    ) {}

    public createTask(formValues: TaskCreatorFormValues): void {
        this.store
            .dispatch(new IncidentActions.CreateTask(formValues))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.createTaskError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahClientLibIncident.taskSidePanel.createTaskError"));

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahClientLibIncident.taskSidePanel.createTaskSuccessMessage", {
                        taskName: formValues.name,
                    })
                );

                this.store.dispatch(new IncidentActions.OpenSidePanel(SidePanelViewType.TaskList));
            });
    }

    public updateTask(formValues: TaskCreatorFormValues): void {
        const updatedTaskId = this.store.selectSnapshot(IncidentState.editedTask)?.id;
        if (!updatedTaskId) {
            return;
        }

        this.store
            .dispatch(new IncidentActions.UpdateTask(updatedTaskId, formValues))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.updateTaskError);

                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("sahClientLibIncident.taskSidePanel.updateTaskError", {
                            taskName: formValues.name,
                        })
                    );

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahClientLibIncident.taskSidePanel.updateTaskSuccessMessage", {
                        taskName: formValues.name,
                    })
                );

                this.store.dispatch(new IncidentActions.OpenSidePanel(SidePanelViewType.TaskList));
            });
    }

    public changeTaskStatus({ status, task }: TaskStatusChange): void {
        this.store
            .dispatch(new IncidentActions.ChangeTaskStatus(status, task))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.changeTaskStatusError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahClientLibIncident.taskSidePanel.taskStatusChangeError"));

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahClientLibIncident.taskSidePanel.taskStatusChangeSuccessMessage")
                );
            });
    }

    public removeTask(task: Task): void {
        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("sahClientLibIncident.taskSidePanel.confirmTaskRemoveTitle", {
                        taskName: task.name,
                    }),
                    confirmationText: this.translocoService.translate("sahClientLibIncident.incident.confirmMessage"),
                    confirmButtonLabel: this.translocoService.translate("sahClientLibIncident.incident.confirmButtonLabel"),
                    declineButtonLabel: this.translocoService.translate("sahClientLibIncident.incident.declineButtonLabel"),
                    theme: ButtonTheme.Warn,
                },
                disableClose: true,
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new IncidentActions.RemoveTask(task))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.removeTaskError);

                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahClientLibIncident.taskSidePanel.taskRemoveError"));

                    return;
                }

                this.toastrService.success(this.translocoService.translate("sahClientLibIncident.taskSidePanel.taskRemoveSuccessMessage"));
            });
    }

    public attachTeamToTask({ task, team }: TaskTeamChange): void {
        this.store
            .dispatch(new IncidentActions.AttachTeamToTask(task, team))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.attachTeamToTaskError);
                if (!error) {
                    return;
                }

                this.toastrService.error(this.translocoService.translate("sahClientLibIncident.taskSidePanel.taskTeamAttachError"));
            });
    }

    public detachTeamFromTask(task: Task): void {
        this.store
            .dispatch(new IncidentActions.DetachTeamFromTask(task))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.detachTeamFromTaskError);
                if (!error) {
                    return;
                }

                switch (error.type) {
                    case IncidentErrorType.Conflict:
                        this.toastrService.error(this.translationHelper.selectSystemTranslation(error.messageKey, error.args));
                        break;
                    default:
                        this.toastrService.error(this.translocoService.translate("sahClientLibIncident.taskSidePanel.taskTeamDetachError"));
                }
            });
    }

    public acceptPilotTask(task: Task): void {
        this.dialogService
            .open(AcceptPilotTaskDialogComponent, { data: { task } })
            .afterClosed()
            .pipe(
                first(),
                RxjsUtils.filterFalsy(),
                switchMap(({ shouldBeActivated }) => this.store.dispatch(new IncidentActions.AcceptPilotTask(task, shouldBeActivated))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.acceptPilotTaskError);
                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("sahClientLibIncident.taskSidePanel.acceptPilotTaskUnknownError")
                    );

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahClientLibIncident.taskSidePanel.acceptPilotTaskSuccessMessage", {
                        taskName: task.name,
                    })
                );
            });
    }

    public rejectPilotTask(task: Task): void {
        const dialogRef = this.dialogService.open(RejectPilotTaskDialogComponent, {
            data: { task, isProcessing$: this.store.select(IncidentState.isProcessing) },
            disableClose: true,
        });

        dialogRef.componentInstance.rejectTask$
            .pipe(
                switchMap((rejectionReason) => this.store.dispatch(new IncidentActions.RejectPilotTask(task, rejectionReason))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.rejectPilotTaskError);
                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("sahClientLibIncident.taskSidePanel.rejectPilotTaskUnknownError")
                    );

                    return;
                }

                dialogRef.close();
                this.toastrService.success(
                    this.translocoService.translate("sahClientLibIncident.taskSidePanel.rejectPilotTaskSuccessMessage", {
                        taskName: task.name,
                    })
                );
            });
    }
}
