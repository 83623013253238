<sah-shared-lib-action-marker-photo-preview
    *ngIf="photo$ | ngrxPush as photo; else addPhotoTemplate"
    [photo]="photo"
    [markerName]="markerName$ | ngrxPush"
>
    <button type="button" class="button-icon" (click)="removePhoto()">
        <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
    </button>
</sah-shared-lib-action-marker-photo-preview>

<ng-template #addPhotoTemplate>
    <div class="label">{{ "sahSharedLibIncident.actionMarker.photoLabel" | transloco }}</div>
    <dtm-ui-files-upload
        [isMultiple]="false"
        [allowedTypes]="ALLOWED_MIME_TYPES"
        (processedFilesAdd)="addPhoto($event)"
    ></dtm-ui-files-upload>
</ng-template>

<dtm-ui-form-errors>
    <ng-content select=".field-error"></ng-content>
</dtm-ui-form-errors>
