import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Task } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Observable, Subject } from "rxjs";

const REJECTION_REASON_MAX_LENGTH = 1500;
const TEXTAREA_MIN_NUMBER_OF_ROWS = 5;

@UntilDestroy()
@Component({
    selector: "sah-client-lib-reject-pilot-task-dialog",
    templateUrl: "reject-pilot-task-dialog.component.html",
    styleUrls: ["reject-pilot-task-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RejectPilotTaskDialogComponent {
    protected readonly REJECTION_REASON_MAX_LENGTH = REJECTION_REASON_MAX_LENGTH;
    protected readonly TEXTAREA_MIN_NUMBER_OF_ROWS = TEXTAREA_MIN_NUMBER_OF_ROWS;

    protected readonly rejectionReasonControl = new FormControl<string | null>(null, [Validators.maxLength(REJECTION_REASON_MAX_LENGTH)]);

    private readonly rejectTaskSubject = new Subject<string | null>();
    public readonly rejectTask$ = this.rejectTaskSubject.asObservable();

    constructor(@Inject(MAT_DIALOG_DATA) protected data: { task: Task; isProcessing$: Observable<boolean> }) {}

    protected rejectTask(): void {
        this.rejectionReasonControl.markAsTouched();
        if (this.rejectionReasonControl.invalid) {
            return;
        }

        this.rejectTaskSubject.next(this.rejectionReasonControl.value);
    }
}
