import { Pipe, PipeTransform } from "@angular/core";
import { SahPathUtils } from "../index";

@Pipe({
    name: "pathInterpolation",
})
export class PathInterpolationPipe implements PipeTransform {
    public transform(path: string, value: Record<string, string>): string {
        return SahPathUtils.interpolatePath(path, value);
    }
}
