export enum SahPath {
    Dashboard = "dashboard",
    CreateIncident = "incidents/new",
    EditIncident = "incidents/edit/:id",
    ActiveIncident = "incidents/active/:id",
    PlannedIncident = "incidents/planned/:id",
    Login = "login",
}

export const SahAbsolutePath = Object.keys(SahPath).reduce(
    (result, key) => ({
        ...result,
        [key]: `/${SahPath[key as keyof typeof SahPath]}`,
    }),
    {}
) as Record<keyof typeof SahPath, string>;
