<div class="dialog-header">
    <div mat-dialog-title>{{ "sahClientLibIncident.taskSidePanel.acceptPilotTaskTitle" | transloco : { taskName: data.task.name } }}</div>
    <button type="button" class="button-icon" [matDialogClose]="false">
        <dtm-ui-icon name="close"></dtm-ui-icon>
    </button>
</div>

<div mat-dialog-content>
    <p>{{ "sahClientLibIncident.taskSidePanel.acceptPilotTaskConfirmMessage" | transloco }}</p>
</div>

<div mat-dialog-actions align="end">
    <button type="button" class="button-secondary" [mat-dialog-close]="false">
        {{ "sahClientLibIncident.taskSidePanel.acceptPilotTaskCancelButtonLabel" | transloco }}
    </button>
    <div class="accept-buttons">
        <button type="button" class="button-secondary" (click)="accept(false)">
            {{ "sahClientLibIncident.taskSidePanel.acceptPilotTaskAcceptButtonLabel" | transloco }}
        </button>
        <button cdkFocusInitial type="button" class="button-primary" (click)="accept(true)">
            {{ "sahClientLibIncident.taskSidePanel.acceptPilotTaskAcceptAndActivateButtonLabel" | transloco }}
        </button>
    </div>
</div>
