<ng-container *ngrxLet="{ isOpen: isOpen$, incidentStatus: incidentStatus$ } as vm">
    <button type="button" class="button-secondary map-button" (click)="togglePanel(vm.isOpen)">
        <dtm-ui-icon
            *ngIf="areFiltersActive$ | ngrxPush"
            name="information"
            class="filters-applied-icon"
            [matTooltip]="'sahClientLibIncident.mapFilters.filtersActiveLabel' | transloco"
        ></dtm-ui-icon>
        {{ "sahClientLibIncident.mapFilters.mapFiltersButtonLabel" | transloco }}
        <dtm-ui-icon [name]="vm.isOpen ? 'arrow-up' : 'stack-line'"></dtm-ui-icon>
    </button>

    <form *ngIf="vm.isOpen" class="filters-form" [formGroup]="filtersForm">
        <mat-slide-toggle
            labelPosition="before"
            class="spread"
            formControlName="areas"
            [matTooltip]="
                filtersForm.controls.areas.disabled ? ('sahClientLibIncident.mapFilters.areasFilterDisabledMessage' | transloco) : ''
            "
        >
            {{ "sahClientLibIncident.mapFilters.areasToggleLabel" | transloco }}
        </mat-slide-toggle>
        <mat-slide-toggle
            *ngIf="vm.incidentStatus !== IncidentStatus.Planned"
            labelPosition="before"
            class="spread"
            formControlName="areasWithCompletedTasksOnly"
        >
            {{ "sahClientLibIncident.mapFilters.areasWithCompletedTasksOnlyToggleLabel" | transloco }}
        </mat-slide-toggle>
        <mat-slide-toggle labelPosition="before" class="spread" formControlName="areasWithPlannedTasksOnly">
            {{ "sahClientLibIncident.mapFilters.areasWithPlannedTasksOnlyToggleLabel" | transloco }}
        </mat-slide-toggle>
        <mat-slide-toggle
            labelPosition="before"
            class="spread"
            formControlName="handDrawingsAndNotes"
            [matTooltip]="
                filtersForm.controls.handDrawingsAndNotes.disabled
                    ? ('sahClientLibIncident.mapFilters.handDrawingAndNotesFilterDisabledMessage' | transloco)
                    : ''
            "
        >
            {{ "sahClientLibIncident.mapFilters.notesToggleLabel" | transloco }}
        </mat-slide-toggle>
        <mat-slide-toggle
            labelPosition="before"
            class="spread"
            formControlName="actionMarkers"
            [matTooltip]="
                filtersForm.controls.actionMarkers.disabled
                    ? ('sahClientLibIncident.mapFilters.actionMarkersFilterDisabledMessage' | transloco)
                    : ''
            "
        >
            {{ "sahClientLibIncident.mapFilters.markersToggleLabel" | transloco }}
        </mat-slide-toggle>
    </form>
</ng-container>
