import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MapArea } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AreaMenuComponentState {
    area: MapArea | undefined;
}

@Component({
    selector: "sah-client-lib-area-menu[area]",
    templateUrl: "area-menu.component.html",
    styleUrls: ["area-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AreaMenuComponent {
    @Input() public set area(value: MapArea | undefined) {
        this.localStore.patchState({ area: value });
    }

    @Output() public readonly tasksPreview = new EventEmitter<MapArea>();

    protected readonly area$ = this.localStore.selectByKey("area");

    constructor(private readonly localStore: LocalComponentStore<AreaMenuComponentState>) {
        this.localStore.setState({ area: undefined });
    }
}
