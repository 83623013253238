import { IncidentCategory, MapArea } from "@dtm-frontend/search-and-help-shared-lib/incident";

export enum CoordinatorRole {
    AirOperationsCoordinator = "AIR_OPERATIONS_COORDINATOR",
    FlightsDirector = "FLIGHTS_DIRECTOR",
}

export enum IncidentCreatorErrorType {
    Unknown = "Unknown",
    Conflict = "Conflict",
}

export interface IncidentFormValues {
    name: string;
    categories: IncidentCategory[];
    isPlanned: boolean;
    isCustomArea: boolean;
    customArea?: MapArea;
    restriction?: Restriction;
    leadInstitution: Institution;
    unit: Unit;
    airOperationsCoordinator: Coordinator;
    deputyAirOperationsCoordinators: Coordinator[];
    flightsCoordinators: Coordinator[];
    isRequiredTaskReconciliation: boolean;
}

export interface Institution {
    id: string;
    name: string;
}

export interface Unit {
    code: string;
    name: string;
}

export interface Coordinator {
    id: string;
    firstName: string;
    lastName: string;
    roles: CoordinatorRole[];
}

export interface Restriction {
    id: string;
    name: string;
    area?: MapArea;
}

export interface IncidentCreatorError {
    type: IncidentCreatorErrorType;
    messageKey: string;
    args: Record<string, unknown>;
}
