import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ComponentRef, Directive, EventEmitter, Inject, Input, OnDestroy, Output, ViewContainerRef } from "@angular/core";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "@dtm-frontend/shared/map/leaflet";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BaseIconOptions, DivIcon, DomUtil, Layer, LeafletMouseEvent, Marker } from "leaflet";
import { ACTION_MARKER_TOOLS, ActionMarker, ActionMarkerData, CustomLeafletMapEvent, MapToolName } from "../../models/incident-map.models";
import { IncidentMapLayer, IncidentMapLayersService } from "../../services/incident-map-layers.service";
import { ActionMarkerComponent } from "./action-marker/action-marker.component";

interface ActionMarkersLayerDirectiveState {
    isCreateModeEnabled: boolean;
    isSelectModeEnabled: boolean;
    isCreateProcessing: boolean;

    selectedToolName: MapToolName | undefined;
    selectedMarkerData: Partial<ActionMarkerData> | undefined;
    markerToBeCreated: ActionMarker | undefined;
}

const MARKER_POINT_POSITION_CLASS = "cursor-pointer";
const MARKER_ICON_OPTIONS: BaseIconOptions = {
    /* eslint-disable no-magic-numbers */
    iconAnchor: [17, 44],
    /* eslint-enable */
};

@UntilDestroy()
@Directive({
    selector: "sah-shared-lib-action-markers-layer[selectedToolName][isCreateModeEnabled][isSelectModeEnabled]",
    providers: [LocalComponentStore],
    exportAs: "actionMarkersLayerDirective",
})
export class ActionMarkersLayerDirective implements OnDestroy {
    @Input() public set actionMarkers(value: Partial<ActionMarkerData>[] | undefined) {
        this.loadMarkers(value);
    }

    @Input() public set selectedToolName(value: MapToolName) {
        this.localStore.patchState({ selectedToolName: value });
        this.mapInstance.then((map) => map.fire(CustomLeafletMapEvent.ClearActionMarkerSelection));
    }

    @Input() public set isCreateModeEnabled(value: BooleanInput) {
        const coercedValue = coerceBooleanProperty(value);
        this.localStore.patchState({ isCreateModeEnabled: coercedValue });

        this.mapInstance.then((map) => {
            if (coercedValue) {
                DomUtil.addClass(map.getContainer(), MARKER_POINT_POSITION_CLASS);

                return;
            }

            if (DomUtil.hasClass(map.getContainer(), MARKER_POINT_POSITION_CLASS)) {
                DomUtil.removeClass(map.getContainer(), MARKER_POINT_POSITION_CLASS);
            }
        });
    }

    @Input() public set isSelectModeEnabled(value: BooleanInput) {
        const coercedValue = coerceBooleanProperty(value);

        this.localStore.patchState({ isSelectModeEnabled: coercedValue });
    }

    @Output() protected readonly markerDragStart = new EventEmitter<ActionMarker>();
    @Output() protected readonly markerDragEnd = new EventEmitter<ActionMarker>();
    @Output() protected readonly markerSelect = new EventEmitter<Partial<ActionMarkerData> | undefined>();
    @Output() protected readonly dataLoad = new EventEmitter();

    public readonly markerDragStart$ = this.markerDragStart.asObservable();
    public readonly markerDragEnd$ = this.markerDragEnd.asObservable();
    public readonly dataLoad$ = this.dataLoad.asObservable();
    public readonly markerSelect$ = this.markerSelect.asObservable();

    public readonly mapInstance = this.mapProvider.getMap();
    private readonly actionMarkerDivIconComponents: ComponentRef<ActionMarkerComponent>[] = [];

    constructor(
        private readonly localStore: LocalComponentStore<ActionMarkersLayerDirectiveState>,
        private readonly mapLayersService: IncidentMapLayersService,
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        private readonly viewContainerRef: ViewContainerRef
    ) {
        this.localStore.setState({
            selectedToolName: undefined,
            isCreateModeEnabled: false,
            isSelectModeEnabled: false,
            isCreateProcessing: false,

            selectedMarkerData: undefined,
            markerToBeCreated: undefined,
        });

        this.init();
        this.watchCustomLeafletEvents();
    }

    private get layer() {
        return this.mapLayersService.getMapLayer(IncidentMapLayer.ActionMarkers);
    }

    public ngOnDestroy() {
        this.actionMarkerDivIconComponents.forEach((component) => component.destroy());
        this.layer.clearLayers();

        this.mapInstance.then((map) => {
            map.off("click", this.startMarkerCreation, this);
            map.off(CustomLeafletMapEvent.CreateActionMarker, this.finishMarkerCreation, this);
            map.off(CustomLeafletMapEvent.OnActionMarkerUpdateFail, this.revertMarkerPositionWhenUpdateFailed, this);
            map.off(CustomLeafletMapEvent.RemoveActionMarker, this.removeSelectedMarker, this);
            map.off(CustomLeafletMapEvent.ClearActionMarkerSelection, this.clearMarkerSelection, this);
        });
    }

    public getSelectedMapMarker(): ActionMarker | undefined {
        return (this.layer.getLayers() as ActionMarker[]).find(
            (actionMarker) => actionMarker.data?.id === this.localStore.selectSnapshotByKey("selectedMarkerData")?.id
        );
    }

    private init(): void {
        this.mapInstance.then((mapInstance) => {
            mapInstance.on("click", this.startMarkerCreation, this);
        });
    }

    private loadActionMarkerOnMap(data: Partial<ActionMarkerData>): ActionMarker | undefined {
        if (!data.location) {
            return undefined;
        }

        const divIconComponent = this.viewContainerRef.createComponent(ActionMarkerComponent);
        divIconComponent.instance.selectedTool = data.tool;
        divIconComponent.instance.hasPhoto = !!data.photoId;
        this.actionMarkerDivIconComponents.push(divIconComponent);

        const marker = new Marker(
            { lat: data.location.latitude, lng: data.location.longitude },
            {
                icon: new DivIcon({ ...MARKER_ICON_OPTIONS, html: divIconComponent.location.nativeElement, className: undefined }),
            }
        ) as ActionMarker;

        marker.on("click", () => {
            if (!this.localStore.selectSnapshotByKey("isSelectModeEnabled")) {
                return;
            }

            this.updateMarkerSelection(marker);
        });
        marker.on("dragstart", () => this.markerDragStart.emit(marker));
        marker.on("dragend", () => this.markerDragEnd.emit(marker));

        marker.data = data;

        this.layer.addLayer(marker);

        return marker;
    }

    private updateMarkerSelection(marker: ActionMarker | undefined): void {
        this.localStore.patchState({ selectedMarkerData: marker?.data });
        this.markerSelect.emit({ ...marker?.data });
    }

    private async watchCustomLeafletEvents(): Promise<void> {
        const map = await this.mapInstance;

        map.on(CustomLeafletMapEvent.CreateActionMarker, this.finishMarkerCreation, this);
        map.on(CustomLeafletMapEvent.OnActionMarkerUpdateFail, this.revertMarkerPositionWhenUpdateFailed, this);
        map.on(CustomLeafletMapEvent.RemoveActionMarker, this.removeSelectedMarker, this);
        map.on(CustomLeafletMapEvent.ClearActionMarkerSelection, this.clearMarkerSelection, this);
    }

    private startMarkerCreation({ latlng: { lat, lng } }: LeafletMouseEvent): void {
        const { isCreateModeEnabled, isCreateProcessing } = this.localStore.get();

        if (isCreateModeEnabled && !isCreateProcessing) {
            const selectedTool = ACTION_MARKER_TOOLS.find((tool) => tool.name === this.localStore.selectSnapshotByKey("selectedToolName"));
            const marker = this.loadActionMarkerOnMap({ location: { latitude: lat, longitude: lng }, tool: selectedTool });

            if (marker) {
                this.updateMarkerSelection(marker);
                this.localStore.patchState({ isCreateProcessing: true, markerToBeCreated: marker });
            }
        }
    }

    private cancelMarkerCreation(): void {
        this.localStore.patchState({ isCreateProcessing: false });

        const markerToBeCreated = this.localStore.selectSnapshotByKey("markerToBeCreated");
        if (markerToBeCreated) {
            this.layer.removeLayer(markerToBeCreated as Layer);
            this.updateMarkerSelection(undefined);
            this.localStore.patchState({ markerToBeCreated: undefined });
        }
    }

    private finishMarkerCreation(): void {
        this.localStore.patchState({ isCreateProcessing: false });
        this.updateMarkerSelection(undefined);
    }

    private revertMarkerPositionWhenUpdateFailed(): void {
        const selectMarker = this.getSelectedMapMarker();
        const location = selectMarker?.data?.location;
        if (!location) {
            return;
        }

        selectMarker.setLatLng({ lat: location.latitude, lng: location.longitude });
    }

    private removeSelectedMarker(): void {
        const selectedMarker = this.getSelectedMapMarker();
        selectedMarker?.remove();
        this.updateMarkerSelection(undefined);
    }

    private clearMarkerSelection(): void {
        if (this.localStore.selectSnapshotByKey("isCreateProcessing")) {
            this.cancelMarkerCreation();

            return;
        }

        this.updateMarkerSelection(undefined);
    }

    private loadMarkers(data: Partial<ActionMarkerData>[] | undefined): void {
        if (!data) {
            return;
        }

        this.layer.clearLayers();
        this.actionMarkerDivIconComponents.forEach((component) => component.destroy());
        data.forEach((markerData) => this.loadActionMarkerOnMap(markerData));
        this.dataLoad.emit();
    }
}
