import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IncidentSharedDataActions, IncidentSharedDataState, SidePanelViewType } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { AnimationUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs/operators";
import { IncidentActions } from "../../state/incident.actions";
import { IncidentState } from "../../state/incident.state";

@UntilDestroy()
@Component({
    selector: "sah-client-lib-chat-side-panel",
    templateUrl: "chat-side-panel.component.html",
    styleUrls: ["chat-side-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInSideOffScreenRight()],
})
export class ChatSidePanelComponent {
    protected readonly isExpanded$ = this.store
        .select(IncidentState.sidePanelViewOpenState)
        .pipe(map((sidePanelOpenState) => sidePanelOpenState[SidePanelViewType.Chat]));

    protected readonly messages$ = this.store.select(IncidentSharedDataState.messages);
    protected readonly isProcessing$ = this.store.select(IncidentState.isProcessing);

    constructor(
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService,
        private readonly store: Store
    ) {}

    protected closePanel(): void {
        this.store.dispatch(IncidentActions.CloseSidePanel);
    }

    protected sendMessage(message: string): void {
        this.store
            .dispatch(new IncidentSharedDataActions.SendIncidentMessage(message))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentSharedDataState.sendIncidentMessageError);
                if (error) {
                    this.toastrService.error(
                        this.translocoService.translate("sahClientLibIncident.chatSidePanel.sendIncidentMessageError")
                    );
                }
            });
    }
}
