export enum DashboardErrorType {
    Unknown = "Unknown",
    Conflict = "Conflict",
}

export interface DashboardError {
    type: DashboardErrorType;
}

export interface DashboardIncidentActivationError {
    type: DashboardErrorType;
    messageKey: string;
    args: Record<string, unknown>;
}

export enum IncidentListType {
    MyActiveIncidents = "MyActiveIncidents",
    MyPlannedIncidents = "MyPlannedIncidents",
    MyCompletedIncidents = "MyCompletedIncidents",
}
