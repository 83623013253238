import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { DmsCoordinatesUtils, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Circle, LatLng } from "leaflet";
import { MapArea } from "../../../models/incident-map.models";

interface AreaCardComponentState {
    area: MapArea | undefined;
}

@Component({
    selector: "sah-shared-lib-area-card",
    templateUrl: "area-card.component.html",
    styleUrls: ["area-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AreaCardComponent {
    @Input() public set area(value: MapArea | undefined) {
        this.localStore.patchState({ area: value });
    }

    protected readonly area$ = this.localStore.selectByKey("area");

    constructor(private readonly localStore: LocalComponentStore<AreaCardComponentState>) {
        this.localStore.setState({ area: undefined });
    }

    protected getDmsCoordinates(area: MapArea): string {
        const convertLatLngToDmsString = ({ lat, lng }: LatLng) => {
            const dmsLatitude = DmsCoordinatesUtils.convertDmsWithDirectionToString(
                DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(lat, GeographicCoordinatesType.Latitude)
            );
            const dmsLongitude = DmsCoordinatesUtils.convertDmsWithDirectionToString(
                DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(lng, GeographicCoordinatesType.Longitude)
            );

            return `${dmsLatitude} ${dmsLongitude}`;
        };

        if (area instanceof Circle) {
            return convertLatLngToDmsString(area.getLatLng());
        }

        return (area.getLatLngs() as LatLng[])
            .flat()
            .map((latlng) => convertLatLngToDmsString(latlng))
            .join("; ");
    }

    protected getRadius(area: MapArea): string {
        if (area instanceof Circle) {
            return `${area.getRadius().toFixed(2)}`;
        }

        return "";
    }

    protected isInstanceofCircle(area: MapArea): boolean {
        return area instanceof Circle;
    }
}
