import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Directive, Inject, Input, OnDestroy } from "@angular/core";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider, LeafletRuler } from "@dtm-frontend/shared/map/leaflet";
import { LocalizeNumberPipe } from "@dtm-frontend/shared/ui/i18n";
import { KILOMETERS_IN_NAUTICAL_MILE } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { Map } from "leaflet";

@Directive({
    selector: "sah-client-lib-ruler[isRulerEnabled]",
    providers: [LocalizeNumberPipe],
})
export class RulerDirective implements OnDestroy {
    private readonly map: Promise<Map> = this.mapProvider.getMap();
    private ruler: LeafletRuler | undefined;

    @Input() public set isRulerEnabled(value: BooleanInput) {
        if (coerceBooleanProperty(value)) {
            this.ruler?.enable();
        } else {
            this.ruler?.disable();
        }
    }

    constructor(
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        private readonly translocoService: TranslocoService,
        private readonly localizeNumberPipe: LocalizeNumberPipe
    ) {
        this.initializeRuler();
    }

    public ngOnDestroy() {
        this.ruler?.disable();
    }

    private async initializeRuler(): Promise<void> {
        const map = await this.map;
        this.ruler = new LeafletRuler(map, this.tooltipTextProvider.bind(this));
    }

    private tooltipTextProvider(sectionDistance: number, totalDistance: number): string {
        const sectionDistanceTranslation = this.translocoService.translate("sahClientLibIncident.mapFeatures.rulerSectionDistanceLabel", {
            distanceText: this.getMultiUnitDistanceLabel(sectionDistance),
        });
        const totalDistanceTranslation = this.translocoService.translate("sahClientLibIncident.mapFeatures.rulerTotalDistanceLabel", {
            totalDistanceText: this.getMultiUnitDistanceLabel(totalDistance),
        });

        return `${sectionDistanceTranslation} <br> ${totalDistanceTranslation}`;
    }

    private getMultiUnitDistanceLabel(distance: number): string {
        return this.translocoService.translate("sahClientLibIncident.mapFeatures.multiUnitDistanceLabel", {
            kilometers: this.localizeNumberPipe.transform(distance, { maximumFractionDigits: 2 }),
            nauticalMiles: this.localizeNumberPipe.transform(distance / KILOMETERS_IN_NAUTICAL_MILE, { maximumFractionDigits: 2 }),
        });
    }
}
