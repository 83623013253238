import { inject } from "@angular/core";
import { CanActivateFn, Router } from "@angular/router";
import { AuthState } from "@dtm-frontend/shared/auth";
import { Store } from "@ngxs/store";

export function loginPageGuard(redirectUrl: string): CanActivateFn {
    return () => {
        const router = inject(Router);
        const store = inject(Store);

        const isLoggedIn = store.selectSnapshot(AuthState.isLoggedIn);
        if (isLoggedIn) {
            router.navigateByUrl(redirectUrl);
        }

        return !isLoggedIn;
    };
}
