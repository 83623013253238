<div class="header">{{ "sahClientLibIncident.areaSidePanel.areaTaskSelectHeader" | transloco }}</div>
<ng-container *ngrxLet="tasks$ as tasks">
    <ng-container *ngIf="tasks.length; else emptyListTemplate">
        <sah-shared-lib-task-card
            *ngFor="let task of tasks"
            isPreview
            [task]="task"
            (click)="taskSelect.emit(task)"
        ></sah-shared-lib-task-card>
    </ng-container>

    <ng-template #emptyListTemplate>
        <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary">
            <img imageSlot src="assets/images/no-results.svg" alt="" />
            <ng-container messageSlot>
                {{ "sahClientLibIncident.areaSidePanel.emptyAreaTaskSelectMessage" | transloco }}
            </ng-container>
        </dtm-ui-empty-state>
    </ng-template>
</ng-container>
