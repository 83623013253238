import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { saveAs } from "file-saver";
import { map } from "rxjs/operators";

interface ActionMarkerPhotoPreviewComponentState {
    photo: Blob | undefined;
    markerName: string | undefined;
}

const FILE_EXTENSION = ".jpg";

@Component({
    selector: "sah-shared-lib-action-marker-photo-preview[photo][markerName]",
    templateUrl: "action-marker-photo-preview.component.html",
    styleUrls: ["action-marker-photo-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ActionMarkerPhotoPreviewComponent {
    @ViewChild("photoPreviewTemplateRef") private photoPreviewTemplateRef: TemplateRef<HTMLElement> | undefined;

    @Input() public set photo(value: Blob | undefined) {
        this.localStore.patchState({ photo: value });
    }
    @Input() public set markerName(value: string | undefined) {
        this.localStore.patchState({ markerName: value });
    }

    protected readonly photo$ = this.localStore.selectByKey("photo").pipe(map((photo) => (photo ? URL.createObjectURL(photo) : undefined)));

    constructor(
        private readonly dialog: MatDialog,
        private readonly localStore: LocalComponentStore<ActionMarkerPhotoPreviewComponentState>
    ) {
        this.localStore.setState({
            photo: undefined,
            markerName: undefined,
        });
    }

    protected openImagePreview(): void {
        const photo = this.localStore.selectSnapshotByKey("photo");
        if (!photo || !this.photoPreviewTemplateRef) {
            return;
        }

        this.dialog.open(this.photoPreviewTemplateRef);
    }

    protected downloadImage(): void {
        const { photo, markerName } = this.localStore.get();
        if (!photo || !markerName) {
            return;
        }

        saveAs(photo, markerName + FILE_EXTENSION);
    }
}
