import { fadeAnimation } from "./fade-animation";
import { foldAnimation } from "./fold-animation";
import { slideInAnimation, slideInAnimationWithMargin, slideInSideOffScreenDown, slideInSideOffScreenRight } from "./slide-in-animation";

export const AnimationUtils = {
    slideInAnimation,
    slideInAnimationWithMargin,
    foldAnimation,
    fadeAnimation,
    slideInSideOffScreenRight,
    slideInSideOffScreenDown,
};
