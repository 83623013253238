import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
} from "@angular/forms";
import { ProcessedFile } from "@dtm-frontend/shared/ui";
import { FunctionUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

interface ActionMarkerPhotoEditComponentState {
    maxSize: number | undefined;
    maxSizeValidator: ValidatorFn | undefined;
    markerName: string | undefined;
    photo: Blob | undefined;
}

const ALLOWED_MIME_TYPES = ["image/jpeg", "image/png"];

@UntilDestroy()
@Component({
    selector: "sah-shared-lib-action-marker-photo-edit[markerName]",
    templateUrl: "action-marker-photo-edit.component.html",
    styleUrls: ["action-marker-photo-edit.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ActionMarkerPhotoEditComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ActionMarkerPhotoEditComponent),
            multi: true,
        },
    ],
})
export class ActionMarkerPhotoEditComponent implements ControlValueAccessor, Validator {
    @Input() public set maxSize(value: number | undefined) {
        if (!value) {
            return;
        }

        const maxSizeValidator = this.validateMaxSize(value);
        this.localStore.patchState({ maxSize: value, maxSizeValidator });
    }

    @Input() public set markerName(value: string | undefined) {
        this.localStore.patchState({ markerName: value });
    }

    @Output() protected readonly valueChange = new EventEmitter<Blob | null>();

    protected readonly ALLOWED_MIME_TYPES = ALLOWED_MIME_TYPES;

    protected readonly control = new FormControl<Blob | null>(null);

    protected readonly markerName$ = this.localStore.selectByKey("markerName");
    protected readonly photo$ = this.localStore.selectByKey("photo");

    private onChange: (value: Blob | null) => void = FunctionUtils.noop;
    private onTouched: () => void = FunctionUtils.noop;

    constructor(private readonly localStore: LocalComponentStore<ActionMarkerPhotoEditComponentState>) {
        this.localStore.setState({
            maxSize: undefined,
            maxSizeValidator: undefined,
            markerName: undefined,
            photo: undefined,
        });

        this.watchControlValueChange();
    }

    public registerOnChange(fn: (value: Blob | null) => void): void {
        this.onChange = fn;
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public writeValue(value: Blob | null): void {
        this.control.setValue(value);
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.control.disable();
        } else {
            this.control.enable();
        }
    }

    public validate(control: AbstractControl): ValidationErrors | null {
        const maxSizeValidator = this.localStore.selectSnapshotByKey("maxSizeValidator");
        if (!maxSizeValidator) {
            return null;
        }

        return maxSizeValidator(control);
    }

    protected removePhoto(): void {
        this.control.setValue(null);
        this.valueChange.emit(null);
    }

    protected addPhoto(files: ProcessedFile[]): void {
        const file = files[0].file;
        this.control.setValue(file);
        this.valueChange.emit(file);
    }

    private validateMaxSize(maxSize: number): ValidatorFn {
        return (control: AbstractControl<Blob | null>): ValidationErrors | null => {
            if (maxSize && control.value && control.value.size > maxSize) {
                return { maxSize: { requiredSize: maxSize } };
            }

            return null;
        };
    }

    private watchControlValueChange(): void {
        this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
            this.localStore.patchState({ photo: value ?? undefined });
            this.onChange(value);
        });
    }
}
