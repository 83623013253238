import { InjectionToken } from "@angular/core";

export interface IncidentEndpoints {
    createTask: string;
    updateTask: string;
    updateTaskStatus: string;
    removeTask: string;
    createArea: string;
    updateArea: string;
    removeArea: string;
    createHandDrawing: string;
    removeHandDrawing: string;
    assignAreaToTask: string;
    detachAreaFromTask: string;
    createTeams: string;
    attachTeamToTask: string;
    detachTeamFromTask: string;
    getTeamCreatorHints: string;
    getOperators: string;
    getPilots: string;
    getPilotsUavs: string;
    removeTeam: string;
}

export const INCIDENT_ENDPOINTS = new InjectionToken<IncidentEndpoints>("Incident endpoints");
