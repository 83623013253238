import { Circle, Polygon } from "leaflet";
import { MapArea } from "../../models/incident-map.models";

export const mockPolygonArea1 = new Polygon([
    [21.520793, 51.535172],
    [21.5147, 51.52673],
    [21.530834, 51.525875],
    [21.520793, 51.535172],
]) as MapArea;
export const mockCircleArea1 = new Circle([21.507996, 51.520839], { radius: 100 }) as MapArea;
export const mockCircleArea2 = new Circle([23.17326, 52.350632], { radius: 200 }) as MapArea;

export const mockAreas: MapArea[] = [
    Object.assign(mockCircleArea1, { data: { name: "Circle 1", id: "2", version: 0, taskIds: ["1"] } }),
    Object.assign(mockPolygonArea1, { data: { name: "Polygon 1", id: "1", version: 0, taskIds: ["1", "2", "3"] } }),
    Object.assign(mockCircleArea2, { data: { name: "Circle 2", id: "3", version: 0, taskIds: [] } }),
];
