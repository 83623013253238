<sah-shared-lib-task-creator-form
    dtmUiMarkValueAccessorControlsAsTouched
    dtmUiInvalidFormScrollable
    [formControl]="taskCreatorForm"
></sah-shared-lib-task-creator-form>
<div class="actions" *ngrxLet="isProcessing$ as isProcessing">
    <button type="button" class="button-secondary" [disabled]="isProcessing" (click)="cancel.emit()">
        {{ "sahClientLibIncident.taskSidePanel.cancelButtonLabel" | transloco }}
    </button>
    <button type="button" class="button-primary" [disabled]="isProcessing" (click)="submit()">
        <mat-spinner *ngIf="isProcessing" diameter="20" color="accent"></mat-spinner
        >{{ "sahClientLibIncident.taskSidePanel.submitButtonLabel" | transloco }}
    </button>
</div>
