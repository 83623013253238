import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { SahUtils } from "../../../index";
import { Task, TaskStatus, TaskStatusChange, TaskTeamChange } from "../../../models/task.models";
import { Team } from "../../../models/team.models";

interface TaskCardComponentState {
    task: Task | undefined;
    isPreview: boolean;
    isStatusChangeEnabled: boolean;
    availableStatuses: TaskStatus[];
    isTeamChangeEnabled: boolean;
    availableTeams: Team[];
}

@Component({
    selector: "sah-shared-lib-task-card[task]",
    templateUrl: "task-card.component.html",
    styleUrls: ["task-card.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TaskCardComponent {
    @Input() public set task(value: Task | undefined) {
        this.localStore.patchState({ task: value });
    }
    @Input() public set isPreview(value: BooleanInput) {
        this.localStore.patchState({ isPreview: coerceBooleanProperty(value) });
    }
    @Input() public set availableStatuses(value: TaskStatus[] | undefined) {
        this.localStore.patchState({ availableStatuses: value ?? [] });
    }
    @Input() public set availableTeams(value: Team[] | undefined) {
        this.localStore.patchState({ availableTeams: value ?? [] });
    }
    @Input() public set isStatusChangeEnabled(value: BooleanInput) {
        this.localStore.patchState({ isStatusChangeEnabled: coerceBooleanProperty(value) });
    }
    @Input() public set isTeamChangeEnabled(value: BooleanInput) {
        this.localStore.patchState({ isTeamChangeEnabled: coerceBooleanProperty(value) });
    }

    @Output() protected readonly statusChange = new EventEmitter<TaskStatusChange>();
    @Output() protected readonly teamAttach = new EventEmitter<TaskTeamChange>();
    @Output() protected readonly teamDetach = new EventEmitter<Task>();

    protected readonly task$ = this.localStore.selectByKey("task");
    protected readonly isPreview$ = this.localStore.selectByKey("isPreview");
    protected readonly availableStatuses$ = this.localStore.selectByKey("availableStatuses");
    protected readonly availableTeams$ = this.localStore.selectByKey("availableTeams");
    protected readonly isStatusChangeEnabled$ = this.localStore.selectByKey("isStatusChangeEnabled");
    protected readonly isTeamChangeEnabled$ = this.localStore.selectByKey("isTeamChangeEnabled");

    constructor(private readonly localStore: LocalComponentStore<TaskCardComponentState>) {
        this.localStore.setState({
            task: undefined,
            isPreview: false,
            availableStatuses: [],
            isStatusChangeEnabled: true,
            availableTeams: [],
            isTeamChangeEnabled: true,
        });
    }

    protected getStatusClass(taskStatus: TaskStatus | undefined): string {
        return SahUtils.convertTaskStatusToCssClass(taskStatus);
    }
}
