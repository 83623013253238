import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguation from "../assets/dynamic.config.json";
import { SearchAndHelpClientEnvironment } from "./environment.model";

export const dynamicEnvironment = createDynamicEnvironment<SearchAndHelpClientEnvironment, typeof DynamicConfiguation>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    ({ apiDomain, keycloakConfig }) => {
        const apiUrl = `https://${apiDomain}/api`;
        const appSpecificApiUrl = `${apiUrl}/search-and-help`;
        const websocketEndpoint = `wss://${apiDomain}/api/ws`;

        return {
            production: false,
            name: "dev",
            keycloakConfig,
            azureMapsSubscriptionKey: "HS9JYfhTrM0Ud2smxePEEpkueCl81_SAD2T-VU-ZNFk",
            bearerExcludedUrls: [],
            leafletMapConfig: {
                defaultPosition: {
                    lat: 52.115,
                    lng: 19.424,
                },
                zoom: {
                    min: 6,
                    max: 18,
                    initial: 7,
                    userPosition: 17,
                },
            },
            websocketEndpoint,
            incidentEndpoints: {
                assignAreaToTask: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/tasks/{{taskId}}/area-assignments`,
                createArea: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/areas`,
                createTask: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/tasks`,
                updateTask: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/tasks/{{taskId}}`,
                updateTaskStatus: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/tasks/{{taskId}}/status`,
                removeTask: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/tasks/{{taskId}}`,
                detachAreaFromTask: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/tasks/{{taskId}}/area-assignments`,
                removeArea: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/areas/{{areaId}}`,
                updateArea: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/areas/{{areaId}}`,
                createHandDrawing: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/doodles`,
                removeHandDrawing: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/doodles/{{drawingId}}`,
                createTeams: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/teams/collection`,
                attachTeamToTask: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/tasks/{{taskId}}/team-assignments`,
                detachTeamFromTask: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/tasks/{{taskId}}/assigned-team`,
                getTeamCreatorHints: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/teams/creator-hints`,
                getOperators: `${appSpecificApiUrl}/emergency-operators`,
                getPilots: `${appSpecificApiUrl}/pilots`,
                getPilotsUavs: `${appSpecificApiUrl}/pilots/{{pilotId}}/uavs`,
                removeTeam: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/teams/{{teamId}}`,
            },
            incidentSharedEndpoints: {
                getIncidentMapData: `${appSpecificApiUrl}/operational-situations/{{incidentId}}`,
                createActionMarker: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/markers`,
                updateActionMarker: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/markers/{{markerId}}`,
                addActionMarkerPhoto: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/markers/{{markerId}}/photo`,
                removeActionMarkerPhoto: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/markers/{{markerId}}/photo`,
                getActionMarkerPhoto: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/markers/{{markerId}}/photo`,
                removeActionMarker: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/markers/{{markerId}}`,
                sendIncidentMessage: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/comments`,
                wsIncidentTopic: "/websocket/topic/search-and-help/incident-id/{{incidentId}}",
            },
            incidentCreatorEndpoints: {
                createIncident: `${appSpecificApiUrl}/incidents`,
                updateIncident: `${appSpecificApiUrl}/incidents/{{incidentId}}`,
                getIncident: `${appSpecificApiUrl}/incidents/{{incidentId}}`,
                getCoordinators: `${appSpecificApiUrl}/coordinators`,
                getInstitutions: `${appSpecificApiUrl}/institutions`,
                getUnits: `${appSpecificApiUrl}/voivodeships`,
                createTeams: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/teams/collection`,
                getRestrictions: `${appSpecificApiUrl}/restrictions`,
                getRestrictionArea: `${appSpecificApiUrl}/restrictions/{{restrictionId}}/area`,
                getOperators: `${appSpecificApiUrl}/emergency-operators`,
                getPilots: `${appSpecificApiUrl}/pilots`,
                getPilotsUavs: `${appSpecificApiUrl}/pilots/{{pilotId}}/uavs`,
            },
            translationEndpoints: {
                getSystemTranslations: `${apiUrl}/system/translations/{{locale}}`,
            },
            dashboardEndpoints: {
                activateIncident: `${appSpecificApiUrl}/incidents/{{incidentId}}/activation`,
                completeIncident: `${appSpecificApiUrl}/incidents/{{incidentId}}/completion`,
                getIncidents: `${appSpecificApiUrl}/incidents`,
                downloadIncidentReport: `${appSpecificApiUrl}/incidents/{{incidentId}}/report`,
            },
        };
    }
);
