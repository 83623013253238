import { ChangeDetectionStrategy, Component } from "@angular/core";
import {
    IncidentErrorType,
    IncidentSharedDataState,
    SahUtils,
    SidePanelViewType,
    TaskStatus,
    Team,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { ButtonTheme, ConfirmationDialogComponent, DialogService } from "@dtm-frontend/shared/ui";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { LocalComponentStore, RxjsUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@jsverse/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { Observable, switchMap } from "rxjs";
import { map } from "rxjs/operators";
import { IncidentActions } from "../../state/incident.actions";
import { IncidentState } from "../../state/incident.state";

interface TeamPanelComponentState {
    isOpen: boolean;
}

// NOTE: represented by array index from lowest to highest
const TEAM_TASK_STATUS_PRIORITY = [
    TaskStatus.Incomplete,
    TaskStatus.PendingAcceptance,
    TaskStatus.Completed,
    TaskStatus.Planned,
    TaskStatus.Paused,
    TaskStatus.Active,
];

@UntilDestroy()
@Component({
    selector: "sah-client-lib-team-panel",
    templateUrl: "team-panel.component.html",
    styleUrls: ["team-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TeamPanelComponent {
    protected readonly tasks$ = this.store.select(IncidentSharedDataState.tasks);
    protected readonly teams$ = this.store.select(IncidentSharedDataState.teams);
    protected readonly numberOfTeams$ = this.teams$.pipe(map((teams) => teams.length));
    protected readonly isOpen$ = this.localStore.selectByKey("isOpen");

    constructor(
        private readonly dialogService: DialogService,
        private readonly toastrService: ToastrService,
        private readonly translationHelper: TranslationHelperService,
        private readonly translocoService: TranslocoService,
        private readonly localStore: LocalComponentStore<TeamPanelComponentState>,
        private readonly store: Store
    ) {
        this.localStore.setState({ isOpen: false });
    }

    protected openTeamCreator(): void {
        this.store.dispatch(new IncidentActions.OpenSidePanel(SidePanelViewType.TeamCreator));
    }

    protected removeTeam(team: Team): void {
        const hasConnectedPilot = team.pilot?.pilotId !== undefined;
        const confirmationText = hasConnectedPilot
            ? this.translocoService.translate("sahClientLibIncident.teamPanel.removeTeamDialog.connectedPilotConfirmationMessage", {
                  pilotName: team.pilot?.fullName,
              })
            : this.translocoService.translate("sahClientLibIncident.teamPanel.removeTeamDialog.defaultConfirmationMessage");

        this.dialogService
            .open(ConfirmationDialogComponent, {
                data: {
                    titleText: this.translocoService.translate("sahClientLibIncident.teamPanel.removeTeamDialog.header", {
                        teamName: team.name,
                    }),
                    confirmationText,
                    declineButtonLabel: this.translocoService.translate(
                        "sahClientLibIncident.teamPanel.removeTeamDialog.cancelButtonLabel"
                    ),
                    confirmButtonLabel: this.translocoService.translate(
                        "sahClientLibIncident.teamPanel.removeTeamDialog.confirmButtonLabel"
                    ),
                    theme: ButtonTheme.Warn,
                },
            })
            .afterClosed()
            .pipe(
                RxjsUtils.filterFalsy(),
                switchMap(() => this.store.dispatch(new IncidentActions.RemoveTeam(team.id))),
                untilDestroyed(this)
            )
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentState.removeTeamError);
                if (error) {
                    switch (error.type) {
                        case IncidentErrorType.Conflict:
                            this.toastrService.error(this.translationHelper.selectSystemTranslation(error.messageKey, error.args));
                            break;
                        default:
                            this.toastrService.error(
                                this.translocoService.translate("sahClientLibIncident.teamPanel.teamRemoveUnknownError")
                            );
                    }

                    return;
                }

                this.toastrService.success(
                    this.translocoService.translate("sahClientLibIncident.teamPanel.teamRemoveSuccessMessage", { teamName: team.name })
                );
            });
    }

    protected togglePanel(): void {
        this.localStore.patchState((state) => ({ isOpen: !state.isOpen }));
    }

    protected getTeamTaskStatusClass(team: Team): Observable<string> {
        return this.tasks$.pipe(
            map((tasks) => {
                const teamTasks = tasks.filter((task) => task.attachedTeam?.teamId === team.id);

                const teamTaskStatus = teamTasks.reduce((status, task) => {
                    const determinedStatusPriority = TEAM_TASK_STATUS_PRIORITY.indexOf(status);
                    const currentValueStatusPriority = TEAM_TASK_STATUS_PRIORITY.indexOf(task.status);

                    return determinedStatusPriority > currentValueStatusPriority ? status : task.status;
                }, TaskStatus.Incomplete);

                return SahUtils.convertTaskStatusToCssClass(teamTaskStatus);
            }),
            untilDestroyed(this)
        );
    }
}
