<sah-client-lib-incident-side-panel @slideInSideOffScreenRight *ngIf="isExpanded$ | ngrxPush">
    <div header class="header">
        <button type="button" class="button-icon" (click)="closePanel()">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
        <span class="title">{{ "sahClientLibIncident.chatSidePanel.header" | transloco }}</span>
    </div>

    <sah-shared-lib-incident-chat
        [messages]="messages$ | ngrxPush"
        [isProcessing]="isProcessing$ | ngrxPush"
        (messageSend)="sendMessage($event)"
    >
    </sah-shared-lib-incident-chat>
</sah-client-lib-incident-side-panel>
