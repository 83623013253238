<ng-container *ngrxLet="{ isExpanded: isExpanded$, isProcessing: isProcessing$, viewType: viewType$ } as vm">
    <sah-client-lib-incident-side-panel @slideInSideOffScreenRight *ngIf="vm.isExpanded">
        <div header class="header">
            <ng-container *ngIf="vm.viewType[SidePanelViewType.TeamCreator]">
                <button type="button" class="button-icon" (click)="closePanel()">
                    <dtm-ui-icon name="close"></dtm-ui-icon>
                </button>
                <span class="title">{{ "sahClientLibIncident.teamPanel.creatorHeader" | transloco }}</span>
            </ng-container>
        </div>

        <section>
            <dtm-ui-loader-container [isShown]="vm.isProcessing">
                <ng-container *ngIf="vm.viewType[SidePanelViewType.TeamCreator]">
                    <sah-client-lib-teams-creator
                        dtmUiMarkValueAccessorControlsAsTouched
                        dtmUiInvalidFormScrollable
                        [formControl]="teamsControl"
                        [isProcessing]="vm.isProcessing"
                        [accessoryOptions]="teamUavAccessories$ | ngrxPush"
                        [operators]="operators$ | ngrxPush"
                        [operatorPilots]="operatorPilots$ | ngrxPush"
                        (operatorChange)="getOperatorsPilots($event)"
                        (pilotChange)="getPilotsUavs($event)"
                    ></sah-client-lib-teams-creator>
                </ng-container>
            </dtm-ui-loader-container>
        </section>

        <section footer class="panel-actions">
            <button type="button" class="button-secondary" [disabled]="vm.isProcessing" (click)="closePanel()">
                {{ "sahClientLibIncident.teamPanel.creatorCancelButtonLabel" | transloco }}
            </button>
            <button type="button" class="button-primary" [disabled]="vm.isProcessing" (click)="submit()">
                <mat-spinner *ngIf="vm.isProcessing" diameter="20" color="accent"></mat-spinner>
                {{ "sahClientLibIncident.teamPanel.creatorSubmitButtonLabel" | transloco }}
            </button>
        </section>
    </sah-client-lib-incident-side-panel>
</ng-container>
