import { BooleanInput } from "@angular/cdk/coercion";
import { Directive, EventEmitter, Inject, Input, OnDestroy, Output, ViewContainerRef } from "@angular/core";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "@dtm-frontend/shared/map/leaflet";
import { FunctionUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Popup, PopupOptions } from "leaflet";
import { ActionMarkerData, CustomLeafletMapEvent } from "../../models/incident-map.models";
import { ActionMarkerContextMenuComponent, ViewType } from "./action-marker-context-menu/action-marker-context-menu.component";
import { ActionMarkersLayerDirective } from "./action-markers-layer.directive";

const CONTEXT_MENU_POPUP_OPTIONS: PopupOptions = {
    closeButton: false,
    closeOnClick: false,
    closeOnEscapeKey: false,
    autoClose: false,
    className: "action-marker-menu",
    maxWidth: undefined,
};

@UntilDestroy()
@Directive({
    selector: "sah-shared-lib-action-markers-layer[withContextMenu]",
})
export class ActionMarkersContextMenuDirective implements OnDestroy {
    @Input() public set isActionMarkerProcessing(value: BooleanInput) {
        this.contextMenu.instance.isProcessing = value;
    }

    @Output() protected readonly markerAdd = new EventEmitter<Partial<ActionMarkerData>>();
    @Output() protected readonly markerUpdate = new EventEmitter<Partial<ActionMarkerData>>();
    @Output() protected readonly markerRemove = new EventEmitter<string>();
    @Output() protected readonly markerPhotoLoad = new EventEmitter<Partial<ActionMarkerData>>();
    @Output() protected readonly draggableToggle = new EventEmitter<boolean>();

    private readonly mapInstance = this.mapProvider.getMap();
    private readonly contextMenu = this.viewContainerRef.createComponent(ActionMarkerContextMenuComponent);
    private readonly contextMenuPopup = new Popup(CONTEXT_MENU_POPUP_OPTIONS).setContent(this.contextMenu.location.nativeElement);

    constructor(
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        private readonly parentDirective: ActionMarkersLayerDirective,
        private readonly viewContainerRef: ViewContainerRef
    ) {
        this.watchContextMenuActions();
        this.watchCustomLeafletEvents();
        this.watchParentEvents();
    }

    public ngOnDestroy() {
        this.contextMenu.destroy();

        this.mapInstance.then((map) => {
            map.off(CustomLeafletMapEvent.OnActionMarkerUpdate, this.openContextMenuAfterMarkerUpdate, this);
            map.off(CustomLeafletMapEvent.OnActionMarkerUpdateFail, this.moveContextMenuToLastValidMarkerLocation, this);
            map.off(CustomLeafletMapEvent.CreateActionMarker, this.closeContextMenuAfterMarkerCreationCompletion, this);
            map.off(CustomLeafletMapEvent.ClearActionMarkerSelection, this.closeContextMenuOnSelectionClear, this);
            map.off(CustomLeafletMapEvent.RemoveActionMarker, () => this.closeContextMenuAfterSelectedMarkerRemoval(), this);
        });
    }

    private async openContextMenu(viewType: ViewType, shouldTryToLoadPhoto = true): Promise<void> {
        const selectedMapMarker = this.parentDirective.getSelectedMapMarker();
        if (!selectedMapMarker) {
            return;
        }

        this.contextMenu.instance.viewType = viewType;
        this.updateContextMenuMarkerData(selectedMapMarker.data, shouldTryToLoadPhoto);
        (await this.mapInstance).openPopup(this.contextMenuPopup.setLatLng(selectedMapMarker.getLatLng()));
    }

    private async closeContextMenu(): Promise<void> {
        (await this.mapInstance).closePopup(this.contextMenuPopup);
    }

    private toggleContextMenu(viewType: ViewType): void {
        if (this.contextMenuPopup.isOpen()) {
            this.closeContextMenu();

            return;
        }

        this.openContextMenu(viewType);
    }

    private async watchContextMenuActions(): Promise<void> {
        const map = await this.mapInstance;
        const { add, addCancel, update, remove, dragToggle, viewChange } = this.contextMenu.instance;

        add.pipe(untilDestroyed(this)).subscribe((data) => {
            const selectedMapMarker = this.parentDirective.getSelectedMapMarker();
            if (!selectedMapMarker) {
                return;
            }

            this.markerAdd.emit({ ...selectedMapMarker?.data, ...data });
        });
        addCancel.pipe(untilDestroyed(this)).subscribe(() => map.fire(CustomLeafletMapEvent.ClearActionMarkerSelection));
        update.pipe(untilDestroyed(this)).subscribe((data) => this.markerUpdate.emit(data));
        remove.pipe(untilDestroyed(this)).subscribe(() => this.markerRemove.emit(this.parentDirective.getSelectedMapMarker()?.data?.id));
        dragToggle.pipe(untilDestroyed(this)).subscribe((isDraggable) => this.draggableToggle.emit(isDraggable));
        viewChange.pipe(untilDestroyed(this)).subscribe((viewType) => this.openContextMenu(viewType));
    }

    private async watchCustomLeafletEvents(): Promise<void> {
        const map = await this.mapInstance;

        map.on(CustomLeafletMapEvent.OnActionMarkerUpdate, this.openContextMenuAfterMarkerUpdate, this);
        map.on(CustomLeafletMapEvent.OnActionMarkerUpdateFail, this.moveContextMenuToLastValidMarkerLocation, this);
        map.on(CustomLeafletMapEvent.CreateActionMarker, this.closeContextMenuAfterMarkerCreationCompletion, this);
        map.on(CustomLeafletMapEvent.ClearActionMarkerSelection, this.closeContextMenuOnSelectionClear, this);
        map.on(CustomLeafletMapEvent.RemoveActionMarker, () => this.closeContextMenuAfterSelectedMarkerRemoval(), this);
    }

    private watchParentEvents(): void {
        this.parentDirective.markerDragStart$.pipe(untilDestroyed(this)).subscribe(() => this.closeContextMenu());
        this.parentDirective.markerDragEnd$.pipe(untilDestroyed(this)).subscribe((marker) => {
            this.openContextMenu(ViewType.Preview);
            const currentPosition = marker.getLatLng();
            this.markerUpdate.emit({ ...marker.data, location: { latitude: currentPosition.lat, longitude: currentPosition.lng } });
        });
        this.parentDirective.dataLoad$.pipe(untilDestroyed(this)).subscribe(() => {
            if (this.contextMenuPopup.isOpen()) {
                const selectedMapMarker = this.parentDirective.getSelectedMapMarker();
                if (!selectedMapMarker) {
                    this.closeContextMenu();

                    return;
                }

                if (selectedMapMarker?.data?.location) {
                    this.updateContextMenuMarkerData(selectedMapMarker.data);
                    const { latitude, longitude } = selectedMapMarker.data.location;
                    this.contextMenuPopup.setLatLng([latitude, longitude]);
                }
            }
        });
        this.parentDirective.markerSelect$.pipe(untilDestroyed(this)).subscribe((markerData) => {
            if (FunctionUtils.isNullOrUndefined(markerData)) {
                this.closeContextMenu();
            } else if (markerData.id === undefined) {
                this.openContextMenu(ViewType.Create);
            } else if (markerData.id === this.contextMenu.instance.selectedMarkerId) {
                this.toggleContextMenu(ViewType.Preview);
            } else {
                this.openContextMenu(ViewType.Preview);
            }
        });
    }

    private updateContextMenuMarkerData(markerData: Partial<ActionMarkerData> | undefined, shouldTryToLoadPhoto = true): void {
        this.contextMenu.instance.actionMarkerData = markerData;
        if (shouldTryToLoadPhoto && markerData?.photoId && !markerData?.photo) {
            this.markerPhotoLoad.emit(markerData);
        }
    }

    private openContextMenuAfterMarkerUpdate(): void {
        this.openContextMenu(ViewType.Preview, false);
    }

    private moveContextMenuToLastValidMarkerLocation(): void {
        const selectMapMarker = this.parentDirective.getSelectedMapMarker();
        const location = selectMapMarker?.data?.location;
        if (!location) {
            return;
        }

        this.contextMenuPopup.setLatLng([location.latitude, location.longitude]);
    }

    private closeContextMenuAfterMarkerCreationCompletion(): void {
        this.closeContextMenu();
    }

    private closeContextMenuOnSelectionClear(): void {
        this.closeContextMenu();
    }

    private closeContextMenuAfterSelectedMarkerRemoval(): void {
        this.closeContextMenu();
    }
}
