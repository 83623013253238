import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    QueryList,
    ViewChildren,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { EmptyStateMode } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { map, startWith } from "rxjs";
import { IncidentMessage } from "../../models/incident.models";

interface IncidentChatComponentState {
    isProcessing: boolean;
    messages: IncidentMessage[];
}

const MESSAGE_INPUT_MIN_ROWS = 1;
const MESSAGE_INPUT_MAX_ROWS = 5;
const MESSAGE_MAX_LENGTH = 1500;

@UntilDestroy()
@Component({
    selector: "sah-shared-lib-incident-chat[messages]",
    templateUrl: "incident-chat.component.html",
    styleUrls: ["incident-chat.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class IncidentChatComponent implements AfterViewInit {
    @ViewChildren("messageElement") private readonly messageElements: QueryList<ElementRef<HTMLElement>> = new QueryList();

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set messages(value: IncidentMessage[] | undefined) {
        this.localStore.patchState({ messages: value ?? [] });
    }

    @Output() protected readonly messageSend = new EventEmitter<string>();

    protected readonly EmptyStateMode = EmptyStateMode;
    protected readonly MESSAGE_MAX_LENGTH = MESSAGE_MAX_LENGTH;
    protected readonly MESSAGE_INPUT_MIN_ROWS = MESSAGE_INPUT_MIN_ROWS;
    protected readonly MESSAGE_INPUT_MAX_ROWS = MESSAGE_INPUT_MAX_ROWS;

    protected readonly messageControl = new FormControl<string>("", {
        nonNullable: true,
        validators: [Validators.maxLength(MESSAGE_MAX_LENGTH)],
    });

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly messages$ = this.localStore.selectByKey("messages");
    protected readonly isMessageInputEmpty$ = this.messageControl.valueChanges.pipe(
        startWith(this.messageControl.value),
        map((message) => message.trim() === "")
    );

    constructor(private readonly localStore: LocalComponentStore<IncidentChatComponentState>) {
        this.localStore.setState({ isProcessing: false, messages: [] });
    }

    public ngAfterViewInit() {
        this.scrollToLastMessageOnChanges();
    }

    protected sendMessage(): void {
        const message = this.messageControl.value;
        this.messageControl.markAsTouched();

        if (this.messageControl.invalid || message.trim() === "") {
            return;
        }

        this.messageSend.emit(message);
        this.messageControl.reset("");
    }

    private scrollToLastMessageOnChanges(): void {
        this.messageElements.changes
            .pipe(startWith(this.messageElements), untilDestroyed(this))
            .subscribe((queryList: QueryList<ElementRef<HTMLElement>>) => {
                if (!queryList.last) {
                    return;
                }

                const lastMessage = queryList.last.nativeElement;

                lastMessage.scrollIntoView({ behavior: "smooth" });
            });
    }
}
