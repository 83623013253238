import { Circle, Polygon } from "leaflet";
import { MapArea, MapAreaData } from "../models/incident-map.models";

export function createMutableMapArea(mapArea: MapArea, data?: Partial<MapAreaData>): MapArea {
    let createdMapArea: MapArea;

    if (mapArea instanceof Circle) {
        createdMapArea = new Circle(mapArea.getLatLng(), mapArea.getRadius());
    } else {
        createdMapArea = new Polygon(mapArea.getLatLngs());
    }

    createdMapArea.data = mapArea.data;

    if (data) {
        createdMapArea.data = { ...createdMapArea.data, ...data };
    }

    return createdMapArea;
}
