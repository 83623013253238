import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import {
    ACTION_MARKER_TOOLS,
    AREA_DRAWING_TOOLS,
    MAP_TOOLS,
    MapTool,
    MapToolName,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Observable, map } from "rxjs";

enum MenuType {
    AreaDrawing = "AreaDrawing",
    ActionMarker = "ActionMarker",
}

interface MenuState {
    type: MenuType;
    isOpened: boolean;
    currentTool: MapTool;
    tools: MapTool[];
}

interface IncidentToolbarComponentState {
    selectedMapToolName: MapToolName | undefined;
    menuStates: Record<MenuType, MenuState>;
}

const OVERLAY_OFFSET_X = 55;
const OVERLAY_OFFSET_Y = -95;

@Component({
    selector: "sah-client-lib-incident-toolbar[selectedMapToolName]",
    templateUrl: "./incident-toolbar.component.html",
    styleUrls: ["./incident-toolbar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class IncidentToolbarComponent {
    @Input() public set selectedMapToolName(value: MapToolName | undefined) {
        this.localStore.patchState({ selectedMapToolName: value });
    }

    @Output() protected toolSelect = new EventEmitter<MapToolName>();
    @Output() protected actionUndo = new EventEmitter<void>();
    @Output() protected actionRedo = new EventEmitter<void>();

    protected readonly MenuType = MenuType;
    protected readonly MapToolName = MapToolName;

    protected readonly OVERLAY_OFFSET_X = OVERLAY_OFFSET_X;
    protected readonly OVERLAY_OFFSET_Y = OVERLAY_OFFSET_Y;
    protected readonly MAP_TOOLS = MAP_TOOLS;

    protected readonly selectedMapToolName$ = this.localStore.selectByKey("selectedMapToolName");

    constructor(private readonly localStore: LocalComponentStore<IncidentToolbarComponentState>) {
        localStore.setState({
            selectedMapToolName: undefined,
            menuStates: {
                [MenuType.AreaDrawing]: {
                    type: MenuType.AreaDrawing,
                    isOpened: false,
                    currentTool: AREA_DRAWING_TOOLS[0],
                    tools: AREA_DRAWING_TOOLS,
                },
                [MenuType.ActionMarker]: {
                    type: MenuType.ActionMarker,
                    isOpened: false,
                    currentTool: ACTION_MARKER_TOOLS[0],
                    tools: ACTION_MARKER_TOOLS,
                },
            },
        });
    }

    protected isAnyToolSelected(selectedToolName: MapToolName | undefined, tools: MapTool[]): boolean {
        if (!selectedToolName) {
            return false;
        }

        return tools.some((tool) => tool.name === selectedToolName);
    }

    protected selectFirstMenuToolIfNoneIsSelected(menuState: MenuState): void {
        const selectedMapToolName = this.localStore.selectSnapshotByKey("selectedMapToolName");
        const menuCurrentToolName = menuState.currentTool.name;

        if (!selectedMapToolName || selectedMapToolName === menuCurrentToolName) {
            return;
        }

        this.toolSelect.emit(menuCurrentToolName);
        this.localStore.patchState({ selectedMapToolName: menuCurrentToolName });
    }

    protected toggleMenu(menuType: MenuType, tool?: MapTool): void {
        this.localStore.patchState(({ menuStates }) => ({
            menuStates: {
                ...menuStates,
                [menuType]: {
                    ...menuStates[menuType],
                    isOpened: !menuStates[menuType].isOpened,
                    currentTool: tool ?? menuStates[menuType].currentTool,
                },
            },
        }));
    }

    protected getMenuState(menuType: MenuType): Observable<MenuState> {
        return this.localStore.selectByKey("menuStates").pipe(map((menus) => menus[menuType]));
    }

    protected selectTool(toolName: MapToolName): void {
        if (this.localStore.selectSnapshotByKey("selectedMapToolName") === toolName) {
            return;
        }

        this.localStore.patchState({ selectedMapToolName: toolName });
        this.toolSelect.emit(toolName);
    }
}
