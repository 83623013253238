import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    ActionMarkerService,
    AreaService,
    HandDrawingService,
    IncidentMapLayersService,
    IncidentMapService,
    IncidentSharedDataActions,
    IncidentSharedDataState,
    IncidentStatus,
    MapFiltersState,
    MapToolName,
    SidePanelViewType,
    TaskMarkerService,
    TaskStatus,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { AuthState } from "@dtm-frontend/shared/auth";
import { LeafletMapComponent } from "@dtm-frontend/shared/map/leaflet";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { combineLatestWith, distinctUntilChanged, switchMap, take, tap } from "rxjs";
import { map } from "rxjs/operators";
import { SahAbsolutePath } from "../../../shared/index";
import { AreaEditService } from "../../services/area-edit.service";
import { HandDrawingEditService } from "../../services/hand-drawing-edit.service";
import { IncidentMapFiltersService } from "../../services/incident-map-filters.service";
import { TaskService } from "../../services/task.service";
import { IncidentActions } from "../../state/incident.actions";
import { IncidentState } from "../../state/incident.state";

const TASK_STATUS_POOL: TaskStatus[] = [
    TaskStatus.Incomplete,
    TaskStatus.Planned,
    TaskStatus.PendingAcceptance,
    TaskStatus.Active,
    TaskStatus.Paused,
    TaskStatus.Completed,
];

@UntilDestroy()
@Component({
    selector: "sah-client-lib-active-incident",
    templateUrl: "./active-incident.component.html",
    styleUrls: ["../../../shared/styles/incident.scss", "./active-incident.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        ActionMarkerService,
        AreaEditService,
        AreaService,
        HandDrawingEditService,
        HandDrawingService,
        IncidentMapFiltersService,
        IncidentMapLayersService,
        IncidentMapService,
        TaskMarkerService,
        TaskService,
    ],
})
export class ActiveIncidentComponent implements AfterViewInit, OnDestroy {
    @ViewChild(LeafletMapComponent) private leafletMapComponent: LeafletMapComponent | undefined;

    protected readonly TASK_STATUS_POOL = TASK_STATUS_POOL;
    protected readonly SidePanelViewType = SidePanelViewType;
    protected readonly SahAbsolutePath = SahAbsolutePath;
    protected readonly IncidentStatus = IncidentStatus;

    protected readonly isIncidentValid$ = this.store
        .select(IncidentSharedDataState.incidentStatus)
        .pipe(map((status) => status === IncidentStatus.Active));
    protected readonly hasUserLostAccess$ = this.isIncidentValid$.pipe(
        combineLatestWith(
            this.store.select(IncidentSharedDataState.airOperationsCoordinatorId),
            this.store.select(IncidentSharedDataState.deputyAirOperationsCoordinatorsIds),
            this.store.select(IncidentSharedDataState.flightsDirectorsIds),
            this.store.select(AuthState.userId)
        ),
        map(([isIncidentValid, airOperationsCoordinatorId, deputyAirOperationsCoordinatorsIds, flightsDirectorsIds, userId]) => {
            if (!isIncidentValid || !airOperationsCoordinatorId || !deputyAirOperationsCoordinatorsIds || !flightsDirectorsIds) {
                return false;
            }

            return (
                airOperationsCoordinatorId !== userId &&
                !deputyAirOperationsCoordinatorsIds.includes(userId) &&
                !flightsDirectorsIds.includes(userId)
            );
        }),
        distinctUntilChanged(),
        tap((hasUserLostAccess) => {
            if (hasUserLostAccess) {
                this.store.dispatch(new IncidentSharedDataActions.StopIncidentUpdatesWatch());
            }
        })
    );
    protected readonly isIncidentMapDataProcessing$ = this.store.select(IncidentSharedDataState.isProcessing).pipe(
        combineLatestWith(this.store.select(IncidentState.isIncidentMapDataProcessing)),
        map(([isSharedDataProcessing, isIncidentMapDataProcessing]) => isSharedDataProcessing || isIncidentMapDataProcessing)
    );
    protected readonly selectedArea$ = this.store
        .select(IncidentState.selectedAreaId)
        .pipe(map((areaId) => this.areaEditService.getAreaFromMapLayer(areaId)));

    protected readonly actionMarkers$ = this.store.select(IncidentSharedDataState.actionMarkers);
    protected readonly isActionMarkerProcessing$ = this.store.select(IncidentSharedDataState.isActionMarkerProcessing);

    protected readonly sidePanelViewOpenState$ = this.store.select(IncidentState.sidePanelViewOpenState);
    protected readonly mapFiltersState$ = this.store.select(IncidentState.mapFiltersState);

    protected readonly selectedMapToolName$ = this.store.select(IncidentState.selectedMapToolName);
    protected readonly isSelectionEnabled$ = this.store.select(IncidentState.isSelectionEnabled);
    protected readonly isAreaDrawEnabled$ = this.store.select(IncidentState.isAreaDrawEnabled);
    protected readonly isHandDrawEnabled$ = this.store.select(IncidentState.isHandDrawEnabled);
    protected readonly isRulerEnabled$ = this.store.select(IncidentState.isRulerEnabled);
    protected readonly isActionMarkerEnabled$ = this.store.select(IncidentState.isActionMarkerEnabled);

    constructor(
        protected readonly actionMarkerService: ActionMarkerService,
        protected readonly areaEditService: AreaEditService,
        protected readonly handDrawingEditService: HandDrawingEditService,
        private readonly incidentMapService: IncidentMapService,
        private readonly mapFiltersService: IncidentMapFiltersService,
        private readonly route: ActivatedRoute,
        private readonly store: Store
    ) {
        const incidentId = this.route.snapshot.params?.id;
        this.store.dispatch(new IncidentSharedDataActions.GetOperationalSituation(incidentId));
        this.store.dispatch(new IncidentSharedDataActions.StartIncidentUpdatesWatch(incidentId));

        this.watchOperationalSituationDataReload();
    }

    public ngAfterViewInit() {
        this.initMap();
    }

    public ngOnDestroy() {
        this.store.dispatch([
            IncidentSharedDataActions.StopIncidentUpdatesWatch,
            IncidentSharedDataActions.ResetState,
            IncidentActions.ResetState,
        ]);
    }

    protected selectMapTool(toolName: MapToolName): void {
        this.store.dispatch(new IncidentActions.SelectMapTool(toolName));
    }

    protected openSidePanel(sidePanelViewType: SidePanelViewType): void {
        this.store.dispatch(new IncidentActions.OpenSidePanel(sidePanelViewType));
    }

    protected closeSidePanel(): void {
        this.store.dispatch(new IncidentActions.CloseSidePanel());
    }

    protected updateMapFiltersState(value: MapFiltersState): void {
        this.store.dispatch(new IncidentActions.UpdateMapFilters(value));
    }

    private initMap(): void {
        this.leafletMapComponent?.getMap().then((mapInstance) => {
            this.isIncidentValid$
                .pipe(
                    RxjsUtils.filterFalsy(),
                    take(1),
                    switchMap(() => this.incidentMapService.initMapWithLayers(mapInstance)),
                    untilDestroyed(this)
                )
                .subscribe(() => {
                    this.mapFiltersService.initMap(mapInstance);
                    this.areaEditService.initMap(mapInstance);
                });
        });
    }

    private watchOperationalSituationDataReload(): void {
        this.incidentMapService.operationalSituationDataReloaded$.pipe(untilDestroyed(this)).subscribe(() => {
            this.mapFiltersService.reloadFilters();
        });
    }
}
