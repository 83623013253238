import { ArrayUtils } from "@dtm-frontend/shared/utils";
import { BasicUavEquipment, Operator, Pilot, TeamCreatorFormValues, UavCreatorFormValues } from "../models/team.models";

interface CreateTeamsRequestPayload {
    registeredPilotTeams: TeamEntity[];
    unregisteredPilotTeams: TeamEntity[];
}

interface TeamEntity {
    name: string | null;
    pilot: Partial<PilotEntity> | null;
    uavs: Partial<TeamUavEntity>[];
}

interface PilotEntity extends Pilot {
    emergencyOperator: Partial<Operator> | null;
}

interface TeamUavEntity {
    name: string | null;
    type: string | null;
    equipment: {
        basic: string[];
        additional: string[];
    };
}

function convertUavCreatorFormValuesToTeamUav(uavCreatorFormValues: Partial<UavCreatorFormValues>): Partial<TeamUavEntity> {
    const [basic, additional] = ArrayUtils.partition(uavCreatorFormValues.equipment ?? [], (equipment) =>
        (Object.values(BasicUavEquipment) as string[]).includes(equipment.value)
    );

    return {
        name: uavCreatorFormValues.name,
        type: uavCreatorFormValues.type,
        equipment: {
            basic: basic.map((chipValue) => chipValue.value),
            additional: additional.map((chipValue) => chipValue.value),
        },
    };
}

export function convertTeamCreatorFormValuesListToCreateTeamsRequestPayload(
    teamCreatorFormValuesList: TeamCreatorFormValues[]
): CreateTeamsRequestPayload {
    const [registeredPilotTeams, unregisteredPilotTeams] = ArrayUtils.partition(teamCreatorFormValuesList, (team) => !!team.pilot);

    return {
        registeredPilotTeams: registeredPilotTeams.map(({ name, pilot, operator, uavs }) => ({
            name,
            pilot: { ...pilot, emergencyOperator: operator },
            uavs: uavs.map(convertUavCreatorFormValuesToTeamUav),
        })),
        unregisteredPilotTeams: unregisteredPilotTeams.map(({ name, pilotName, uavs }) => ({
            name,
            pilot: pilotName ? { fullName: pilotName } : null,
            uavs: uavs.map(convertUavCreatorFormValuesToTeamUav),
        })),
    };
}
