import { Polyline, Renderer } from "leaflet";
import { HandDrawingPolyline } from "../models/incident-map.models";

export function createMutableHandDrawPolyline(polyline: HandDrawingPolyline, renderer: Renderer): HandDrawingPolyline {
    const createdPolyline: HandDrawingPolyline = new Polyline([], { renderer }).setLatLngs(polyline.getLatLngs());

    createdPolyline.data = polyline.data;

    return createdPolyline;
}
