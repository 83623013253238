import { ChangeDetectionStrategy, Component, forwardRef } from "@angular/core";
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    Validators,
} from "@angular/forms";
import { TranslationHelperService } from "@dtm-frontend/shared/ui/i18n";
import { FunctionUtils, LocalComponentStore, ONLY_WHITE_SPACES_VALIDATION_PATTERN } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { startWith } from "rxjs";
import { map } from "rxjs/operators";
import { TaskCreatorFormValues } from "../../../models/task.models";

interface TaskCreatorFormComponentState {
    minEndDate: Date;
    isDisabled: boolean;
}

interface TaskCreatorForm {
    name: FormControl<string | null>;
    startDate: FormControl<Date | null>;
    startTime: FormControl<Date | null>;
    endDate: FormControl<Date | null>;
    endTime: FormControl<Date | null>;
    floorAltitude: FormControl<number | null>;
    ceilingAltitude: FormControl<number | null>;
    areDetailsRequired: FormControl<boolean | null>;
    details: FormControl<string | null>;
}

const NAME_MAX_LENGTH = 150;
const DETAILS_MAX_LENGTH = 1500;
const ALTITUDE_MIN_VALUE = 0;

@UntilDestroy()
@Component({
    selector: "sah-shared-lib-task-creator-form",
    templateUrl: "task-creator-form.component.html",
    styleUrls: ["task-creator-form.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        LocalComponentStore,
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TaskCreatorFormComponent), multi: true },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => TaskCreatorFormComponent),
            multi: true,
        },
    ],
})
export class TaskCreatorFormComponent implements ControlValueAccessor, Validator {
    private onTouched = FunctionUtils.noop;
    private onValidationChange = FunctionUtils.noop;

    protected readonly MIN_DATE = new Date();
    protected readonly taskCreatorForm = new FormGroup<TaskCreatorForm>(
        {
            name: new FormControl(null, [
                Validators.required,
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                Validators.maxLength(NAME_MAX_LENGTH),
            ]),
            startDate: new FormControl(null),
            startTime: new FormControl(null),
            endDate: new FormControl(null),
            endTime: new FormControl(null),
            floorAltitude: new FormControl(null, [Validators.required, Validators.min(ALTITUDE_MIN_VALUE)]),
            ceilingAltitude: new FormControl(null, [Validators.required, Validators.min(ALTITUDE_MIN_VALUE)]),
            areDetailsRequired: new FormControl(null),
            details: new FormControl({ value: null, disabled: true }, [
                Validators.required,
                Validators.pattern(ONLY_WHITE_SPACES_VALIDATION_PATTERN),
                Validators.maxLength(DETAILS_MAX_LENGTH),
            ]),
        },
        [(formGroup) => this.optionalRequiredStartDateAndTime(formGroup), (formGroup) => this.optionalRequiredEndDateAndTime(formGroup)]
    );

    protected readonly datePickerPlaceholder$ = this.translocoHelper.datePickerPlaceholder$;
    protected readonly minEndDate$ = this.localStore.selectByKey("minEndDate");
    protected readonly isDisabled$ = this.localStore.selectByKey("isDisabled");
    protected readonly isDateClearButtonDisabled$ = this.taskCreatorForm.valueChanges.pipe(
        map(({ startDate, startTime, endDate, endTime }) => !startDate && !startTime && !endDate && !endTime)
    );

    constructor(
        private readonly localStore: LocalComponentStore<TaskCreatorFormComponentState>,
        private readonly translocoHelper: TranslationHelperService
    ) {
        this.localStore.setState({
            minEndDate: this.MIN_DATE,
            isDisabled: false,
        });

        this.watchStartDateChanges();
        this.watchFloorAltitudeChanges();
        this.watchAreDetailsRequiredChanges();
    }

    public registerOnChange(fn: (value: Partial<TaskCreatorFormValues>) => void): void {
        this.taskCreatorForm.valueChanges.pipe(untilDestroyed(this)).subscribe(fn);
    }

    public registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    public registerOnValidatorChange(fn: () => void): void {
        this.onValidationChange = fn;
    }

    public writeValue(value: Partial<TaskCreatorFormValues>): void {
        this.taskCreatorForm.reset(value ?? {}, { emitEvent: false });
    }

    public setDisabledState(isDisabled: boolean): void {
        if (isDisabled) {
            this.taskCreatorForm.disable();
        } else {
            this.taskCreatorForm.enable();
            const { areDetailsRequired, details } = this.taskCreatorForm.controls;

            if (!areDetailsRequired.value) {
                details.disable();
            }
        }

        this.localStore.patchState({ isDisabled });
    }

    public validate(): ValidationErrors | null {
        return this.taskCreatorForm.invalid ? { invalidTaskCreator: true } : null;
    }

    protected clearDates(): void {
        this.taskCreatorForm.patchValue({ startDate: null, startTime: null, endDate: null, endTime: null });
    }

    private watchStartDateChanges(): void {
        const startDateControl = this.taskCreatorForm.controls.startDate;

        startDateControl.valueChanges.pipe(startWith(startDateControl.value), untilDestroyed(this)).subscribe((startDate) => {
            this.localStore.patchState({ minEndDate: startDate ?? this.MIN_DATE });
        });
    }

    private watchFloorAltitudeChanges(): void {
        const { floorAltitude: floorAltitudeControl, ceilingAltitude: ceilingAltitudeControl } = this.taskCreatorForm.controls;

        floorAltitudeControl.valueChanges.pipe(startWith(floorAltitudeControl.value), untilDestroyed(this)).subscribe((floorAltitude) => {
            ceilingAltitudeControl.setValidators([
                Validators.required,
                Validators.min(Math.max(floorAltitude ?? ALTITUDE_MIN_VALUE, ALTITUDE_MIN_VALUE)),
            ]);
            ceilingAltitudeControl.updateValueAndValidity();
        });
    }

    private watchAreDetailsRequiredChanges(): void {
        const { areDetailsRequired: areDetailsRequiredControl, details: detailsControl } = this.taskCreatorForm.controls;

        areDetailsRequiredControl.valueChanges
            .pipe(startWith(areDetailsRequiredControl.value), untilDestroyed(this))
            .subscribe((areDetailsRequired) => {
                if (areDetailsRequired) {
                    detailsControl.enable();

                    return;
                }

                detailsControl.disable();
            });
    }

    private optionalRequiredStartDateAndTime(formGroup: AbstractControl): ValidationErrors | null {
        const form = formGroup as FormGroup<TaskCreatorForm>;
        const { startDate, startTime } = form.value;

        if ((startDate && startTime) || (!startDate && !startTime)) {
            return null;
        }

        return startDate ? { requiredStartTime: true } : { requiredStartDate: true };
    }

    private optionalRequiredEndDateAndTime(formGroup: AbstractControl): ValidationErrors | null {
        const form = formGroup as FormGroup<TaskCreatorForm>;
        const { endDate, endTime } = form.value;

        if ((endDate && endTime) || (!endDate && !endTime)) {
            return null;
        }

        return endDate ? { requiredEndTime: true } : { requiredEndDate: true };
    }
}
