import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { LanguageCode, SharedI18nModule, getTranslocoInlineLoader } from "@dtm-frontend/shared/ui/i18n";
import { TRANSLOCO_SCOPE } from "@jsverse/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { TeamCreatorComponent } from "./components/team-creator/team-creator.component";
import { TeamsCreatorComponent } from "./components/teams-creator/teams-creator.component";

@NgModule({
    imports: [
        CommonModule,
        LetModule,
        MatDialogModule,
        MatInputModule,
        MatTooltipModule,
        ReactiveFormsModule,
        SharedI18nModule,
        SharedUiModule,
        PushModule,
    ],
    declarations: [TeamCreatorComponent, TeamsCreatorComponent],
    providers: [
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "sahClientLibShared",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [TeamCreatorComponent, TeamsCreatorComponent],
})
export class SharedModule {
    public static forTest(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [],
        };
    }
}
