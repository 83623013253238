import { TaskStatus } from "../models/task.models";

export function convertTaskStatusToCssClass(taskStatus: TaskStatus | undefined): string {
    switch (taskStatus) {
        case TaskStatus.Planned:
            return "task-status-planned";
        case TaskStatus.Active:
            return "task-status-active";
        case TaskStatus.Paused:
            return "task-status-paused";
        case TaskStatus.Completed:
            return "task-status-completed";
        case TaskStatus.PendingAcceptance:
            return "task-status-pending-acceptance";
        case TaskStatus.Incomplete:
            return "task-status-incomplete";
        case TaskStatus.Rejected:
            return "task-status-rejected";
        default:
            return "";
    }
}
