<ng-container *ngIf="task$ | ngrxPush as task">
    <dtm-ui-info-message *ngIf="task.rejectionReason">
        <dtm-ui-label-value
            [label]="'sahSharedLibIncident.tasks.taskPreview.rejectionReasonLabel' | transloco"
            [value]="task.rejectionReason"
        >
        </dtm-ui-label-value>
    </dtm-ui-info-message>

    <dtm-ui-label-value [label]="'sahSharedLibIncident.tasks.taskPreview.nameLabel' | transloco" [value]="task.name"></dtm-ui-label-value>

    <div class="paired-fields">
        <dtm-ui-label-value [label]="'sahSharedLibIncident.tasks.taskPreview.startDateLabel' | transloco">
            <ng-container *ngIf="task.timeRange?.start as startDate; else noDataTemplate">
                {{ startDate | localizeDate : { dateStyle: "short" } }}
            </ng-container>
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'sahSharedLibIncident.tasks.taskPreview.startTimeLabel' | transloco">
            <ng-container *ngIf="task.timeRange?.start as startDate; else noDataTemplate">
                {{ startDate | localizeDate : { timeStyle: "short" } }}
            </ng-container>
        </dtm-ui-label-value>
    </div>

    <div class="paired-fields">
        <dtm-ui-label-value [label]="'sahSharedLibIncident.tasks.taskPreview.endDateLabel' | transloco">
            <ng-container *ngIf="task.timeRange?.stop as stopDate; else noDataTemplate">
                {{ stopDate | localizeDate : { dateStyle: "short" } }}
            </ng-container>
        </dtm-ui-label-value>
        <dtm-ui-label-value [label]="'sahSharedLibIncident.tasks.taskPreview.endTimeLabel' | transloco">
            <ng-container *ngIf="task.timeRange?.stop as stopDate; else noDataTemplate">
                {{ stopDate | localizeDate : { timeStyle: "short" } }}
            </ng-container>
        </dtm-ui-label-value>
    </div>

    <div class="paired-fields">
        <dtm-ui-label-value
            [label]="'sahSharedLibIncident.tasks.taskPreview.floorAltitudeLabel' | transloco"
            [value]="
                'sahSharedLibIncident.tasks.taskPreview.altitudeValueLabel'
                    | transloco
                        : { value: task.altitudeRange.floor, suffix: 'sahSharedLibIncident.tasks.taskPreview.altitudeSuffix' | transloco }
            "
        ></dtm-ui-label-value>
        <dtm-ui-label-value
            [label]="'sahSharedLibIncident.tasks.taskPreview.ceilingAltitudeLabel' | transloco"
            [value]="
                'sahSharedLibIncident.tasks.taskPreview.altitudeValueLabel'
                    | transloco
                        : { value: task.altitudeRange.ceiling, suffix: 'sahSharedLibIncident.tasks.taskPreview.altitudeSuffix' | transloco }
            "
        ></dtm-ui-label-value>
    </div>

    <dtm-ui-label-value [label]="'sahSharedLibIncident.tasks.taskPreview.detailsLabel' | transloco">
        <ng-container *ngIf="task.details; else noDataTemplate">{{ task.details }}</ng-container>
    </dtm-ui-label-value>

    <div class="actions">
        <button type="button" class="button-primary" (click)="previewClose.emit()">
            {{ "sahSharedLibIncident.tasks.taskPreview.closeButtonLabel" | transloco }}
        </button>
    </div>
</ng-container>

<ng-template #noDataTemplate>
    {{ "sahSharedLibIncident.tasks.taskPreview.noDataLabel" | transloco }}
</ng-template>
