import { ComponentRef, Directive, EventEmitter, Inject, OnDestroy, Output, ViewContainerRef } from "@angular/core";
import { LEAFLET_MAP_PROVIDER, LeafletMapProvider } from "@dtm-frontend/shared/map/leaflet";
import { ArrayUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import center from "@turf/center";
import { BaseIconOptions, DivIcon, LatLngExpression, LeafletEvent, Map, Marker, PopupOptions } from "leaflet";
import { SahMapUtils } from "../../index";
import { CustomLeafletMapEvent, MapArea, TaskMarker } from "../../models/incident-map.models";
import { Task, TaskStatus } from "../../models/task.models";
import { IncidentMapLayer, IncidentMapLayersService } from "../../services/incident-map-layers.service";
import { TaskMarkerService } from "../../services/task-marker.service";
import { TaskMarkerIconComponent } from "./task-marker/task-marker-icon.component";
import { TaskPopupContentComponent } from "./task-popup-content/task-popup-content.component";

type TaskMarkerLeafletEvent = LeafletEvent & { area: MapArea; tasks: Task[] };

interface TaskMarkerRef {
    taskId: string;
    marker: Marker;
    markerIconComponentRef: ComponentRef<TaskMarkerIconComponent>;
    popupContentComponentRef: ComponentRef<TaskPopupContentComponent>;
}

interface AreaTaskMarker {
    area: MapArea;
    taskMarkers: TaskMarkerRef[];
}

const MARKER_ICON_OPTIONS: BaseIconOptions = {
    /* eslint-disable no-magic-numbers */
    iconAnchor: [120, 15],
    popupAnchor: [0, -15],
    /* eslint-enable */
};
const DISPLAYED_TASK_STATUSES = [TaskStatus.Planned, TaskStatus.PendingAcceptance, TaskStatus.Active, TaskStatus.Paused];
const POPUP_WIDTH = 156;
const POPUP_OPTIONS: PopupOptions = {
    closeButton: false,
    className: "task-popup",
    minWidth: POPUP_WIDTH,
    maxWidth: POPUP_WIDTH,
};

@UntilDestroy()
// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: "sah-shared-lib-tasks-map-layer" })
export class TasksMapLayerDirective implements OnDestroy {
    @Output() protected readonly taskMarkerClick = new EventEmitter<MapArea>();

    private map: Map | undefined;
    private areasTaskMarkers: AreaTaskMarker[] = [];

    constructor(
        private readonly mapLayersService: IncidentMapLayersService,
        @Inject(LEAFLET_MAP_PROVIDER) private readonly mapProvider: LeafletMapProvider,
        private readonly viewContainerRef: ViewContainerRef,
        private readonly taskMarkerService: TaskMarkerService
    ) {
        this.initMap();
    }

    private get layer() {
        return this.mapLayersService.getMapLayer(IncidentMapLayer.TaskMarkers);
    }

    public ngOnDestroy(): void {
        this.areasTaskMarkers.forEach(({ taskMarkers }) => taskMarkers.forEach((taskMarker) => this.removeTaskMarkerRef(taskMarker)));

        if (this.layer) {
            this.map?.removeLayer(this.layer);
        }

        this.map?.off(CustomLeafletMapEvent.CreateTaskMarker, this.handleCreateTaskMarkerEvent, this);
        this.map?.off(CustomLeafletMapEvent.UpdateTaskMarker, this.handleUpdateTaskMarkerEvent, this);
        this.map?.off(CustomLeafletMapEvent.RemoveTaskMarker, this.handleRemoveTaskMarkerEvent, this);
        this.map?.off(CustomLeafletMapEvent.RemoveAllTaskMarkers, this.removeAllTaskMarkers, this);
    }

    private async initMap(): Promise<void> {
        if (!this.map) {
            this.map = await this.mapProvider.getMap();
        }

        this.map.on(CustomLeafletMapEvent.CreateTaskMarker, this.handleCreateTaskMarkerEvent, this);
        this.map.on(CustomLeafletMapEvent.UpdateTaskMarker, this.handleUpdateTaskMarkerEvent, this);
        this.map.on(CustomLeafletMapEvent.RemoveTaskMarker, this.handleRemoveTaskMarkerEvent, this);
        this.map.on(CustomLeafletMapEvent.RemoveAllTaskMarkers, this.removeAllTaskMarkers, this);
    }

    private handleCreateTaskMarkerEvent(createTaskMarkerEvent: LeafletEvent): void {
        const { area, tasks } = createTaskMarkerEvent as unknown as TaskMarkerLeafletEvent;

        this.createAreaTaskMarkers(area, tasks);
    }

    private handleUpdateTaskMarkerEvent(updateTaskMarkerEvent: LeafletEvent): void {
        const { area, tasks } = updateTaskMarkerEvent as unknown as TaskMarkerLeafletEvent;

        this.addOrUpdateAreaTaskMarkers(area, tasks);
    }

    private handleRemoveTaskMarkerEvent(removeTaskMarkerEvent: LeafletEvent): void {
        const { areaId } = removeTaskMarkerEvent as unknown as LeafletEvent & { areaId: string };

        this.removeAreaTaskMarker(areaId);
    }

    private removeAllTaskMarkers(): void {
        this.layer.clearLayers();
        this.areasTaskMarkers = [];
    }

    private createAreaTaskMarkers(area: MapArea, tasks: Task[]): void {
        const tasksToDisplay = this.getDisplayableTasks(tasks);
        const createdTaskMarkers = tasksToDisplay.map((taskToDisplay) => this.createTaskMarker(area, taskToDisplay));

        this.areasTaskMarkers.push({
            area,
            taskMarkers: createdTaskMarkers,
        });
    }

    private createTaskMarker(area: MapArea, task: Task): TaskMarkerRef {
        const popupContentComponentRef = this.createTaskPopupContentComponent(area, task);
        const markerIconComponentRef = this.createTaskMarkerIconComponent(task);
        const marker: TaskMarker = new Marker(this.getAreaCenterAsLatLng(area), {
            icon: this.createTaskMarkerIcon(markerIconComponentRef),
        });

        marker.data = { areaId: area.data?.id, taskId: task.id };

        marker.on("click", () => this.taskMarkerClick.emit(area));
        marker.bindPopup(popupContentComponentRef.location.nativeElement, POPUP_OPTIONS);

        this.layer.addLayer(marker);

        return {
            taskId: task.id,
            marker,
            markerIconComponentRef,
            popupContentComponentRef,
        };
    }

    private createTaskMarkerIcon(taskMarkerIconComponentRef: ComponentRef<TaskMarkerIconComponent>): DivIcon {
        // NOTE: className property is set to undefined in order to remove default class styling
        return new DivIcon({ ...MARKER_ICON_OPTIONS, html: taskMarkerIconComponentRef.location.nativeElement, className: undefined });
    }

    private createTaskMarkerIconComponent(task: Task): ComponentRef<TaskMarkerIconComponent> {
        const component = this.viewContainerRef.createComponent(TaskMarkerIconComponent);
        component.instance.task = task;

        return component;
    }

    private createTaskPopupContentComponent(area: MapArea, task: Task): ComponentRef<TaskPopupContentComponent> {
        const component = this.viewContainerRef.createComponent(TaskPopupContentComponent);
        component.instance.area = area;
        component.instance.task = task;

        return component;
    }

    private updateExistingTaskMarker(taskMarker: TaskMarkerRef, area: MapArea, taskToDisplay: Task): void {
        taskMarker.marker.setLatLng(this.getAreaCenterAsLatLng(area));
        taskMarker.markerIconComponentRef.instance.task = taskToDisplay;
        taskMarker.popupContentComponentRef.instance.task = taskToDisplay;
        taskMarker.popupContentComponentRef.instance.area = area;
    }

    private addOrUpdateAreaTaskMarkers(area: MapArea, tasks: Task[]): void {
        const existingAreaTaskMarker = this.areasTaskMarkers.find((areaTaskMarker) => areaTaskMarker.area.data?.id === area.data?.id);
        if (!existingAreaTaskMarker) {
            this.createAreaTaskMarkers(area, tasks);
            this.taskMarkerService.notifyAboutTaskMarkerUpdate(area);

            return;
        }

        const tasksToDisplay = this.getDisplayableTasks(tasks);
        tasksToDisplay.forEach((taskToDisplay) => {
            const taskMarkerRef = existingAreaTaskMarker.taskMarkers.find((taskMarker) => taskMarker.taskId === taskToDisplay.id);
            if (!taskMarkerRef) {
                existingAreaTaskMarker.taskMarkers.push(this.createTaskMarker(area, taskToDisplay));

                return;
            }

            this.updateExistingTaskMarker(taskMarkerRef, area, taskToDisplay);
        });

        const [activeTaskMarkers, obsoleteTaskMarkers] = ArrayUtils.partition(existingAreaTaskMarker.taskMarkers, (taskMarker) =>
            tasksToDisplay.some((task) => task.id === taskMarker.taskId)
        );

        existingAreaTaskMarker.taskMarkers = activeTaskMarkers;
        obsoleteTaskMarkers.forEach((obsoleteTaskMarker) => this.removeTaskMarkerRef(obsoleteTaskMarker));
        this.taskMarkerService.notifyAboutTaskMarkerUpdate(area);
    }

    private removeAreaTaskMarker(areaId: string): void {
        const toBeRemovedEntity = this.areasTaskMarkers.find((areaTaskMarker) => areaTaskMarker.area.data?.id === areaId);
        if (!toBeRemovedEntity) {
            return;
        }

        toBeRemovedEntity.taskMarkers.forEach((taskMarker) => this.removeTaskMarkerRef(taskMarker));
        this.areasTaskMarkers = this.areasTaskMarkers.filter((areaTaskMarker) => areaTaskMarker.area.data?.id !== areaId);
        this.taskMarkerService.notifyAboutTaskMarkerUpdate(toBeRemovedEntity.area);
    }

    private getAreaCenterAsLatLng(area: MapArea): LatLngExpression {
        const areaCenterPoint = center(SahMapUtils.convertMapAreaToPolygon(area)).geometry.coordinates;

        return [areaCenterPoint[1], areaCenterPoint[0]];
    }

    private getDisplayableTasks(tasks: Task[]): Task[] {
        return tasks.filter((task) => DISPLAYED_TASK_STATUSES.includes(task.status));
    }

    private removeTaskMarkerRef(taskMarker: TaskMarkerRef): void {
        this.layer.removeLayer(taskMarker.marker);
        taskMarker.markerIconComponentRef.destroy();
        taskMarker.popupContentComponentRef.destroy();
    }
}
