import { InjectionToken } from "@angular/core";

export interface IncidentCreatorEndpoints {
    createIncident: string;
    updateIncident: string;
    getIncident: string;
    getCoordinators: string;
    getInstitutions: string;
    getUnits: string;
    getRestrictions: string;
    getRestrictionArea: string;
    createTeams: string;
    getOperators: string;
    getPilots: string;
    getPilotsUavs: string;
}

export const INCIDENT_CREATOR_ENDPOINTS = new InjectionToken<IncidentCreatorEndpoints>("Incident creator endpoints");
