<ng-container *ngrxLet="{ isExpanded: isExpanded$, viewType: viewType$ } as vm">
    <sah-client-lib-incident-side-panel @slideInSideOffScreenRight *ngIf="vm.isExpanded">
        <div header class="header">
            <ng-container *ngIf="vm.viewType[SidePanelViewType.TaskList]">
                <button type="button" class="button-icon" (click)="closePanel()">
                    <dtm-ui-icon name="close"></dtm-ui-icon>
                </button>
                <span class="title">{{ "sahClientLibIncident.taskSidePanel.taskListHeader" | transloco }}</span>
                <button type="button" class="button-primary" (click)="changeViewType(SidePanelViewType.TaskCreator)">
                    {{ "sahClientLibIncident.taskSidePanel.addTaskButtonLabel" | transloco }}
                </button>
            </ng-container>
            <ng-container *ngIf="vm.viewType[SidePanelViewType.TaskCreator]">
                <button type="button" class="button-icon" (click)="changeViewType(SidePanelViewType.TaskList)">
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                </button>
                <span class="title">{{ "sahClientLibIncident.taskSidePanel.taskCreatorHeader" | transloco }}</span>
            </ng-container>

            <ng-container *ngIf="vm.viewType[SidePanelViewType.TaskEditor]">
                <button type="button" class="button-icon" (click)="changeViewType(SidePanelViewType.TaskList)">
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                </button>
                <span class="title">{{ "sahClientLibIncident.taskSidePanel.editTaskHeader" | transloco }}</span>
            </ng-container>
        </div>

        <ng-container *ngIf="vm.viewType[SidePanelViewType.TaskList]">
            <sah-client-lib-task-list
                isTaskEditEnabled
                [tasks]="tasks$ | ngrxPush"
                [teams]="teams$ | ngrxPush"
                [taskStatusPool]="taskStatusPool$ | ngrxPush"
                (taskEdit)="openTaskEditor($event)"
                (taskRemove)="taskService.removeTask($event)"
                (taskAreaSelect)="areaService.selectAreaById($event, true)"
                (statusChange)="taskService.changeTaskStatus($event)"
                (teamAttach)="taskService.attachTeamToTask($event)"
                (teamDetach)="taskService.detachTeamFromTask($event)"
                (pilotTaskAccept)="taskService.acceptPilotTask($event)"
                (pilotTaskReject)="taskService.rejectPilotTask($event)"
            ></sah-client-lib-task-list>
        </ng-container>

        <ng-container *ngIf="vm.viewType[SidePanelViewType.TaskCreator]">
            <sah-client-lib-task-creator
                [isProcessing]="isProcessing$ | ngrxPush"
                [isDefaultDateSet]="(incidentStatus$ | ngrxPush) === IncidentStatus.Active"
                (taskSubmit)="taskService.createTask($event)"
                (cancel)="changeViewType(SidePanelViewType.TaskList)"
            ></sah-client-lib-task-creator>
        </ng-container>

        <ng-container *ngIf="vm.viewType[SidePanelViewType.TaskEditor]">
            <sah-client-lib-task-creator
                [isProcessing]="isProcessing$ | ngrxPush"
                [task]="editedTask$ | ngrxPush"
                (taskSubmit)="taskService.updateTask($event)"
                (cancel)="changeViewType(SidePanelViewType.TaskList)"
            ></sah-client-lib-task-creator>
        </ng-container>
    </sah-client-lib-incident-side-panel>
</ng-container>
