<ng-container
    *ngrxLet="{
        isProcessing: isProcessing$,
        accessoryOptions: accessoryOptions$,
        operators: operators$,
        operatorPilots: operatorPilots$,
        isInfoMessageVisible: isInfoMessageVisible$
    } as vm"
>
    <dtm-ui-info-message *ngIf="vm.isInfoMessageVisible && teamsCreatorForm.enabled">
        <p>{{ "sahClientLibShared.teamsCreator.infoMessage" | transloco }}</p>
        <button type="button" class="button-secondary" (click)="hideInfoMessage()">
            {{ "sahClientLibShared.teamsCreator.hideInfoMessageButtonLabel" | transloco }}
        </button>
    </dtm-ui-info-message>

    <div *ngFor="let teamControl of teamsCreatorForm.controls; index as teamIndex" class="team-container">
        <sah-client-lib-team-creator
            dtmUiMarkValueAccessorControlsAsTouched
            [isProcessing]="vm.isProcessing"
            [formControl]="teamControl"
            [accessoryOptions]="vm.accessoryOptions"
            [operators]="vm.operators"
            [operatorPilots]="vm.operatorPilots"
            (operatorChange)="operatorChange.emit($event)"
            (pilotChange)="pilotChange.emit($event)"
        >
            <button
                *ngIf="teamIndex !== 0"
                actionsPanel
                type="button"
                class="button-icon"
                [matTooltip]="'sahClientLibShared.teamsCreator.removeTeamButtonTooltipLabel' | transloco"
                [matTooltipDisabled]="teamsCreatorForm.disabled"
                [disabled]="teamsCreatorForm.disabled"
                (click)="removeTeam(teamControl.value, teamIndex)"
            >
                <dtm-ui-icon name="delete-bin"></dtm-ui-icon>
            </button>
        </sah-client-lib-team-creator>
    </div>
    <button type="button" class="button-secondary action-button" [disabled]="teamsCreatorForm.disabled" (click)="addTeam()">
        <dtm-ui-icon name="add"></dtm-ui-icon>
        {{ "sahClientLibShared.teamsCreator.addTeamButtonLabel" | transloco }}
    </button>
</ng-container>
