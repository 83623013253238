import { InjectionToken } from "@angular/core";

export interface DashboardEndpoints {
    activateIncident: string;
    completeIncident: string;
    getIncidents: string;
    downloadIncidentReport: string;
}

export const DASHBOARD_ENDPOINTS = new InjectionToken<DashboardEndpoints>("Dashboard endpoints");
