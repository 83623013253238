import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { DmsCoordinatesUtils, GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { ActionMarkerData } from "../../../models/incident-map.models";

interface ActionMarkerPreviewComponentState {
    markerData: Partial<ActionMarkerData> | undefined;
    isReadonly: boolean;
    isDraggable: boolean;
}

@Component({
    selector: "sah-shared-lib-action-marker-preview",
    templateUrl: "action-marker-preview.component.html",
    styleUrls: ["action-marker-preview.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class ActionMarkerPreviewComponent {
    @Input() public set markerData(value: Partial<ActionMarkerData> | undefined) {
        this.localStore.patchState({ markerData: value, isDraggable: !!value?.isDraggable });
    }

    @Input() public set isReadonly(value: BooleanInput) {
        this.localStore.patchState({ isReadonly: coerceBooleanProperty(value) });
    }

    @Output() protected readonly iconChange = new EventEmitter();
    @Output() protected readonly dragToggle = new EventEmitter<boolean>();
    @Output() protected readonly edit = new EventEmitter();
    @Output() protected readonly remove = new EventEmitter();

    protected readonly markerData$ = this.localStore.selectByKey("markerData");
    protected readonly isReadonly$ = this.localStore.selectByKey("isReadonly");
    protected readonly isDraggable$ = this.localStore.selectByKey("isDraggable");

    constructor(private readonly localStore: LocalComponentStore<ActionMarkerPreviewComponentState>) {
        this.localStore.setState({ markerData: undefined, isDraggable: false, isReadonly: false });
    }

    protected getLocationDMS({ latitude, longitude }: { latitude: number; longitude: number }): string {
        const latitudeDmsWithDirectionString = DmsCoordinatesUtils.convertDmsWithDirectionToString(
            DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(latitude, GeographicCoordinatesType.Latitude)
        );
        const longitudeDmsWithDirectionString = DmsCoordinatesUtils.convertDmsWithDirectionToString(
            DmsCoordinatesUtils.convertDecimalDegreesToDmsCoordinatesWithDirection(longitude, GeographicCoordinatesType.Longitude)
        );

        return `${latitudeDmsWithDirectionString} ${longitudeDmsWithDirectionString}`;
    }

    protected toggleDraggable(): void {
        this.localStore.patchState((state) => {
            const isDraggable = !state.isDraggable;
            this.dragToggle.emit(isDraggable);

            return { isDraggable };
        });
    }
}
