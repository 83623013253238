<ng-container *ngrxLet="{ attachedTeam: attachedTeam$, availableTeams: availableTeams$ } as vm">
    <div *ngIf="isPreview$ | ngrxPush; else editModeTemplate">
        <div *ngIf="vm.attachedTeam" class="attached-team preview">
            <dtm-ui-icon name="team"></dtm-ui-icon>
            <span>{{ vm.attachedTeam.name }}</span>
        </div>
    </div>
    <ng-template #editModeTemplate>
        <div
            [class.attached-team]="!!vm.attachedTeam"
            [class.add-team]="!vm.attachedTeam"
            [matMenuTriggerFor]="teamChangeMenu"
            [matMenuTriggerData]="{ $implicit: vm.attachedTeam }"
        >
            <ng-container *ngIf="vm.attachedTeam; else addTeamTemplate">
                <dtm-ui-icon name="team"></dtm-ui-icon>
                <span>{{ vm.attachedTeam.name }}</span>
                <button type="button" class="remove-team-button" (click)="detachTeam(vm.attachedTeam); $event.stopPropagation()">
                    <dtm-ui-icon name="close-circle-fill"></dtm-ui-icon>
                </button>
            </ng-container>
            <ng-template #addTeamTemplate>
                <dtm-ui-icon name="add"></dtm-ui-icon>
                <span>{{ "sahSharedLibIncident.tasks.taskTeamBadgeMenu.addTeamLabel" | transloco }}</span>
            </ng-template>
        </div>

        <mat-menu #teamChangeMenu="matMenu">
            <ng-template matMenuContent let-attachedTeam>
                <ng-container *ngFor="let team of vm.availableTeams">
                    <button *ngIf="team.id !== attachedTeam?.teamId" mat-menu-item type="button" (click)="attachTeam(team)">
                        {{ team.name }}
                    </button>
                </ng-container>
                <ng-container *ngIf="!vm.availableTeams.length">
                    <div class="no-data-container">{{ "sahSharedLibIncident.tasks.taskTeamBadgeMenu.emptyListLabel" | transloco }}</div>
                </ng-container>
            </ng-template>
        </mat-menu>
    </ng-template>
</ng-container>
