<ng-container *ngrxLet="data.isProcessing$ as isProcessing">
    <div class="dialog-header">
        <div mat-dialog-title>
            {{ "sahClientLibIncident.taskSidePanel.rejectPilotTaskTitle" | transloco : { taskName: data.task.name } }}
        </div>
        <button type="button" class="button-icon" [matDialogClose]="false" [disabled]="isProcessing">
            <dtm-ui-icon name="close"></dtm-ui-icon>
        </button>
    </div>

    <div mat-dialog-content>
        <dtm-ui-loader-container [isShown]="isProcessing">
            <p>{{ "sahClientLibIncident.taskSidePanel.rejectPilotTaskConfirmMessage" | transloco }}</p>

            <dtm-ui-textarea-field [maxLength]="REJECTION_REASON_MAX_LENGTH">
                <label>
                    <dtm-ui-icon name="draft"></dtm-ui-icon>
                    <span>{{ "sahClientLibIncident.taskSidePanel.rejectionReasonLabel" | transloco }}</span>
                </label>
                <textarea matInput [formControl]="rejectionReasonControl" [rows]="TEXTAREA_MIN_NUMBER_OF_ROWS"></textarea>
                <div class="field-error" *dtmUiFieldHasError="rejectionReasonControl; name: 'maxlength'; error as error">
                    {{
                        "sahClientLibIncident.taskSidePanel.rejectionReasonMaxLengthError" | transloco : { maxLength: error.requiredLength }
                    }}
                </div>
            </dtm-ui-textarea-field>
        </dtm-ui-loader-container>
    </div>

    <div mat-dialog-actions align="end">
        <button type="button" class="button-secondary" [mat-dialog-close]="false" [disabled]="isProcessing">
            {{ "sahClientLibIncident.taskSidePanel.rejectPilotTaskCancelButtonLabel" | transloco }}
        </button>
        <button cdkFocusInitial type="button" class="button-warn" [disabled]="isProcessing" (click)="rejectTask()">
            {{ "sahClientLibIncident.taskSidePanel.rejectPilotTaskConfirmButtonLabel" | transloco }}
        </button>
    </div>
</ng-container>
