import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { Task } from "../../../models/task.models";

interface TaskMarkerIconComponentState {
    task: Task | undefined;
}

const TEXT_MAX_LENGTH = 15;

@Component({
    templateUrl: "task-marker-icon.component.html",
    styleUrls: ["task-marker-icon.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TaskMarkerIconComponent {
    @Input() public set task(value: Task | undefined) {
        this.localStore.patchState({ task: value });
    }

    protected readonly TEXT_MAX_LENGTH = TEXT_MAX_LENGTH;

    protected readonly task$ = this.localStore.selectByKey("task");

    constructor(private readonly localStore: LocalComponentStore<TaskMarkerIconComponentState>) {
        this.localStore.setState({
            task: undefined,
        });
    }
}
